

* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
box-sizing: border-box;

}

/* colors */
.white{
  color: white; 

}
/* navbar */

/* Home page */
.bg_home {
  animation:slide 3s ease-in-out infinite alternate;
  background: linear-gradient(-60deg, #09f 50%, rgb(158 159 54 / 60%) 50%);
  bottom:0;
  left:-50%;
  opacity:.5;
  position:fixed;
  right:-50%;
  top:0;
  z-index:-1;
}

.bg2_home {
  animation-direction:alternate-reverse;
  animation-duration:4s;
}

.bg3_home {
  animation-duration:3.5s;
}

/* auto change text */

/* section 2 */
.btn_primary {
  
  color: #ffffff;
  text-decoration: none;
  transition: 500ms;
}

.btn_primary:hover {
  
  color: #fffffff5;
  text-decoration: none;
  transition: 500ms;
}
/* Hero Section */



.btn_primary_about {
  text-decoration: none;
  color: white;
  transition: 500ms;
  background-color: transparent;
  border-radius: 50px;

}

.btn_primary_about:hover {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.759);
  background-color: blueviolet;
  border-color: blueviolet;
  transition: 500ms;
  border-radius: 50px;
}

/* Services */

.service_cardss {
  box-shadow: 0px 17px 25px rgba(96, 96, 96, 0.08);
  background-color: white;
  transition: .5s;
  flex: 1 0 20em;
}

.service_cardss:hover {
  box-shadow: none;
  background-color: white;
  transition: .5s;
}

.service_cards {
 
  border-radius: 4px;
  background: transparent;
  box-shadow: 0 6px 10px rgba(0, 0, 0, .08), 0 0 6px rgba(0, 0, 0, .05);
  transition: .5s transform cubic-bezier(.155, 1.105, .295, 1.12), .3s box-shadow, .3s -webkit-transform cubic-bezier(.155, 1.105, .295, 1.12);
  cursor: pointer;
  flex: 1 0 20em;
}

.service_cards:hover {
  transform: scale(1.07);
  box-shadow: 0 10px 20px rgba(0, 0, 0, .12), 0 4px 8px rgba(0, 0, 0, .06);
}

.flex {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.icon_size_service {
  width: 60px;
}

.tech_card {

  box-shadow: #5D3F2B;
}

.bg_service {
  animation:slide 3s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg,  rgb(61, 117, 150) 50%, rgba(162, 163, 117, 0.973) 50%);
  bottom:0;
  left:-50%;
  opacity:.5;
  position:fixed;
  right:-50%;
  top:0;
  z-index:-1;
}

.bg2_service {
  animation-direction:alternate-reverse;
  animation-duration:4s;
}

.bg3_service {
  animation-duration:3s;
}
@keyframes slide {
  0% {
    transform:translateX(-25%);
  }
  100% {
    transform:translateX(25%);
  }
}

.listul {
  list-style: none;
color: white;
font-size: 14px;
}
.listul li{
  padding-top: 16px;
}
.listul li::before {
  content: "";
  height: 18px;
  width: 10px;
  border: 3px solid #eff1f3;
  border-radius: 50px;
  background-size: contain;
  display: inline-block;
  width: 1em;
  height: 1em;
  position: relative;
  top: 0.1rem;
  margin-right: 0.5rem;
}

/* About Us */



#about .about-container .icon-box .icon {
  float: left;
  background: transparent;
  width: 64px;
  height: 64px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  border-radius: 50%;
  border: 2px solid #eff1f3;
  transition: all 0.3s ease-in-out;
}

#about .about-container .icon-box .icon i {
  color: #c9c9c9;
  font-size: 24px;
}

#about .about-container .icon-box:hover .icon {
  background: #007bff;
}

#about .about-container .icon-box:hover .icon i {
  color: rgb(243, 240, 240);
}

#about .about-container .icon-box .title {
  margin-left: 80px;
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 18px;
}

#about .about-container .icon-box .title a {
  color: #283d50;
  text-decoration: none;
}
#about .about-container .icon-box .title a:hover {
  color: #283d50;
  text-decoration: underline;
}

#about .about-container .icon-box .description {
  margin-left: 80px;
  
  font-size: 14px;
}

#about .about-extra {
  padding-top: 60px;
}

#about .about-extra h4 {
  font-weight: 600;
  font-size: 24px;
}
.bg_about {
  animation:slide 3s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg,  rgb(48, 99, 141) 50%, rgb(86, 108, 189) 50%);
  bottom:0;
  left:-50%;
  opacity:.5;
  position:fixed;
  right:-50%;
  top:0;
  z-index:-1;
}

.bg2_about {
  animation-direction:alternate-reverse;
  animation-duration:4s;
}

.bg3_about {
  animation-duration:3s;
}


.bg_contactus {
  animation:slide 3s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg, rgb(82 74 118) 50%, rgb(106 101 121) 50%);
  bottom:0;
  left:-50%;
  opacity:.5;
  position:fixed;
  right:-50%;
  top:0;
  z-index:-1;
}

.bg2_contactus {
  animation-direction:alternate-reverse;
  animation-duration:4s;
}

.bg3_contactus {
  animation-duration:3s;
}


@keyframes slide {
  0% {
    transform:translateX(-25%);
  }
  50% {
    transform:translateX(10%);
  }
  100% {
    transform:translateX(25%);
  }
}
/* Footer */
.footer_bg_color{
  background-color: transparent;
  
}
.footer_icon{
  width: 40px;
}
