*{
  margin: 0;
  padding: 0; 
  font-family: 'Poppins', sans-serif;
}
.bg_techteam {
  animation:slide 3s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg, rgb(106, 129, 97) 50%, rgb(64, 93, 112) 50%);
  bottom:0;
  left:-70%;
  opacity:.5;
  position:fixed;
  right:-70%;
  top:0;
  z-index:-1;
}

.bg2_techteam {
  animation-direction:alternate-reverse;
  animation-duration:4s;
}

.bg3_techteam {
  animation-duration:5s;
}

@keyframes slide {
  0% {
    transform:translateX(-25%);
  }

  100% {
    transform:translateX(25%);
  }
}

.bg_color3{
  background-color: #7e7676;
}
.space {
  letter-spacing: 3px;
}

.cardteam{
   
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.4);
  transition: 0.5s;
}
.cardteam:hover {

  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.6);
}
.text-color-development_color_1 {
 color: #162149;

}
.text-color-development_color_4 {
  color: #493434;
}

.text-color-development_color_3 {
  color: rgb(224, 221, 214);
}
.teamname{
  font-size: 20px;
}
.content{
 color: #102779;
}
.heading{
  color:  linear-gradient(red, yellow);;
}