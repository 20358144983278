@import url(https://fonts.googleapis.com/css?family=Roboto);
.h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 76px;
  word-break: break-word;
}
.h1-med {
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  line-height: 76px;
  word-break: break-word;
}
.h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 57px;
  word-break: break-word;
}
.h2-med {
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 57px;
  word-break: break-word;
}
.h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 43px;
  word-break: break-word;
}
.h3-med {
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 43px;
  word-break: break-word;
}
.h4 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  word-break: break-word;
}
.h4-med {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  word-break: break-word;
}
.p {
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  word-break: break-word;
}
.body1 {
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  word-break: break-word;
}
.small {
  font-size: 80%;
}
.font-medium {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
  word-break: break-word;
}
.dot {
  height: 5px;
  width: 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin: 0.6rem;
}
.button {
  font-size: 16px;
  line-height: 19px;
  word-break: normal;
  text-align: center;
  transition: 500ms;
}
.button:hover {
  text-decoration: none;
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
}
.button-primary-white {
  font-size: 16px;
  line-height: 19px;
  background-color: var(--white);
  color: white;
  border-radius: 4px;
  word-break: normal;
  text-align: center;
  transition: 500ms;
}
.button-primary-white:hover {
  text-decoration: none;
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
  color: white;
}
.button-secondary-white {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--white);
  background-color: white;
  border-radius: 4px;
  word-break: normal;
  text-align: center;
  transition: 500ms;
}
.button-secondary-white:hover {
  text-decoration: none;
  background-color: var(--white);
  color: white;
}
.link-hover-white:hover {
  color: var(--white);
}
.button-primary-black {
  font-size: 16px;
  line-height: 19px;
  background-color: var(--black);
  color: white;
  border-radius: 4px;
  word-break: normal;
  text-align: center;
  transition: 500ms;
}
.button-primary-black:hover {
  text-decoration: none;
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
  color: white;
}
.button-secondary-black {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--black);
  background-color: white;
  border-radius: 4px;
  word-break: normal;
  text-align: center;
  transition: 500ms;
}
.button-secondary-black:hover {
  text-decoration: none;
  background-color: var(--black);
  color: white;
}
.link-hover-black:hover {
  color: var(--black);
}
.button-primary-theme_primary {
  font-size: 16px;
  line-height: 19px;
  background-color: var(--theme_primary);
  color: white;
  border-radius: 4px;
  word-break: normal;
  text-align: center;
  transition: 500ms;
}
.button-primary-theme_primary:hover {
  text-decoration: none;
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
  color: white;
}
.button-secondary-theme_primary {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--theme_primary);
  background-color: white;
  border-radius: 4px;
  word-break: normal;
  text-align: center;
  transition: 500ms;
}
.button-secondary-theme_primary:hover {
  text-decoration: none;
  background-color: var(--theme_primary);
  color: white;
}
.link-hover-theme_primary:hover {
  color: var(--theme_primary);
}
.button-primary-theme_light {
  font-size: 16px;
  line-height: 19px;
  background-color: var(--theme_light);
  color: white;
  border-radius: 4px;
  word-break: normal;
  text-align: center;
  transition: 500ms;
}
.button-primary-theme_light:hover {
  text-decoration: none;
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
  color: white;
}
.button-secondary-theme_light {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--theme_light);
  background-color: white;
  border-radius: 4px;
  word-break: normal;
  text-align: center;
  transition: 500ms;
}
.button-secondary-theme_light:hover {
  text-decoration: none;
  background-color: var(--theme_light);
  color: white;
}
.link-hover-theme_light:hover {
  color: var(--theme_light);
}
.button-primary-text_primary {
  font-size: 16px;
  line-height: 19px;
  background-color: var(--theme_text_primary);
  color: white;
  border-radius: 4px;
  word-break: normal;
  text-align: center;
  transition: 500ms;
}
.button-primary-text_primary:hover {
  text-decoration: none;
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
  color: white;
}
.button-secondary-text_primary {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--theme_text_primary);
  background-color: white;
  border-radius: 4px;
  word-break: normal;
  text-align: center;
  transition: 500ms;
}
.button-secondary-text_primary:hover {
  text-decoration: none;
  background-color: var(--theme_text_primary);
  color: white;
}
.link-hover-text_primary:hover {
  color: var(--theme_text_primary);
}
.button-primary-text_secondary {
  font-size: 16px;
  line-height: 19px;
  background-color: var(--theme_text_secondary);
  color: white;
  border-radius: 4px;
  word-break: normal;
  text-align: center;
  transition: 500ms;
}
.button-primary-text_secondary:hover {
  text-decoration: none;
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
  color: white;
}
.button-secondary-text_secondary {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--theme_text_secondary);
  background-color: white;
  border-radius: 4px;
  word-break: normal;
  text-align: center;
  transition: 500ms;
}
.button-secondary-text_secondary:hover {
  text-decoration: none;
  background-color: var(--theme_text_secondary);
  color: white;
}
.link-hover-text_secondary:hover {
  color: var(--theme_text_secondary);
}
.button-primary-text_tertiary {
  font-size: 16px;
  line-height: 19px;
  background-color: var(--theme_text_tertiary);
  color: white;
  border-radius: 4px;
  word-break: normal;
  text-align: center;
  transition: 500ms;
}
.button-primary-text_tertiary:hover {
  text-decoration: none;
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
  color: white;
}
.button-secondary-text_tertiary {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--theme_text_tertiary);
  background-color: white;
  border-radius: 4px;
  word-break: normal;
  text-align: center;
  transition: 500ms;
}
.button-secondary-text_tertiary:hover {
  text-decoration: none;
  background-color: var(--theme_text_tertiary);
  color: white;
}
.link-hover-text_tertiary:hover {
  color: var(--theme_text_tertiary);
}
@media (max-width: 576px) {
  .h1 {
    font-size: 36px;
    line-height: 43px;
  }
  .h1-med {
    font-size: 36px;
    line-height: 43px;
  }
  .h2 {
    font-size: 28px;
    line-height: 32px;
  }
  .h2-med {
    font-size: 28px;
    line-height: 32px;
  }
  .h3 {
    font-size: 24px;
    line-height: 24px;
  }
  .h3-med {
    font-size: 24px;
    line-height: 24px;
  }
  .h4 {
    font-size: 18px;
    line-height: 20px;
  }
  .h4-med {
    font-size: 18px;
    line-height: 20px;
  }
}
.m-auto {
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.bottom-0 {
  bottom: 0;
}
.bottom-16 {
  bottom: 16px;
}
.bottom-32 {
  bottom: 32px;
}
.right-0 {
  right: 0;
}
.right-32 {
  right: 32px;
}
.right-16 {
  right: 16px;
}
.mt-m10 {
  margin-top: -10rem;
}
.pt-m10 {
  padding-top: -10rem;
}
.mb-m10 {
  margin-bottom: -10rem;
}
.pb-m10 {
  padding-bottom: -10rem;
}
.ml-m10 {
  margin-left: -10rem;
}
.pl-m10 {
  padding-left: -10rem;
}
.mr-m10 {
  margin-right: -10rem;
}
.pr-m10 {
  padding-right: -10rem;
}
.mx-m10 {
  margin-left: -10rem;
  margin-right: -10rem;
}
.my-m10 {
  margin-top: -10rem;
  margin-bottom: -10rem;
}
.py-m10 {
  padding-top: -10rem;
  padding-bottom: -10rem;
}
.px-m10 {
  padding-left: -10rem;
  padding-right: -10rem;
}
.top-m10 {
  top: -10rem;
}
.left-m10 {
  left: -10rem;
}
.height-px-m10 {
  height: -10rem;
}
.width-px-m10 {
  width: -10rem;
}
.border-radius-m10 {
  border-radius: -10rem;
}
.mt-m9 {
  margin-top: -9rem;
}
.pt-m9 {
  padding-top: -9rem;
}
.mb-m9 {
  margin-bottom: -9rem;
}
.pb-m9 {
  padding-bottom: -9rem;
}
.ml-m9 {
  margin-left: -9rem;
}
.pl-m9 {
  padding-left: -9rem;
}
.mr-m9 {
  margin-right: -9rem;
}
.pr-m9 {
  padding-right: -9rem;
}
.mx-m9 {
  margin-left: -9rem;
  margin-right: -9rem;
}
.my-m9 {
  margin-top: -9rem;
  margin-bottom: -9rem;
}
.py-m9 {
  padding-top: -9rem;
  padding-bottom: -9rem;
}
.px-m9 {
  padding-left: -9rem;
  padding-right: -9rem;
}
.top-m9 {
  top: -9rem;
}
.left-m9 {
  left: -9rem;
}
.height-px-m9 {
  height: -9rem;
}
.width-px-m9 {
  width: -9rem;
}
.border-radius-m9 {
  border-radius: -9rem;
}
.mt-m8 {
  margin-top: -8rem;
}
.pt-m8 {
  padding-top: -8rem;
}
.mb-m8 {
  margin-bottom: -8rem;
}
.pb-m8 {
  padding-bottom: -8rem;
}
.ml-m8 {
  margin-left: -8rem;
}
.pl-m8 {
  padding-left: -8rem;
}
.mr-m8 {
  margin-right: -8rem;
}
.pr-m8 {
  padding-right: -8rem;
}
.mx-m8 {
  margin-left: -8rem;
  margin-right: -8rem;
}
.my-m8 {
  margin-top: -8rem;
  margin-bottom: -8rem;
}
.py-m8 {
  padding-top: -8rem;
  padding-bottom: -8rem;
}
.px-m8 {
  padding-left: -8rem;
  padding-right: -8rem;
}
.top-m8 {
  top: -8rem;
}
.left-m8 {
  left: -8rem;
}
.height-px-m8 {
  height: -8rem;
}
.width-px-m8 {
  width: -8rem;
}
.border-radius-m8 {
  border-radius: -8rem;
}
.mt-m7 {
  margin-top: -7rem;
}
.pt-m7 {
  padding-top: -7rem;
}
.mb-m7 {
  margin-bottom: -7rem;
}
.pb-m7 {
  padding-bottom: -7rem;
}
.ml-m7 {
  margin-left: -7rem;
}
.pl-m7 {
  padding-left: -7rem;
}
.mr-m7 {
  margin-right: -7rem;
}
.pr-m7 {
  padding-right: -7rem;
}
.mx-m7 {
  margin-left: -7rem;
  margin-right: -7rem;
}
.my-m7 {
  margin-top: -7rem;
  margin-bottom: -7rem;
}
.py-m7 {
  padding-top: -7rem;
  padding-bottom: -7rem;
}
.px-m7 {
  padding-left: -7rem;
  padding-right: -7rem;
}
.top-m7 {
  top: -7rem;
}
.left-m7 {
  left: -7rem;
}
.height-px-m7 {
  height: -7rem;
}
.width-px-m7 {
  width: -7rem;
}
.border-radius-m7 {
  border-radius: -7rem;
}
.mt-m6 {
  margin-top: -6rem;
}
.pt-m6 {
  padding-top: -6rem;
}
.mb-m6 {
  margin-bottom: -6rem;
}
.pb-m6 {
  padding-bottom: -6rem;
}
.ml-m6 {
  margin-left: -6rem;
}
.pl-m6 {
  padding-left: -6rem;
}
.mr-m6 {
  margin-right: -6rem;
}
.pr-m6 {
  padding-right: -6rem;
}
.mx-m6 {
  margin-left: -6rem;
  margin-right: -6rem;
}
.my-m6 {
  margin-top: -6rem;
  margin-bottom: -6rem;
}
.py-m6 {
  padding-top: -6rem;
  padding-bottom: -6rem;
}
.px-m6 {
  padding-left: -6rem;
  padding-right: -6rem;
}
.top-m6 {
  top: -6rem;
}
.left-m6 {
  left: -6rem;
}
.height-px-m6 {
  height: -6rem;
}
.width-px-m6 {
  width: -6rem;
}
.border-radius-m6 {
  border-radius: -6rem;
}
.mt-m5 {
  margin-top: -5rem;
}
.pt-m5 {
  padding-top: -5rem;
}
.mb-m5 {
  margin-bottom: -5rem;
}
.pb-m5 {
  padding-bottom: -5rem;
}
.ml-m5 {
  margin-left: -5rem;
}
.pl-m5 {
  padding-left: -5rem;
}
.mr-m5 {
  margin-right: -5rem;
}
.pr-m5 {
  padding-right: -5rem;
}
.mx-m5 {
  margin-left: -5rem;
  margin-right: -5rem;
}
.my-m5 {
  margin-top: -5rem;
  margin-bottom: -5rem;
}
.py-m5 {
  padding-top: -5rem;
  padding-bottom: -5rem;
}
.px-m5 {
  padding-left: -5rem;
  padding-right: -5rem;
}
.top-m5 {
  top: -5rem;
}
.left-m5 {
  left: -5rem;
}
.height-px-m5 {
  height: -5rem;
}
.width-px-m5 {
  width: -5rem;
}
.border-radius-m5 {
  border-radius: -5rem;
}
.mt-m4 {
  margin-top: -4rem;
}
.pt-m4 {
  padding-top: -4rem;
}
.mb-m4 {
  margin-bottom: -4rem;
}
.pb-m4 {
  padding-bottom: -4rem;
}
.ml-m4 {
  margin-left: -4rem;
}
.pl-m4 {
  padding-left: -4rem;
}
.mr-m4 {
  margin-right: -4rem;
}
.pr-m4 {
  padding-right: -4rem;
}
.mx-m4 {
  margin-left: -4rem;
  margin-right: -4rem;
}
.my-m4 {
  margin-top: -4rem;
  margin-bottom: -4rem;
}
.py-m4 {
  padding-top: -4rem;
  padding-bottom: -4rem;
}
.px-m4 {
  padding-left: -4rem;
  padding-right: -4rem;
}
.top-m4 {
  top: -4rem;
}
.left-m4 {
  left: -4rem;
}
.height-px-m4 {
  height: -4rem;
}
.width-px-m4 {
  width: -4rem;
}
.border-radius-m4 {
  border-radius: -4rem;
}
.mt-m3 {
  margin-top: -3rem;
}
.pt-m3 {
  padding-top: -3rem;
}
.mb-m3 {
  margin-bottom: -3rem;
}
.pb-m3 {
  padding-bottom: -3rem;
}
.ml-m3 {
  margin-left: -3rem;
}
.pl-m3 {
  padding-left: -3rem;
}
.mr-m3 {
  margin-right: -3rem;
}
.pr-m3 {
  padding-right: -3rem;
}
.mx-m3 {
  margin-left: -3rem;
  margin-right: -3rem;
}
.my-m3 {
  margin-top: -3rem;
  margin-bottom: -3rem;
}
.py-m3 {
  padding-top: -3rem;
  padding-bottom: -3rem;
}
.px-m3 {
  padding-left: -3rem;
  padding-right: -3rem;
}
.top-m3 {
  top: -3rem;
}
.left-m3 {
  left: -3rem;
}
.height-px-m3 {
  height: -3rem;
}
.width-px-m3 {
  width: -3rem;
}
.border-radius-m3 {
  border-radius: -3rem;
}
.mt-m2 {
  margin-top: -2rem;
}
.pt-m2 {
  padding-top: -2rem;
}
.mb-m2 {
  margin-bottom: -2rem;
}
.pb-m2 {
  padding-bottom: -2rem;
}
.ml-m2 {
  margin-left: -2rem;
}
.pl-m2 {
  padding-left: -2rem;
}
.mr-m2 {
  margin-right: -2rem;
}
.pr-m2 {
  padding-right: -2rem;
}
.mx-m2 {
  margin-left: -2rem;
  margin-right: -2rem;
}
.my-m2 {
  margin-top: -2rem;
  margin-bottom: -2rem;
}
.py-m2 {
  padding-top: -2rem;
  padding-bottom: -2rem;
}
.px-m2 {
  padding-left: -2rem;
  padding-right: -2rem;
}
.top-m2 {
  top: -2rem;
}
.left-m2 {
  left: -2rem;
}
.height-px-m2 {
  height: -2rem;
}
.width-px-m2 {
  width: -2rem;
}
.border-radius-m2 {
  border-radius: -2rem;
}
.mt-m1 {
  margin-top: -1rem;
}
.pt-m1 {
  padding-top: -1rem;
}
.mb-m1 {
  margin-bottom: -1rem;
}
.pb-m1 {
  padding-bottom: -1rem;
}
.ml-m1 {
  margin-left: -1rem;
}
.pl-m1 {
  padding-left: -1rem;
}
.mr-m1 {
  margin-right: -1rem;
}
.pr-m1 {
  padding-right: -1rem;
}
.mx-m1 {
  margin-left: -1rem;
  margin-right: -1rem;
}
.my-m1 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}
.py-m1 {
  padding-top: -1rem;
  padding-bottom: -1rem;
}
.px-m1 {
  padding-left: -1rem;
  padding-right: -1rem;
}
.top-m1 {
  top: -1rem;
}
.left-m1 {
  left: -1rem;
}
.height-px-m1 {
  height: -1rem;
}
.width-px-m1 {
  width: -1rem;
}
.border-radius-m1 {
  border-radius: -1rem;
}
.mt-0 {
  margin-top: 0rem;
}
.pt-0 {
  padding-top: 0rem;
}
.mb-0 {
  margin-bottom: 0rem;
}
.pb-0 {
  padding-bottom: 0rem;
}
.ml-0 {
  margin-left: 0rem;
}
.pl-0 {
  padding-left: 0rem;
}
.mr-0 {
  margin-right: 0rem;
}
.pr-0 {
  padding-right: 0rem;
}
.mx-0 {
  margin-left: 0rem;
  margin-right: 0rem;
}
.my-0 {
  margin-top: 0rem;
  margin-bottom: 0rem;
}
.py-0 {
  padding-top: 0rem;
  padding-bottom: 0rem;
}
.px-0 {
  padding-left: 0rem;
  padding-right: 0rem;
}
.top-0 {
  top: 0rem;
}
.left-0 {
  left: 0rem;
}
.height-px-0 {
  height: 0rem;
}
.width-px-0 {
  width: 0rem;
}
.border-radius-0 {
  border-radius: 0rem;
}
.mt-1 {
  margin-top: 0.063rem;
}
.pt-1 {
  padding-top: 0.063rem;
}
.mb-1 {
  margin-bottom: 0.063rem;
}
.pb-1 {
  padding-bottom: 0.063rem;
}
.ml-1 {
  margin-left: 0.063rem;
}
.pl-1 {
  padding-left: 0.063rem;
}
.mr-1 {
  margin-right: 0.063rem;
}
.pr-1 {
  padding-right: 0.063rem;
}
.mx-1 {
  margin-left: 0.063rem;
  margin-right: 0.063rem;
}
.my-1 {
  margin-top: 0.063rem;
  margin-bottom: 0.063rem;
}
.py-1 {
  padding-top: 0.063rem;
  padding-bottom: 0.063rem;
}
.px-1 {
  padding-left: 0.063rem;
  padding-right: 0.063rem;
}
.top-1 {
  top: 0.063rem;
}
.left-1 {
  left: 0.063rem;
}
.height-px-1 {
  height: 0.063rem;
}
.width-px-1 {
  width: 0.063rem;
}
.border-radius-1 {
  border-radius: 0.063rem;
}
.mt-2 {
  margin-top: 0.125rem;
}
.pt-2 {
  padding-top: 0.125rem;
}
.mb-2 {
  margin-bottom: 0.125rem;
}
.pb-2 {
  padding-bottom: 0.125rem;
}
.ml-2 {
  margin-left: 0.125rem;
}
.pl-2 {
  padding-left: 0.125rem;
}
.mr-2 {
  margin-right: 0.125rem;
}
.pr-2 {
  padding-right: 0.125rem;
}
.mx-2 {
  margin-left: 0.125rem;
  margin-right: 0.125rem;
}
.my-2 {
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
}
.py-2 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}
.px-2 {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}
.top-2 {
  top: 0.125rem;
}
.left-2 {
  left: 0.125rem;
}
.height-px-2 {
  height: 0.125rem;
}
.width-px-2 {
  width: 0.125rem;
}
.border-radius-2 {
  border-radius: 0.125rem;
}
.mt-4 {
  margin-top: 0.25rem;
}
.pt-4 {
  padding-top: 0.25rem;
}
.mb-4 {
  margin-bottom: 0.25rem;
}
.pb-4 {
  padding-bottom: 0.25rem;
}
.ml-4 {
  margin-left: 0.25rem;
}
.pl-4 {
  padding-left: 0.25rem;
}
.mr-4 {
  margin-right: 0.25rem;
}
.pr-4 {
  padding-right: 0.25rem;
}
.mx-4 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.my-4 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.py-4 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.px-4 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.top-4 {
  top: 0.25rem;
}
.left-4 {
  left: 0.25rem;
}
.height-px-4 {
  height: 0.25rem;
}
.width-px-4 {
  width: 0.25rem;
}
.border-radius-4 {
  border-radius: 0.25rem;
}
.mt-8 {
  margin-top: 0.5rem;
}
.pt-8 {
  padding-top: 0.5rem;
}
.mb-8 {
  margin-bottom: 0.5rem;
}
.pb-8 {
  padding-bottom: 0.5rem;
}
.ml-8 {
  margin-left: 0.5rem;
}
.pl-8 {
  padding-left: 0.5rem;
}
.mr-8 {
  margin-right: 0.5rem;
}
.pr-8 {
  padding-right: 0.5rem;
}
.mx-8 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.my-8 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.py-8 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.px-8 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.top-8 {
  top: 0.5rem;
}
.left-8 {
  left: 0.5rem;
}
.height-px-8 {
  height: 0.5rem;
}
.width-px-8 {
  width: 0.5rem;
}
.border-radius-8 {
  border-radius: 0.5rem;
}
.mt-10 {
  margin-top: 0.625rem;
}
.pt-10 {
  padding-top: 0.625rem;
}
.mb-10 {
  margin-bottom: 0.625rem;
}
.pb-10 {
  padding-bottom: 0.625rem;
}
.ml-10 {
  margin-left: 0.625rem;
}
.pl-10 {
  padding-left: 0.625rem;
}
.mr-10 {
  margin-right: 0.625rem;
}
.pr-10 {
  padding-right: 0.625rem;
}
.mx-10 {
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}
.my-10 {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
.py-10 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
.px-10 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
.top-10 {
  top: 0.625rem;
}
.left-10 {
  left: 0.625rem;
}
.height-px-10 {
  height: 0.625rem;
}
.width-px-10 {
  width: 0.625rem;
}
.border-radius-10 {
  border-radius: 0.625rem;
}
.mt-12 {
  margin-top: 0.75rem;
}
.pt-12 {
  padding-top: 0.75rem;
}
.mb-12 {
  margin-bottom: 0.75rem;
}
.pb-12 {
  padding-bottom: 0.75rem;
}
.ml-12 {
  margin-left: 0.75rem;
}
.pl-12 {
  padding-left: 0.75rem;
}
.mr-12 {
  margin-right: 0.75rem;
}
.pr-12 {
  padding-right: 0.75rem;
}
.mx-12 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
.my-12 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.py-12 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.px-12 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.top-12 {
  top: 0.75rem;
}
.left-12 {
  left: 0.75rem;
}
.height-px-12 {
  height: 0.75rem;
}
.width-px-12 {
  width: 0.75rem;
}
.border-radius-12 {
  border-radius: 0.75rem;
}
.mt-14 {
  margin-top: 0.875rem;
}
.pt-14 {
  padding-top: 0.875rem;
}
.mb-14 {
  margin-bottom: 0.875rem;
}
.pb-14 {
  padding-bottom: 0.875rem;
}
.ml-14 {
  margin-left: 0.875rem;
}
.pl-14 {
  padding-left: 0.875rem;
}
.mr-14 {
  margin-right: 0.875rem;
}
.pr-14 {
  padding-right: 0.875rem;
}
.mx-14 {
  margin-left: 0.875rem;
  margin-right: 0.875rem;
}
.my-14 {
  margin-top: 0.875rem;
  margin-bottom: 0.875rem;
}
.py-14 {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}
.px-14 {
  padding-left: 0.875rem;
  padding-right: 0.875rem;
}
.top-14 {
  top: 0.875rem;
}
.left-14 {
  left: 0.875rem;
}
.height-px-14 {
  height: 0.875rem;
}
.width-px-14 {
  width: 0.875rem;
}
.border-radius-14 {
  border-radius: 0.875rem;
}
.mt-16 {
  margin-top: 1rem;
}
.pt-16 {
  padding-top: 1rem;
}
.mb-16 {
  margin-bottom: 1rem;
}
.pb-16 {
  padding-bottom: 1rem;
}
.ml-16 {
  margin-left: 1rem;
}
.pl-16 {
  padding-left: 1rem;
}
.mr-16 {
  margin-right: 1rem;
}
.pr-16 {
  padding-right: 1rem;
}
.mx-16 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.my-16 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.py-16 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.px-16 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.top-16 {
  top: 1rem;
}
.left-16 {
  left: 1rem;
}
.height-px-16 {
  height: 1rem;
}
.width-px-16 {
  width: 1rem;
}
.border-radius-16 {
  border-radius: 1rem;
}
.mt-18 {
  margin-top: 1.125rem;
}
.pt-18 {
  padding-top: 1.125rem;
}
.mb-18 {
  margin-bottom: 1.125rem;
}
.pb-18 {
  padding-bottom: 1.125rem;
}
.ml-18 {
  margin-left: 1.125rem;
}
.pl-18 {
  padding-left: 1.125rem;
}
.mr-18 {
  margin-right: 1.125rem;
}
.pr-18 {
  padding-right: 1.125rem;
}
.mx-18 {
  margin-left: 1.125rem;
  margin-right: 1.125rem;
}
.my-18 {
  margin-top: 1.125rem;
  margin-bottom: 1.125rem;
}
.py-18 {
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
}
.px-18 {
  padding-left: 1.125rem;
  padding-right: 1.125rem;
}
.top-18 {
  top: 1.125rem;
}
.left-18 {
  left: 1.125rem;
}
.height-px-18 {
  height: 1.125rem;
}
.width-px-18 {
  width: 1.125rem;
}
.border-radius-18 {
  border-radius: 1.125rem;
}
.mt-20 {
  margin-top: 1.25rem;
}
.pt-20 {
  padding-top: 1.25rem;
}
.mb-20 {
  margin-bottom: 1.25rem;
}
.pb-20 {
  padding-bottom: 1.25rem;
}
.ml-20 {
  margin-left: 1.25rem;
}
.pl-20 {
  padding-left: 1.25rem;
}
.mr-20 {
  margin-right: 1.25rem;
}
.pr-20 {
  padding-right: 1.25rem;
}
.mx-20 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}
.my-20 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.py-20 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.px-20 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.top-20 {
  top: 1.25rem;
}
.left-20 {
  left: 1.25rem;
}
.height-px-20 {
  height: 1.25rem;
}
.width-px-20 {
  width: 1.25rem;
}
.border-radius-20 {
  border-radius: 1.25rem;
}
.mt-24 {
  margin-top: 1.5rem;
}
.pt-24 {
  padding-top: 1.5rem;
}
.mb-24 {
  margin-bottom: 1.5rem;
}
.pb-24 {
  padding-bottom: 1.5rem;
}
.ml-24 {
  margin-left: 1.5rem;
}
.pl-24 {
  padding-left: 1.5rem;
}
.mr-24 {
  margin-right: 1.5rem;
}
.pr-24 {
  padding-right: 1.5rem;
}
.mx-24 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.my-24 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.py-24 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.px-24 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.top-24 {
  top: 1.5rem;
}
.left-24 {
  left: 1.5rem;
}
.height-px-24 {
  height: 1.5rem;
}
.width-px-24 {
  width: 1.5rem;
}
.border-radius-24 {
  border-radius: 1.5rem;
}
.mt-28 {
  margin-top: 1.75rem;
}
.pt-28 {
  padding-top: 1.75rem;
}
.mb-28 {
  margin-bottom: 1.75rem;
}
.pb-28 {
  padding-bottom: 1.75rem;
}
.ml-28 {
  margin-left: 1.75rem;
}
.pl-28 {
  padding-left: 1.75rem;
}
.mr-28 {
  margin-right: 1.75rem;
}
.pr-28 {
  padding-right: 1.75rem;
}
.mx-28 {
  margin-left: 1.75rem;
  margin-right: 1.75rem;
}
.my-28 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}
.py-28 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}
.px-28 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}
.top-28 {
  top: 1.75rem;
}
.left-28 {
  left: 1.75rem;
}
.height-px-28 {
  height: 1.75rem;
}
.width-px-28 {
  width: 1.75rem;
}
.border-radius-28 {
  border-radius: 1.75rem;
}
.mt-32 {
  margin-top: 2rem;
}
.pt-32 {
  padding-top: 2rem;
}
.mb-32 {
  margin-bottom: 2rem;
}
.pb-32 {
  padding-bottom: 2rem;
}
.ml-32 {
  margin-left: 2rem;
}
.pl-32 {
  padding-left: 2rem;
}
.mr-32 {
  margin-right: 2rem;
}
.pr-32 {
  padding-right: 2rem;
}
.mx-32 {
  margin-left: 2rem;
  margin-right: 2rem;
}
.my-32 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.py-32 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.px-32 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.top-32 {
  top: 2rem;
}
.left-32 {
  left: 2rem;
}
.height-px-32 {
  height: 2rem;
}
.width-px-32 {
  width: 2rem;
}
.border-radius-32 {
  border-radius: 2rem;
}
.mt-36 {
  margin-top: 2.25rem;
}
.pt-36 {
  padding-top: 2.25rem;
}
.mb-36 {
  margin-bottom: 2.25rem;
}
.pb-36 {
  padding-bottom: 2.25rem;
}
.ml-36 {
  margin-left: 2.25rem;
}
.pl-36 {
  padding-left: 2.25rem;
}
.mr-36 {
  margin-right: 2.25rem;
}
.pr-36 {
  padding-right: 2.25rem;
}
.mx-36 {
  margin-left: 2.25rem;
  margin-right: 2.25rem;
}
.my-36 {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
}
.py-36 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}
.px-36 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}
.top-36 {
  top: 2.25rem;
}
.left-36 {
  left: 2.25rem;
}
.height-px-36 {
  height: 2.25rem;
}
.width-px-36 {
  width: 2.25rem;
}
.border-radius-36 {
  border-radius: 2.25rem;
}
.mt-40 {
  margin-top: 2.5rem;
}
.pt-40 {
  padding-top: 2.5rem;
}
.mb-40 {
  margin-bottom: 2.5rem;
}
.pb-40 {
  padding-bottom: 2.5rem;
}
.ml-40 {
  margin-left: 2.5rem;
}
.pl-40 {
  padding-left: 2.5rem;
}
.mr-40 {
  margin-right: 2.5rem;
}
.pr-40 {
  padding-right: 2.5rem;
}
.mx-40 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}
.my-40 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.py-40 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.px-40 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.top-40 {
  top: 2.5rem;
}
.left-40 {
  left: 2.5rem;
}
.height-px-40 {
  height: 2.5rem;
}
.width-px-40 {
  width: 2.5rem;
}
.border-radius-40 {
  border-radius: 2.5rem;
}
.mt-48 {
  margin-top: 3rem;
}
.pt-48 {
  padding-top: 3rem;
}
.mb-48 {
  margin-bottom: 3rem;
}
.pb-48 {
  padding-bottom: 3rem;
}
.ml-48 {
  margin-left: 3rem;
}
.pl-48 {
  padding-left: 3rem;
}
.mr-48 {
  margin-right: 3rem;
}
.pr-48 {
  padding-right: 3rem;
}
.mx-48 {
  margin-left: 3rem;
  margin-right: 3rem;
}
.my-48 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.py-48 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.px-48 {
  padding-left: 3rem;
  padding-right: 3rem;
}
.top-48 {
  top: 3rem;
}
.left-48 {
  left: 3rem;
}
.height-px-48 {
  height: 3rem;
}
.width-px-48 {
  width: 3rem;
}
.border-radius-48 {
  border-radius: 3rem;
}
.mt-56 {
  margin-top: 3.5rem;
}
.pt-56 {
  padding-top: 3.5rem;
}
.mb-56 {
  margin-bottom: 3.5rem;
}
.pb-56 {
  padding-bottom: 3.5rem;
}
.ml-56 {
  margin-left: 3.5rem;
}
.pl-56 {
  padding-left: 3.5rem;
}
.mr-56 {
  margin-right: 3.5rem;
}
.pr-56 {
  padding-right: 3.5rem;
}
.mx-56 {
  margin-left: 3.5rem;
  margin-right: 3.5rem;
}
.my-56 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}
.py-56 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}
.px-56 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}
.top-56 {
  top: 3.5rem;
}
.left-56 {
  left: 3.5rem;
}
.height-px-56 {
  height: 3.5rem;
}
.width-px-56 {
  width: 3.5rem;
}
.border-radius-56 {
  border-radius: 3.5rem;
}
.mt-64 {
  margin-top: 4rem;
}
.pt-64 {
  padding-top: 4rem;
}
.mb-64 {
  margin-bottom: 4rem;
}
.pb-64 {
  padding-bottom: 4rem;
}
.ml-64 {
  margin-left: 4rem;
}
.pl-64 {
  padding-left: 4rem;
}
.mr-64 {
  margin-right: 4rem;
}
.pr-64 {
  padding-right: 4rem;
}
.mx-64 {
  margin-left: 4rem;
  margin-right: 4rem;
}
.my-64 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.py-64 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.px-64 {
  padding-left: 4rem;
  padding-right: 4rem;
}
.top-64 {
  top: 4rem;
}
.left-64 {
  left: 4rem;
}
.height-px-64 {
  height: 4rem;
}
.width-px-64 {
  width: 4rem;
}
.border-radius-64 {
  border-radius: 4rem;
}
.mt-72 {
  margin-top: 4.5rem;
}
.pt-72 {
  padding-top: 4.5rem;
}
.mb-72 {
  margin-bottom: 4.5rem;
}
.pb-72 {
  padding-bottom: 4.5rem;
}
.ml-72 {
  margin-left: 4.5rem;
}
.pl-72 {
  padding-left: 4.5rem;
}
.mr-72 {
  margin-right: 4.5rem;
}
.pr-72 {
  padding-right: 4.5rem;
}
.mx-72 {
  margin-left: 4.5rem;
  margin-right: 4.5rem;
}
.my-72 {
  margin-top: 4.5rem;
  margin-bottom: 4.5rem;
}
.py-72 {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
}
.px-72 {
  padding-left: 4.5rem;
  padding-right: 4.5rem;
}
.top-72 {
  top: 4.5rem;
}
.left-72 {
  left: 4.5rem;
}
.height-px-72 {
  height: 4.5rem;
}
.width-px-72 {
  width: 4.5rem;
}
.border-radius-72 {
  border-radius: 4.5rem;
}
.mt-100 {
  margin-top: 6.25rem;
}
.pt-100 {
  padding-top: 6.25rem;
}
.mb-100 {
  margin-bottom: 6.25rem;
}
.pb-100 {
  padding-bottom: 6.25rem;
}
.ml-100 {
  margin-left: 6.25rem;
}
.pl-100 {
  padding-left: 6.25rem;
}
.mr-100 {
  margin-right: 6.25rem;
}
.pr-100 {
  padding-right: 6.25rem;
}
.mx-100 {
  margin-left: 6.25rem;
  margin-right: 6.25rem;
}
.my-100 {
  margin-top: 6.25rem;
  margin-bottom: 6.25rem;
}
.py-100 {
  padding-top: 6.25rem;
  padding-bottom: 6.25rem;
}
.px-100 {
  padding-left: 6.25rem;
  padding-right: 6.25rem;
}
.top-100 {
  top: 6.25rem;
}
.left-100 {
  left: 6.25rem;
}
.height-px-100 {
  height: 6.25rem;
}
.width-px-100 {
  width: 6.25rem;
}
.border-radius-100 {
  border-radius: 6.25rem;
}
.mt-112 {
  margin-top: 7rem;
}
.pt-112 {
  padding-top: 7rem;
}
.mb-112 {
  margin-bottom: 7rem;
}
.pb-112 {
  padding-bottom: 7rem;
}
.ml-112 {
  margin-left: 7rem;
}
.pl-112 {
  padding-left: 7rem;
}
.mr-112 {
  margin-right: 7rem;
}
.pr-112 {
  padding-right: 7rem;
}
.mx-112 {
  margin-left: 7rem;
  margin-right: 7rem;
}
.my-112 {
  margin-top: 7rem;
  margin-bottom: 7rem;
}
.py-112 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}
.px-112 {
  padding-left: 7rem;
  padding-right: 7rem;
}
.top-112 {
  top: 7rem;
}
.left-112 {
  left: 7rem;
}
.height-px-112 {
  height: 7rem;
}
.width-px-112 {
  width: 7rem;
}
.border-radius-112 {
  border-radius: 7rem;
}
.mt-120 {
  margin-top: 7.5rem;
}
.pt-120 {
  padding-top: 7.5rem;
}
.mb-120 {
  margin-bottom: 7.5rem;
}
.pb-120 {
  padding-bottom: 7.5rem;
}
.ml-120 {
  margin-left: 7.5rem;
}
.pl-120 {
  padding-left: 7.5rem;
}
.mr-120 {
  margin-right: 7.5rem;
}
.pr-120 {
  padding-right: 7.5rem;
}
.mx-120 {
  margin-left: 7.5rem;
  margin-right: 7.5rem;
}
.my-120 {
  margin-top: 7.5rem;
  margin-bottom: 7.5rem;
}
.py-120 {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
}
.px-120 {
  padding-left: 7.5rem;
  padding-right: 7.5rem;
}
.top-120 {
  top: 7.5rem;
}
.left-120 {
  left: 7.5rem;
}
.height-px-120 {
  height: 7.5rem;
}
.width-px-120 {
  width: 7.5rem;
}
.border-radius-120 {
  border-radius: 7.5rem;
}
.mt-124 {
  margin-top: 8rem;
}
.pt-124 {
  padding-top: 8rem;
}
.mb-124 {
  margin-bottom: 8rem;
}
.pb-124 {
  padding-bottom: 8rem;
}
.ml-124 {
  margin-left: 8rem;
}
.pl-124 {
  padding-left: 8rem;
}
.mr-124 {
  margin-right: 8rem;
}
.pr-124 {
  padding-right: 8rem;
}
.mx-124 {
  margin-left: 8rem;
  margin-right: 8rem;
}
.my-124 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}
.py-124 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}
.px-124 {
  padding-left: 8rem;
  padding-right: 8rem;
}
.top-124 {
  top: 8rem;
}
.left-124 {
  left: 8rem;
}
.height-px-124 {
  height: 8rem;
}
.width-px-124 {
  width: 8rem;
}
.border-radius-124 {
  border-radius: 8rem;
}
.mt-136 {
  margin-top: 8.5rem;
}
.pt-136 {
  padding-top: 8.5rem;
}
.mb-136 {
  margin-bottom: 8.5rem;
}
.pb-136 {
  padding-bottom: 8.5rem;
}
.ml-136 {
  margin-left: 8.5rem;
}
.pl-136 {
  padding-left: 8.5rem;
}
.mr-136 {
  margin-right: 8.5rem;
}
.pr-136 {
  padding-right: 8.5rem;
}
.mx-136 {
  margin-left: 8.5rem;
  margin-right: 8.5rem;
}
.my-136 {
  margin-top: 8.5rem;
  margin-bottom: 8.5rem;
}
.py-136 {
  padding-top: 8.5rem;
  padding-bottom: 8.5rem;
}
.px-136 {
  padding-left: 8.5rem;
  padding-right: 8.5rem;
}
.top-136 {
  top: 8.5rem;
}
.left-136 {
  left: 8.5rem;
}
.height-px-136 {
  height: 8.5rem;
}
.width-px-136 {
  width: 8.5rem;
}
.border-radius-136 {
  border-radius: 8.5rem;
}
.mt-144 {
  margin-top: 9rem;
}
.pt-144 {
  padding-top: 9rem;
}
.mb-144 {
  margin-bottom: 9rem;
}
.pb-144 {
  padding-bottom: 9rem;
}
.ml-144 {
  margin-left: 9rem;
}
.pl-144 {
  padding-left: 9rem;
}
.mr-144 {
  margin-right: 9rem;
}
.pr-144 {
  padding-right: 9rem;
}
.mx-144 {
  margin-left: 9rem;
  margin-right: 9rem;
}
.my-144 {
  margin-top: 9rem;
  margin-bottom: 9rem;
}
.py-144 {
  padding-top: 9rem;
  padding-bottom: 9rem;
}
.px-144 {
  padding-left: 9rem;
  padding-right: 9rem;
}
.top-144 {
  top: 9rem;
}
.left-144 {
  left: 9rem;
}
.height-px-144 {
  height: 9rem;
}
.width-px-144 {
  width: 9rem;
}
.border-radius-144 {
  border-radius: 9rem;
}
.mt-152 {
  margin-top: 9.5rem;
}
.pt-152 {
  padding-top: 9.5rem;
}
.mb-152 {
  margin-bottom: 9.5rem;
}
.pb-152 {
  padding-bottom: 9.5rem;
}
.ml-152 {
  margin-left: 9.5rem;
}
.pl-152 {
  padding-left: 9.5rem;
}
.mr-152 {
  margin-right: 9.5rem;
}
.pr-152 {
  padding-right: 9.5rem;
}
.mx-152 {
  margin-left: 9.5rem;
  margin-right: 9.5rem;
}
.my-152 {
  margin-top: 9.5rem;
  margin-bottom: 9.5rem;
}
.py-152 {
  padding-top: 9.5rem;
  padding-bottom: 9.5rem;
}
.px-152 {
  padding-left: 9.5rem;
  padding-right: 9.5rem;
}
.top-152 {
  top: 9.5rem;
}
.left-152 {
  left: 9.5rem;
}
.height-px-152 {
  height: 9.5rem;
}
.width-px-152 {
  width: 9.5rem;
}
.border-radius-152 {
  border-radius: 9.5rem;
}
.mt-160 {
  margin-top: 10rem;
}
.pt-160 {
  padding-top: 10rem;
}
.mb-160 {
  margin-bottom: 10rem;
}
.pb-160 {
  padding-bottom: 10rem;
}
.ml-160 {
  margin-left: 10rem;
}
.pl-160 {
  padding-left: 10rem;
}
.mr-160 {
  margin-right: 10rem;
}
.pr-160 {
  padding-right: 10rem;
}
.mx-160 {
  margin-left: 10rem;
  margin-right: 10rem;
}
.my-160 {
  margin-top: 10rem;
  margin-bottom: 10rem;
}
.py-160 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}
.px-160 {
  padding-left: 10rem;
  padding-right: 10rem;
}
.top-160 {
  top: 10rem;
}
.left-160 {
  left: 10rem;
}
.height-px-160 {
  height: 10rem;
}
.width-px-160 {
  width: 10rem;
}
.border-radius-160 {
  border-radius: 10rem;
}
.mt-168 {
  margin-top: 10.5rem;
}
.pt-168 {
  padding-top: 10.5rem;
}
.mb-168 {
  margin-bottom: 10.5rem;
}
.pb-168 {
  padding-bottom: 10.5rem;
}
.ml-168 {
  margin-left: 10.5rem;
}
.pl-168 {
  padding-left: 10.5rem;
}
.mr-168 {
  margin-right: 10.5rem;
}
.pr-168 {
  padding-right: 10.5rem;
}
.mx-168 {
  margin-left: 10.5rem;
  margin-right: 10.5rem;
}
.my-168 {
  margin-top: 10.5rem;
  margin-bottom: 10.5rem;
}
.py-168 {
  padding-top: 10.5rem;
  padding-bottom: 10.5rem;
}
.px-168 {
  padding-left: 10.5rem;
  padding-right: 10.5rem;
}
.top-168 {
  top: 10.5rem;
}
.left-168 {
  left: 10.5rem;
}
.height-px-168 {
  height: 10.5rem;
}
.width-px-168 {
  width: 10.5rem;
}
.border-radius-168 {
  border-radius: 10.5rem;
}
.mt-176 {
  margin-top: 11rem;
}
.pt-176 {
  padding-top: 11rem;
}
.mb-176 {
  margin-bottom: 11rem;
}
.pb-176 {
  padding-bottom: 11rem;
}
.ml-176 {
  margin-left: 11rem;
}
.pl-176 {
  padding-left: 11rem;
}
.mr-176 {
  margin-right: 11rem;
}
.pr-176 {
  padding-right: 11rem;
}
.mx-176 {
  margin-left: 11rem;
  margin-right: 11rem;
}
.my-176 {
  margin-top: 11rem;
  margin-bottom: 11rem;
}
.py-176 {
  padding-top: 11rem;
  padding-bottom: 11rem;
}
.px-176 {
  padding-left: 11rem;
  padding-right: 11rem;
}
.top-176 {
  top: 11rem;
}
.left-176 {
  left: 11rem;
}
.height-px-176 {
  height: 11rem;
}
.width-px-176 {
  width: 11rem;
}
.border-radius-176 {
  border-radius: 11rem;
}
.mt-184 {
  margin-top: 11.5rem;
}
.pt-184 {
  padding-top: 11.5rem;
}
.mb-184 {
  margin-bottom: 11.5rem;
}
.pb-184 {
  padding-bottom: 11.5rem;
}
.ml-184 {
  margin-left: 11.5rem;
}
.pl-184 {
  padding-left: 11.5rem;
}
.mr-184 {
  margin-right: 11.5rem;
}
.pr-184 {
  padding-right: 11.5rem;
}
.mx-184 {
  margin-left: 11.5rem;
  margin-right: 11.5rem;
}
.my-184 {
  margin-top: 11.5rem;
  margin-bottom: 11.5rem;
}
.py-184 {
  padding-top: 11.5rem;
  padding-bottom: 11.5rem;
}
.px-184 {
  padding-left: 11.5rem;
  padding-right: 11.5rem;
}
.top-184 {
  top: 11.5rem;
}
.left-184 {
  left: 11.5rem;
}
.height-px-184 {
  height: 11.5rem;
}
.width-px-184 {
  width: 11.5rem;
}
.border-radius-184 {
  border-radius: 11.5rem;
}
.mt-192 {
  margin-top: 12rem;
}
.pt-192 {
  padding-top: 12rem;
}
.mb-192 {
  margin-bottom: 12rem;
}
.pb-192 {
  padding-bottom: 12rem;
}
.ml-192 {
  margin-left: 12rem;
}
.pl-192 {
  padding-left: 12rem;
}
.mr-192 {
  margin-right: 12rem;
}
.pr-192 {
  padding-right: 12rem;
}
.mx-192 {
  margin-left: 12rem;
  margin-right: 12rem;
}
.my-192 {
  margin-top: 12rem;
  margin-bottom: 12rem;
}
.py-192 {
  padding-top: 12rem;
  padding-bottom: 12rem;
}
.px-192 {
  padding-left: 12rem;
  padding-right: 12rem;
}
.top-192 {
  top: 12rem;
}
.left-192 {
  left: 12rem;
}
.height-px-192 {
  height: 12rem;
}
.width-px-192 {
  width: 12rem;
}
.border-radius-192 {
  border-radius: 12rem;
}
.mt-200 {
  margin-top: 12.5rem;
}
.pt-200 {
  padding-top: 12.5rem;
}
.mb-200 {
  margin-bottom: 12.5rem;
}
.pb-200 {
  padding-bottom: 12.5rem;
}
.ml-200 {
  margin-left: 12.5rem;
}
.pl-200 {
  padding-left: 12.5rem;
}
.mr-200 {
  margin-right: 12.5rem;
}
.pr-200 {
  padding-right: 12.5rem;
}
.mx-200 {
  margin-left: 12.5rem;
  margin-right: 12.5rem;
}
.my-200 {
  margin-top: 12.5rem;
  margin-bottom: 12.5rem;
}
.py-200 {
  padding-top: 12.5rem;
  padding-bottom: 12.5rem;
}
.px-200 {
  padding-left: 12.5rem;
  padding-right: 12.5rem;
}
.top-200 {
  top: 12.5rem;
}
.left-200 {
  left: 12.5rem;
}
.height-px-200 {
  height: 12.5rem;
}
.width-px-200 {
  width: 12.5rem;
}
.border-radius-200 {
  border-radius: 12.5rem;
}
.position-absolute {
  position: absolute;
}
.position-relative {
  position: relative;
}
.position-fixed {
  position: fixed;
}
.position-sticky {
  position: -webkit-sticky;
  position: sticky;
}
.flex-column {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.flex-column-reverse {
  flex-direction: column-reverse;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-inherit {
  flex-direction: inherit;
}
.flex-unset {
  flex-direction: unset;
}
@media (min-width: 375px) {

  .mobile_footer{
    display: block;
  }

  .mt-xs-m10 {
    margin-top: -10rem;
  }
  .pt-xs-m10 {
    padding-top: -10rem;
  }
  .mb-xs-m10 {
    margin-bottom: -10rem;
  }
  .pb-xs-m10 {
    padding-bottom: -10rem;
  }
  .ml-xs-m10 {
    margin-left: -10rem;
  }
  .pl-xs-m10 {
    padding-left: -10rem;
  }
  .mr-xs-m10 {
    margin-right: -10rem;
  }
  .pr-xs-m10 {
    padding-right: -10rem;
  }
  .mx-xs-m10 {
    margin-left: -10rem;
    margin-right: -10rem;
  }
  .px-xs-m10 {
    padding-left: -10rem;
    padding-right: -10rem;
  }
  .my-xs-m10 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }
  .py-xs-m10 {
    padding-top: -10rem;
    padding-bottom: -10rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-m9 {
    margin-top: -9rem;
  }
  .pt-xs-m9 {
    padding-top: -9rem;
  }
  .mb-xs-m9 {
    margin-bottom: -9rem;
  }
  .pb-xs-m9 {
    padding-bottom: -9rem;
  }
  .ml-xs-m9 {
    margin-left: -9rem;
  }
  .pl-xs-m9 {
    padding-left: -9rem;
  }
  .mr-xs-m9 {
    margin-right: -9rem;
  }
  .pr-xs-m9 {
    padding-right: -9rem;
  }
  .mx-xs-m9 {
    margin-left: -9rem;
    margin-right: -9rem;
  }
  .px-xs-m9 {
    padding-left: -9rem;
    padding-right: -9rem;
  }
  .my-xs-m9 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }
  .py-xs-m9 {
    padding-top: -9rem;
    padding-bottom: -9rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-m8 {
    margin-top: -8rem;
  }
  .pt-xs-m8 {
    padding-top: -8rem;
  }
  .mb-xs-m8 {
    margin-bottom: -8rem;
  }
  .pb-xs-m8 {
    padding-bottom: -8rem;
  }
  .ml-xs-m8 {
    margin-left: -8rem;
  }
  .pl-xs-m8 {
    padding-left: -8rem;
  }
  .mr-xs-m8 {
    margin-right: -8rem;
  }
  .pr-xs-m8 {
    padding-right: -8rem;
  }
  .mx-xs-m8 {
    margin-left: -8rem;
    margin-right: -8rem;
  }
  .px-xs-m8 {
    padding-left: -8rem;
    padding-right: -8rem;
  }
  .my-xs-m8 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }
  .py-xs-m8 {
    padding-top: -8rem;
    padding-bottom: -8rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-m7 {
    margin-top: -7rem;
  }
  .pt-xs-m7 {
    padding-top: -7rem;
  }
  .mb-xs-m7 {
    margin-bottom: -7rem;
  }
  .pb-xs-m7 {
    padding-bottom: -7rem;
  }
  .ml-xs-m7 {
    margin-left: -7rem;
  }
  .pl-xs-m7 {
    padding-left: -7rem;
  }
  .mr-xs-m7 {
    margin-right: -7rem;
  }
  .pr-xs-m7 {
    padding-right: -7rem;
  }
  .mx-xs-m7 {
    margin-left: -7rem;
    margin-right: -7rem;
  }
  .px-xs-m7 {
    padding-left: -7rem;
    padding-right: -7rem;
  }
  .my-xs-m7 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }
  .py-xs-m7 {
    padding-top: -7rem;
    padding-bottom: -7rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-m6 {
    margin-top: -6rem;
  }
  .pt-xs-m6 {
    padding-top: -6rem;
  }
  .mb-xs-m6 {
    margin-bottom: -6rem;
  }
  .pb-xs-m6 {
    padding-bottom: -6rem;
  }
  .ml-xs-m6 {
    margin-left: -6rem;
  }
  .pl-xs-m6 {
    padding-left: -6rem;
  }
  .mr-xs-m6 {
    margin-right: -6rem;
  }
  .pr-xs-m6 {
    padding-right: -6rem;
  }
  .mx-xs-m6 {
    margin-left: -6rem;
    margin-right: -6rem;
  }
  .px-xs-m6 {
    padding-left: -6rem;
    padding-right: -6rem;
  }
  .my-xs-m6 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }
  .py-xs-m6 {
    padding-top: -6rem;
    padding-bottom: -6rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-m5 {
    margin-top: -5rem;
  }
  .pt-xs-m5 {
    padding-top: -5rem;
  }
  .mb-xs-m5 {
    margin-bottom: -5rem;
  }
  .pb-xs-m5 {
    padding-bottom: -5rem;
  }
  .ml-xs-m5 {
    margin-left: -5rem;
  }
  .pl-xs-m5 {
    padding-left: -5rem;
  }
  .mr-xs-m5 {
    margin-right: -5rem;
  }
  .pr-xs-m5 {
    padding-right: -5rem;
  }
  .mx-xs-m5 {
    margin-left: -5rem;
    margin-right: -5rem;
  }
  .px-xs-m5 {
    padding-left: -5rem;
    padding-right: -5rem;
  }
  .my-xs-m5 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .py-xs-m5 {
    padding-top: -5rem;
    padding-bottom: -5rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-m4 {
    margin-top: -4rem;
  }
  .pt-xs-m4 {
    padding-top: -4rem;
  }
  .mb-xs-m4 {
    margin-bottom: -4rem;
  }
  .pb-xs-m4 {
    padding-bottom: -4rem;
  }
  .ml-xs-m4 {
    margin-left: -4rem;
  }
  .pl-xs-m4 {
    padding-left: -4rem;
  }
  .mr-xs-m4 {
    margin-right: -4rem;
  }
  .pr-xs-m4 {
    padding-right: -4rem;
  }
  .mx-xs-m4 {
    margin-left: -4rem;
    margin-right: -4rem;
  }
  .px-xs-m4 {
    padding-left: -4rem;
    padding-right: -4rem;
  }
  .my-xs-m4 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  .py-xs-m4 {
    padding-top: -4rem;
    padding-bottom: -4rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-m3 {
    margin-top: -3rem;
  }
  .pt-xs-m3 {
    padding-top: -3rem;
  }
  .mb-xs-m3 {
    margin-bottom: -3rem;
  }
  .pb-xs-m3 {
    padding-bottom: -3rem;
  }
  .ml-xs-m3 {
    margin-left: -3rem;
  }
  .pl-xs-m3 {
    padding-left: -3rem;
  }
  .mr-xs-m3 {
    margin-right: -3rem;
  }
  .pr-xs-m3 {
    padding-right: -3rem;
  }
  .mx-xs-m3 {
    margin-left: -3rem;
    margin-right: -3rem;
  }
  .px-xs-m3 {
    padding-left: -3rem;
    padding-right: -3rem;
  }
  .my-xs-m3 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .py-xs-m3 {
    padding-top: -3rem;
    padding-bottom: -3rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-m2 {
    margin-top: -2rem;
  }
  .pt-xs-m2 {
    padding-top: -2rem;
  }
  .mb-xs-m2 {
    margin-bottom: -2rem;
  }
  .pb-xs-m2 {
    padding-bottom: -2rem;
  }
  .ml-xs-m2 {
    margin-left: -2rem;
  }
  .pl-xs-m2 {
    padding-left: -2rem;
  }
  .mr-xs-m2 {
    margin-right: -2rem;
  }
  .pr-xs-m2 {
    padding-right: -2rem;
  }
  .mx-xs-m2 {
    margin-left: -2rem;
    margin-right: -2rem;
  }
  .px-xs-m2 {
    padding-left: -2rem;
    padding-right: -2rem;
  }
  .my-xs-m2 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .py-xs-m2 {
    padding-top: -2rem;
    padding-bottom: -2rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-m1 {
    margin-top: -1rem;
  }
  .pt-xs-m1 {
    padding-top: -1rem;
  }
  .mb-xs-m1 {
    margin-bottom: -1rem;
  }
  .pb-xs-m1 {
    padding-bottom: -1rem;
  }
  .ml-xs-m1 {
    margin-left: -1rem;
  }
  .pl-xs-m1 {
    padding-left: -1rem;
  }
  .mr-xs-m1 {
    margin-right: -1rem;
  }
  .pr-xs-m1 {
    padding-right: -1rem;
  }
  .mx-xs-m1 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .px-xs-m1 {
    padding-left: -1rem;
    padding-right: -1rem;
  }
  .my-xs-m1 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .py-xs-m1 {
    padding-top: -1rem;
    padding-bottom: -1rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-0 {
    margin-top: 0rem;
  }
  .pt-xs-0 {
    padding-top: 0rem;
  }
  .mb-xs-0 {
    margin-bottom: 0rem;
  }
  .pb-xs-0 {
    padding-bottom: 0rem;
  }
  .ml-xs-0 {
    margin-left: 0rem;
  }
  .pl-xs-0 {
    padding-left: 0rem;
  }
  .mr-xs-0 {
    margin-right: 0rem;
  }
  .pr-xs-0 {
    padding-right: 0rem;
  }
  .mx-xs-0 {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .px-xs-0 {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .my-xs-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .py-xs-0 {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-1 {
    margin-top: 0.063rem;
  }
  .pt-xs-1 {
    padding-top: 0.063rem;
  }
  .mb-xs-1 {
    margin-bottom: 0.063rem;
  }
  .pb-xs-1 {
    padding-bottom: 0.063rem;
  }
  .ml-xs-1 {
    margin-left: 0.063rem;
  }
  .pl-xs-1 {
    padding-left: 0.063rem;
  }
  .mr-xs-1 {
    margin-right: 0.063rem;
  }
  .pr-xs-1 {
    padding-right: 0.063rem;
  }
  .mx-xs-1 {
    margin-left: 0.063rem;
    margin-right: 0.063rem;
  }
  .px-xs-1 {
    padding-left: 0.063rem;
    padding-right: 0.063rem;
  }
  .my-xs-1 {
    margin-top: 0.063rem;
    margin-bottom: 0.063rem;
  }
  .py-xs-1 {
    padding-top: 0.063rem;
    padding-bottom: 0.063rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-2 {
    margin-top: 0.125rem;
  }
  .pt-xs-2 {
    padding-top: 0.125rem;
  }
  .mb-xs-2 {
    margin-bottom: 0.125rem;
  }
  .pb-xs-2 {
    padding-bottom: 0.125rem;
  }
  .ml-xs-2 {
    margin-left: 0.125rem;
  }
  .pl-xs-2 {
    padding-left: 0.125rem;
  }
  .mr-xs-2 {
    margin-right: 0.125rem;
  }
  .pr-xs-2 {
    padding-right: 0.125rem;
  }
  .mx-xs-2 {
    margin-left: 0.125rem;
    margin-right: 0.125rem;
  }
  .px-xs-2 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }
  .my-xs-2 {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }
  .py-xs-2 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-4 {
    margin-top: 0.25rem;
  }
  .pt-xs-4 {
    padding-top: 0.25rem;
  }
  .mb-xs-4 {
    margin-bottom: 0.25rem;
  }
  .pb-xs-4 {
    padding-bottom: 0.25rem;
  }
  .ml-xs-4 {
    margin-left: 0.25rem;
  }
  .pl-xs-4 {
    padding-left: 0.25rem;
  }
  .mr-xs-4 {
    margin-right: 0.25rem;
  }
  .pr-xs-4 {
    padding-right: 0.25rem;
  }
  .mx-xs-4 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .px-xs-4 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .my-xs-4 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .py-xs-4 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-8 {
    margin-top: 0.5rem;
  }
  .pt-xs-8 {
    padding-top: 0.5rem;
  }
  .mb-xs-8 {
    margin-bottom: 0.5rem;
  }
  .pb-xs-8 {
    padding-bottom: 0.5rem;
  }
  .ml-xs-8 {
    margin-left: 0.5rem;
  }
  .pl-xs-8 {
    padding-left: 0.5rem;
  }
  .mr-xs-8 {
    margin-right: 0.5rem;
  }
  .pr-xs-8 {
    padding-right: 0.5rem;
  }
  .mx-xs-8 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .px-xs-8 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .my-xs-8 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .py-xs-8 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-10 {
    margin-top: 0.625rem;
  }
  .pt-xs-10 {
    padding-top: 0.625rem;
  }
  .mb-xs-10 {
    margin-bottom: 0.625rem;
  }
  .pb-xs-10 {
    padding-bottom: 0.625rem;
  }
  .ml-xs-10 {
    margin-left: 0.625rem;
  }
  .pl-xs-10 {
    padding-left: 0.625rem;
  }
  .mr-xs-10 {
    margin-right: 0.625rem;
  }
  .pr-xs-10 {
    padding-right: 0.625rem;
  }
  .mx-xs-10 {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
  .px-xs-10 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .my-xs-10 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .py-xs-10 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-12 {
    margin-top: 0.75rem;
  }
  .pt-xs-12 {
    padding-top: 0.75rem;
  }
  .mb-xs-12 {
    margin-bottom: 0.75rem;
  }
  .pb-xs-12 {
    padding-bottom: 0.75rem;
  }
  .ml-xs-12 {
    margin-left: 0.75rem;
  }
  .pl-xs-12 {
    padding-left: 0.75rem;
  }
  .mr-xs-12 {
    margin-right: 0.75rem;
  }
  .pr-xs-12 {
    padding-right: 0.75rem;
  }
  .mx-xs-12 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
  .px-xs-12 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .my-xs-12 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .py-xs-12 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-14 {
    margin-top: 0.875rem;
  }
  .pt-xs-14 {
    padding-top: 0.875rem;
  }
  .mb-xs-14 {
    margin-bottom: 0.875rem;
  }
  .pb-xs-14 {
    padding-bottom: 0.875rem;
  }
  .ml-xs-14 {
    margin-left: 0.875rem;
  }
  .pl-xs-14 {
    padding-left: 0.875rem;
  }
  .mr-xs-14 {
    margin-right: 0.875rem;
  }
  .pr-xs-14 {
    padding-right: 0.875rem;
  }
  .mx-xs-14 {
    margin-left: 0.875rem;
    margin-right: 0.875rem;
  }
  .px-xs-14 {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
  }
  .my-xs-14 {
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
  }
  .py-xs-14 {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-16 {
    margin-top: 1rem;
  }
  .pt-xs-16 {
    padding-top: 1rem;
  }
  .mb-xs-16 {
    margin-bottom: 1rem;
  }
  .pb-xs-16 {
    padding-bottom: 1rem;
  }
  .ml-xs-16 {
    margin-left: 1rem;
  }
  .pl-xs-16 {
    padding-left: 1rem;
  }
  .mr-xs-16 {
    margin-right: 1rem;
  }
  .pr-xs-16 {
    padding-right: 1rem;
  }
  .mx-xs-16 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .px-xs-16 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .my-xs-16 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .py-xs-16 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-18 {
    margin-top: 1.125rem;
  }
  .pt-xs-18 {
    padding-top: 1.125rem;
  }
  .mb-xs-18 {
    margin-bottom: 1.125rem;
  }
  .pb-xs-18 {
    padding-bottom: 1.125rem;
  }
  .ml-xs-18 {
    margin-left: 1.125rem;
  }
  .pl-xs-18 {
    padding-left: 1.125rem;
  }
  .mr-xs-18 {
    margin-right: 1.125rem;
  }
  .pr-xs-18 {
    padding-right: 1.125rem;
  }
  .mx-xs-18 {
    margin-left: 1.125rem;
    margin-right: 1.125rem;
  }
  .px-xs-18 {
    padding-left: 1.125rem;
    padding-right: 1.125rem;
  }
  .my-xs-18 {
    margin-top: 1.125rem;
    margin-bottom: 1.125rem;
  }
  .py-xs-18 {
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-20 {
    margin-top: 1.25rem;
  }
  .pt-xs-20 {
    padding-top: 1.25rem;
  }
  .mb-xs-20 {
    margin-bottom: 1.25rem;
  }
  .pb-xs-20 {
    padding-bottom: 1.25rem;
  }
  .ml-xs-20 {
    margin-left: 1.25rem;
  }
  .pl-xs-20 {
    padding-left: 1.25rem;
  }
  .mr-xs-20 {
    margin-right: 1.25rem;
  }
  .pr-xs-20 {
    padding-right: 1.25rem;
  }
  .mx-xs-20 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .px-xs-20 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .my-xs-20 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .py-xs-20 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-24 {
    margin-top: 1.5rem;
  }
  .pt-xs-24 {
    padding-top: 1.5rem;
  }
  .mb-xs-24 {
    margin-bottom: 1.5rem;
  }
  .pb-xs-24 {
    padding-bottom: 1.5rem;
  }
  .ml-xs-24 {
    margin-left: 1.5rem;
  }
  .pl-xs-24 {
    padding-left: 1.5rem;
  }
  .mr-xs-24 {
    margin-right: 1.5rem;
  }
  .pr-xs-24 {
    padding-right: 1.5rem;
  }
  .mx-xs-24 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .px-xs-24 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .my-xs-24 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .py-xs-24 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-28 {
    margin-top: 1.75rem;
  }
  .pt-xs-28 {
    padding-top: 1.75rem;
  }
  .mb-xs-28 {
    margin-bottom: 1.75rem;
  }
  .pb-xs-28 {
    padding-bottom: 1.75rem;
  }
  .ml-xs-28 {
    margin-left: 1.75rem;
  }
  .pl-xs-28 {
    padding-left: 1.75rem;
  }
  .mr-xs-28 {
    margin-right: 1.75rem;
  }
  .pr-xs-28 {
    padding-right: 1.75rem;
  }
  .mx-xs-28 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }
  .px-xs-28 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .my-xs-28 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  .py-xs-28 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-32 {
    margin-top: 2rem;
  }
  .pt-xs-32 {
    padding-top: 2rem;
  }
  .mb-xs-32 {
    margin-bottom: 2rem;
  }
  .pb-xs-32 {
    padding-bottom: 2rem;
  }
  .ml-xs-32 {
    margin-left: 2rem;
  }
  .pl-xs-32 {
    padding-left: 2rem;
  }
  .mr-xs-32 {
    margin-right: 2rem;
  }
  .pr-xs-32 {
    padding-right: 2rem;
  }
  .mx-xs-32 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .px-xs-32 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .my-xs-32 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .py-xs-32 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-36 {
    margin-top: 2.25rem;
  }
  .pt-xs-36 {
    padding-top: 2.25rem;
  }
  .mb-xs-36 {
    margin-bottom: 2.25rem;
  }
  .pb-xs-36 {
    padding-bottom: 2.25rem;
  }
  .ml-xs-36 {
    margin-left: 2.25rem;
  }
  .pl-xs-36 {
    padding-left: 2.25rem;
  }
  .mr-xs-36 {
    margin-right: 2.25rem;
  }
  .pr-xs-36 {
    padding-right: 2.25rem;
  }
  .mx-xs-36 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }
  .px-xs-36 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  .my-xs-36 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }
  .py-xs-36 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-40 {
    margin-top: 2.5rem;
  }
  .pt-xs-40 {
    padding-top: 2.5rem;
  }
  .mb-xs-40 {
    margin-bottom: 2.5rem;
  }
  .pb-xs-40 {
    padding-bottom: 2.5rem;
  }
  .ml-xs-40 {
    margin-left: 2.5rem;
  }
  .pl-xs-40 {
    padding-left: 2.5rem;
  }
  .mr-xs-40 {
    margin-right: 2.5rem;
  }
  .pr-xs-40 {
    padding-right: 2.5rem;
  }
  .mx-xs-40 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .px-xs-40 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .my-xs-40 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .py-xs-40 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-48 {
    margin-top: 3rem;
  }
  .pt-xs-48 {
    padding-top: 3rem;
  }
  .mb-xs-48 {
    margin-bottom: 3rem;
  }
  .pb-xs-48 {
    padding-bottom: 3rem;
  }
  .ml-xs-48 {
    margin-left: 3rem;
  }
  .pl-xs-48 {
    padding-left: 3rem;
  }
  .mr-xs-48 {
    margin-right: 3rem;
  }
  .pr-xs-48 {
    padding-right: 3rem;
  }
  .mx-xs-48 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .px-xs-48 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .my-xs-48 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .py-xs-48 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-56 {
    margin-top: 3.5rem;
  }
  .pt-xs-56 {
    padding-top: 3.5rem;
  }
  .mb-xs-56 {
    margin-bottom: 3.5rem;
  }
  .pb-xs-56 {
    padding-bottom: 3.5rem;
  }
  .ml-xs-56 {
    margin-left: 3.5rem;
  }
  .pl-xs-56 {
    padding-left: 3.5rem;
  }
  .mr-xs-56 {
    margin-right: 3.5rem;
  }
  .pr-xs-56 {
    padding-right: 3.5rem;
  }
  .mx-xs-56 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }
  .px-xs-56 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .my-xs-56 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .py-xs-56 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-64 {
    margin-top: 4rem;
  }
  .pt-xs-64 {
    padding-top: 4rem;
  }
  .mb-xs-64 {
    margin-bottom: 4rem;
  }
  .pb-xs-64 {
    padding-bottom: 4rem;
  }
  .ml-xs-64 {
    margin-left: 4rem;
  }
  .pl-xs-64 {
    padding-left: 4rem;
  }
  .mr-xs-64 {
    margin-right: 4rem;
  }
  .pr-xs-64 {
    padding-right: 4rem;
  }
  .mx-xs-64 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .px-xs-64 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .my-xs-64 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .py-xs-64 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-72 {
    margin-top: 4.5rem;
  }
  .pt-xs-72 {
    padding-top: 4.5rem;
  }
  .mb-xs-72 {
    margin-bottom: 4.5rem;
  }
  .pb-xs-72 {
    padding-bottom: 4.5rem;
  }
  .ml-xs-72 {
    margin-left: 4.5rem;
  }
  .pl-xs-72 {
    padding-left: 4.5rem;
  }
  .mr-xs-72 {
    margin-right: 4.5rem;
  }
  .pr-xs-72 {
    padding-right: 4.5rem;
  }
  .mx-xs-72 {
    margin-left: 4.5rem;
    margin-right: 4.5rem;
  }
  .px-xs-72 {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  .my-xs-72 {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }
  .py-xs-72 {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-100 {
    margin-top: 6.25rem;
  }
  .pt-xs-100 {
    padding-top: 6.25rem;
  }
  .mb-xs-100 {
    margin-bottom: 6.25rem;
  }
  .pb-xs-100 {
    padding-bottom: 6.25rem;
  }
  .ml-xs-100 {
    margin-left: 6.25rem;
  }
  .pl-xs-100 {
    padding-left: 6.25rem;
  }
  .mr-xs-100 {
    margin-right: 6.25rem;
  }
  .pr-xs-100 {
    padding-right: 6.25rem;
  }
  .mx-xs-100 {
    margin-left: 6.25rem;
    margin-right: 6.25rem;
  }
  .px-xs-100 {
    padding-left: 6.25rem;
    padding-right: 6.25rem;
  }
  .my-xs-100 {
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;
  }
  .py-xs-100 {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-112 {
    margin-top: 7rem;
  }
  .pt-xs-112 {
    padding-top: 7rem;
  }
  .mb-xs-112 {
    margin-bottom: 7rem;
  }
  .pb-xs-112 {
    padding-bottom: 7rem;
  }
  .ml-xs-112 {
    margin-left: 7rem;
  }
  .pl-xs-112 {
    padding-left: 7rem;
  }
  .mr-xs-112 {
    margin-right: 7rem;
  }
  .pr-xs-112 {
    padding-right: 7rem;
  }
  .mx-xs-112 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .px-xs-112 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .my-xs-112 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .py-xs-112 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-120 {
    margin-top: 7.5rem;
  }
  .pt-xs-120 {
    padding-top: 7.5rem;
  }
  .mb-xs-120 {
    margin-bottom: 7.5rem;
  }
  .pb-xs-120 {
    padding-bottom: 7.5rem;
  }
  .ml-xs-120 {
    margin-left: 7.5rem;
  }
  .pl-xs-120 {
    padding-left: 7.5rem;
  }
  .mr-xs-120 {
    margin-right: 7.5rem;
  }
  .pr-xs-120 {
    padding-right: 7.5rem;
  }
  .mx-xs-120 {
    margin-left: 7.5rem;
    margin-right: 7.5rem;
  }
  .px-xs-120 {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }
  .my-xs-120 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }
  .py-xs-120 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-124 {
    margin-top: 8rem;
  }
  .pt-xs-124 {
    padding-top: 8rem;
  }
  .mb-xs-124 {
    margin-bottom: 8rem;
  }
  .pb-xs-124 {
    padding-bottom: 8rem;
  }
  .ml-xs-124 {
    margin-left: 8rem;
  }
  .pl-xs-124 {
    padding-left: 8rem;
  }
  .mr-xs-124 {
    margin-right: 8rem;
  }
  .pr-xs-124 {
    padding-right: 8rem;
  }
  .mx-xs-124 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .px-xs-124 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .my-xs-124 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .py-xs-124 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-136 {
    margin-top: 8.5rem;
  }
  .pt-xs-136 {
    padding-top: 8.5rem;
  }
  .mb-xs-136 {
    margin-bottom: 8.5rem;
  }
  .pb-xs-136 {
    padding-bottom: 8.5rem;
  }
  .ml-xs-136 {
    margin-left: 8.5rem;
  }
  .pl-xs-136 {
    padding-left: 8.5rem;
  }
  .mr-xs-136 {
    margin-right: 8.5rem;
  }
  .pr-xs-136 {
    padding-right: 8.5rem;
  }
  .mx-xs-136 {
    margin-left: 8.5rem;
    margin-right: 8.5rem;
  }
  .px-xs-136 {
    padding-left: 8.5rem;
    padding-right: 8.5rem;
  }
  .my-xs-136 {
    margin-top: 8.5rem;
    margin-bottom: 8.5rem;
  }
  .py-xs-136 {
    padding-top: 8.5rem;
    padding-bottom: 8.5rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-144 {
    margin-top: 9rem;
  }
  .pt-xs-144 {
    padding-top: 9rem;
  }
  .mb-xs-144 {
    margin-bottom: 9rem;
  }
  .pb-xs-144 {
    padding-bottom: 9rem;
  }
  .ml-xs-144 {
    margin-left: 9rem;
  }
  .pl-xs-144 {
    padding-left: 9rem;
  }
  .mr-xs-144 {
    margin-right: 9rem;
  }
  .pr-xs-144 {
    padding-right: 9rem;
  }
  .mx-xs-144 {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .px-xs-144 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .my-xs-144 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .py-xs-144 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-152 {
    margin-top: 9.5rem;
  }
  .pt-xs-152 {
    padding-top: 9.5rem;
  }
  .mb-xs-152 {
    margin-bottom: 9.5rem;
  }
  .pb-xs-152 {
    padding-bottom: 9.5rem;
  }
  .ml-xs-152 {
    margin-left: 9.5rem;
  }
  .pl-xs-152 {
    padding-left: 9.5rem;
  }
  .mr-xs-152 {
    margin-right: 9.5rem;
  }
  .pr-xs-152 {
    padding-right: 9.5rem;
  }
  .mx-xs-152 {
    margin-left: 9.5rem;
    margin-right: 9.5rem;
  }
  .px-xs-152 {
    padding-left: 9.5rem;
    padding-right: 9.5rem;
  }
  .my-xs-152 {
    margin-top: 9.5rem;
    margin-bottom: 9.5rem;
  }
  .py-xs-152 {
    padding-top: 9.5rem;
    padding-bottom: 9.5rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-160 {
    margin-top: 10rem;
  }
  .pt-xs-160 {
    padding-top: 10rem;
  }
  .mb-xs-160 {
    margin-bottom: 10rem;
  }
  .pb-xs-160 {
    padding-bottom: 10rem;
  }
  .ml-xs-160 {
    margin-left: 10rem;
  }
  .pl-xs-160 {
    padding-left: 10rem;
  }
  .mr-xs-160 {
    margin-right: 10rem;
  }
  .pr-xs-160 {
    padding-right: 10rem;
  }
  .mx-xs-160 {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .px-xs-160 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .my-xs-160 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .py-xs-160 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-168 {
    margin-top: 10.5rem;
  }
  .pt-xs-168 {
    padding-top: 10.5rem;
  }
  .mb-xs-168 {
    margin-bottom: 10.5rem;
  }
  .pb-xs-168 {
    padding-bottom: 10.5rem;
  }
  .ml-xs-168 {
    margin-left: 10.5rem;
  }
  .pl-xs-168 {
    padding-left: 10.5rem;
  }
  .mr-xs-168 {
    margin-right: 10.5rem;
  }
  .pr-xs-168 {
    padding-right: 10.5rem;
  }
  .mx-xs-168 {
    margin-left: 10.5rem;
    margin-right: 10.5rem;
  }
  .px-xs-168 {
    padding-left: 10.5rem;
    padding-right: 10.5rem;
  }
  .my-xs-168 {
    margin-top: 10.5rem;
    margin-bottom: 10.5rem;
  }
  .py-xs-168 {
    padding-top: 10.5rem;
    padding-bottom: 10.5rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-176 {
    margin-top: 11rem;
  }
  .pt-xs-176 {
    padding-top: 11rem;
  }
  .mb-xs-176 {
    margin-bottom: 11rem;
  }
  .pb-xs-176 {
    padding-bottom: 11rem;
  }
  .ml-xs-176 {
    margin-left: 11rem;
  }
  .pl-xs-176 {
    padding-left: 11rem;
  }
  .mr-xs-176 {
    margin-right: 11rem;
  }
  .pr-xs-176 {
    padding-right: 11rem;
  }
  .mx-xs-176 {
    margin-left: 11rem;
    margin-right: 11rem;
  }
  .px-xs-176 {
    padding-left: 11rem;
    padding-right: 11rem;
  }
  .my-xs-176 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }
  .py-xs-176 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-184 {
    margin-top: 11.5rem;
  }
  .pt-xs-184 {
    padding-top: 11.5rem;
  }
  .mb-xs-184 {
    margin-bottom: 11.5rem;
  }
  .pb-xs-184 {
    padding-bottom: 11.5rem;
  }
  .ml-xs-184 {
    margin-left: 11.5rem;
  }
  .pl-xs-184 {
    padding-left: 11.5rem;
  }
  .mr-xs-184 {
    margin-right: 11.5rem;
  }
  .pr-xs-184 {
    padding-right: 11.5rem;
  }
  .mx-xs-184 {
    margin-left: 11.5rem;
    margin-right: 11.5rem;
  }
  .px-xs-184 {
    padding-left: 11.5rem;
    padding-right: 11.5rem;
  }
  .my-xs-184 {
    margin-top: 11.5rem;
    margin-bottom: 11.5rem;
  }
  .py-xs-184 {
    padding-top: 11.5rem;
    padding-bottom: 11.5rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-192 {
    margin-top: 12rem;
  }
  .pt-xs-192 {
    padding-top: 12rem;
  }
  .mb-xs-192 {
    margin-bottom: 12rem;
  }
  .pb-xs-192 {
    padding-bottom: 12rem;
  }
  .ml-xs-192 {
    margin-left: 12rem;
  }
  .pl-xs-192 {
    padding-left: 12rem;
  }
  .mr-xs-192 {
    margin-right: 12rem;
  }
  .pr-xs-192 {
    padding-right: 12rem;
  }
  .mx-xs-192 {
    margin-left: 12rem;
    margin-right: 12rem;
  }
  .px-xs-192 {
    padding-left: 12rem;
    padding-right: 12rem;
  }
  .my-xs-192 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }
  .py-xs-192 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
  .mt-xs-200 {
    margin-top: 12.5rem;
  }
  .pt-xs-200 {
    padding-top: 12.5rem;
  }
  .mb-xs-200 {
    margin-bottom: 12.5rem;
  }
  .pb-xs-200 {
    padding-bottom: 12.5rem;
  }
  .ml-xs-200 {
    margin-left: 12.5rem;
  }
  .pl-xs-200 {
    padding-left: 12.5rem;
  }
  .mr-xs-200 {
    margin-right: 12.5rem;
  }
  .pr-xs-200 {
    padding-right: 12.5rem;
  }
  .mx-xs-200 {
    margin-left: 12.5rem;
    margin-right: 12.5rem;
  }
  .px-xs-200 {
    padding-left: 12.5rem;
    padding-right: 12.5rem;
  }
  .my-xs-200 {
    margin-top: 12.5rem;
    margin-bottom: 12.5rem;
  }
  .py-xs-200 {
    padding-top: 12.5rem;
    padding-bottom: 12.5rem;
  }
  .flex-xs-column {
    flex-direction: column;
  }
  .flex-xs-row {
    flex-direction: row;
  }
  .flex-xs-column-reverse {
    flex-direction: column-reverse;
  }

  .flex-xs-inherit {
    flex-direction: inherit;
  }
  .flex-xs-unset {
    flex-direction: unset;
  }
}
@media (min-width: 576px) {
  .mt-sm-m10 {
    margin-top: -10rem;
  }
  .pt-sm-m10 {
    padding-top: -10rem;
  }
  .mb-sm-m10 {
    margin-bottom: -10rem;
  }
  .pb-sm-m10 {
    padding-bottom: -10rem;
  }
  .ml-sm-m10 {
    margin-left: -10rem;
  }
  .pl-sm-m10 {
    padding-left: -10rem;
  }
  .mr-sm-m10 {
    margin-right: -10rem;
  }
  .pr-sm-m10 {
    padding-right: -10rem;
  }
  .mx-sm-m10 {
    margin-left: -10rem;
    margin-right: -10rem;
  }
  .px-sm-m10 {
    padding-left: -10rem;
    padding-right: -10rem;
  }
  .my-sm-m10 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }
  .py-sm-m10 {
    padding-top: -10rem;
    padding-bottom: -10rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-m9 {
    margin-top: -9rem;
  }
  .pt-sm-m9 {
    padding-top: -9rem;
  }
  .mb-sm-m9 {
    margin-bottom: -9rem;
  }
  .pb-sm-m9 {
    padding-bottom: -9rem;
  }
  .ml-sm-m9 {
    margin-left: -9rem;
  }
  .pl-sm-m9 {
    padding-left: -9rem;
  }
  .mr-sm-m9 {
    margin-right: -9rem;
  }
  .pr-sm-m9 {
    padding-right: -9rem;
  }
  .mx-sm-m9 {
    margin-left: -9rem;
    margin-right: -9rem;
  }
  .px-sm-m9 {
    padding-left: -9rem;
    padding-right: -9rem;
  }
  .my-sm-m9 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }
  .py-sm-m9 {
    padding-top: -9rem;
    padding-bottom: -9rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-m8 {
    margin-top: -8rem;
  }
  .pt-sm-m8 {
    padding-top: -8rem;
  }
  .mb-sm-m8 {
    margin-bottom: -8rem;
  }
  .pb-sm-m8 {
    padding-bottom: -8rem;
  }
  .ml-sm-m8 {
    margin-left: -8rem;
  }
  .pl-sm-m8 {
    padding-left: -8rem;
  }
  .mr-sm-m8 {
    margin-right: -8rem;
  }
  .pr-sm-m8 {
    padding-right: -8rem;
  }
  .mx-sm-m8 {
    margin-left: -8rem;
    margin-right: -8rem;
  }
  .px-sm-m8 {
    padding-left: -8rem;
    padding-right: -8rem;
  }
  .my-sm-m8 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }
  .py-sm-m8 {
    padding-top: -8rem;
    padding-bottom: -8rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-m7 {
    margin-top: -7rem;
  }
  .pt-sm-m7 {
    padding-top: -7rem;
  }
  .mb-sm-m7 {
    margin-bottom: -7rem;
  }
  .pb-sm-m7 {
    padding-bottom: -7rem;
  }
  .ml-sm-m7 {
    margin-left: -7rem;
  }
  .pl-sm-m7 {
    padding-left: -7rem;
  }
  .mr-sm-m7 {
    margin-right: -7rem;
  }
  .pr-sm-m7 {
    padding-right: -7rem;
  }
  .mx-sm-m7 {
    margin-left: -7rem;
    margin-right: -7rem;
  }
  .px-sm-m7 {
    padding-left: -7rem;
    padding-right: -7rem;
  }
  .my-sm-m7 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }
  .py-sm-m7 {
    padding-top: -7rem;
    padding-bottom: -7rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-m6 {
    margin-top: -6rem;
  }
  .pt-sm-m6 {
    padding-top: -6rem;
  }
  .mb-sm-m6 {
    margin-bottom: -6rem;
  }
  .pb-sm-m6 {
    padding-bottom: -6rem;
  }
  .ml-sm-m6 {
    margin-left: -6rem;
  }
  .pl-sm-m6 {
    padding-left: -6rem;
  }
  .mr-sm-m6 {
    margin-right: -6rem;
  }
  .pr-sm-m6 {
    padding-right: -6rem;
  }
  .mx-sm-m6 {
    margin-left: -6rem;
    margin-right: -6rem;
  }
  .px-sm-m6 {
    padding-left: -6rem;
    padding-right: -6rem;
  }
  .my-sm-m6 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }
  .py-sm-m6 {
    padding-top: -6rem;
    padding-bottom: -6rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-m5 {
    margin-top: -5rem;
  }
  .pt-sm-m5 {
    padding-top: -5rem;
  }
  .mb-sm-m5 {
    margin-bottom: -5rem;
  }
  .pb-sm-m5 {
    padding-bottom: -5rem;
  }
  .ml-sm-m5 {
    margin-left: -5rem;
  }
  .pl-sm-m5 {
    padding-left: -5rem;
  }
  .mr-sm-m5 {
    margin-right: -5rem;
  }
  .pr-sm-m5 {
    padding-right: -5rem;
  }
  .mx-sm-m5 {
    margin-left: -5rem;
    margin-right: -5rem;
  }
  .px-sm-m5 {
    padding-left: -5rem;
    padding-right: -5rem;
  }
  .my-sm-m5 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .py-sm-m5 {
    padding-top: -5rem;
    padding-bottom: -5rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-m4 {
    margin-top: -4rem;
  }
  .pt-sm-m4 {
    padding-top: -4rem;
  }
  .mb-sm-m4 {
    margin-bottom: -4rem;
  }
  .pb-sm-m4 {
    padding-bottom: -4rem;
  }
  .ml-sm-m4 {
    margin-left: -4rem;
  }
  .pl-sm-m4 {
    padding-left: -4rem;
  }
  .mr-sm-m4 {
    margin-right: -4rem;
  }
  .pr-sm-m4 {
    padding-right: -4rem;
  }
  .mx-sm-m4 {
    margin-left: -4rem;
    margin-right: -4rem;
  }
  .px-sm-m4 {
    padding-left: -4rem;
    padding-right: -4rem;
  }
  .my-sm-m4 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  .py-sm-m4 {
    padding-top: -4rem;
    padding-bottom: -4rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-m3 {
    margin-top: -3rem;
  }
  .pt-sm-m3 {
    padding-top: -3rem;
  }
  .mb-sm-m3 {
    margin-bottom: -3rem;
  }
  .pb-sm-m3 {
    padding-bottom: -3rem;
  }
  .ml-sm-m3 {
    margin-left: -3rem;
  }
  .pl-sm-m3 {
    padding-left: -3rem;
  }
  .mr-sm-m3 {
    margin-right: -3rem;
  }
  .pr-sm-m3 {
    padding-right: -3rem;
  }
  .mx-sm-m3 {
    margin-left: -3rem;
    margin-right: -3rem;
  }
  .px-sm-m3 {
    padding-left: -3rem;
    padding-right: -3rem;
  }
  .my-sm-m3 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .py-sm-m3 {
    padding-top: -3rem;
    padding-bottom: -3rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-m2 {
    margin-top: -2rem;
  }
  .pt-sm-m2 {
    padding-top: -2rem;
  }
  .mb-sm-m2 {
    margin-bottom: -2rem;
  }
  .pb-sm-m2 {
    padding-bottom: -2rem;
  }
  .ml-sm-m2 {
    margin-left: -2rem;
  }
  .pl-sm-m2 {
    padding-left: -2rem;
  }
  .mr-sm-m2 {
    margin-right: -2rem;
  }
  .pr-sm-m2 {
    padding-right: -2rem;
  }
  .mx-sm-m2 {
    margin-left: -2rem;
    margin-right: -2rem;
  }
  .px-sm-m2 {
    padding-left: -2rem;
    padding-right: -2rem;
  }
  .my-sm-m2 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .py-sm-m2 {
    padding-top: -2rem;
    padding-bottom: -2rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-m1 {
    margin-top: -1rem;
  }
  .pt-sm-m1 {
    padding-top: -1rem;
  }
  .mb-sm-m1 {
    margin-bottom: -1rem;
  }
  .pb-sm-m1 {
    padding-bottom: -1rem;
  }
  .ml-sm-m1 {
    margin-left: -1rem;
  }
  .pl-sm-m1 {
    padding-left: -1rem;
  }
  .mr-sm-m1 {
    margin-right: -1rem;
  }
  .pr-sm-m1 {
    padding-right: -1rem;
  }
  .mx-sm-m1 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .px-sm-m1 {
    padding-left: -1rem;
    padding-right: -1rem;
  }
  .my-sm-m1 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .py-sm-m1 {
    padding-top: -1rem;
    padding-bottom: -1rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-0 {
    margin-top: 0rem;
  }
  .pt-sm-0 {
    padding-top: 0rem;
  }
  .mb-sm-0 {
    margin-bottom: 0rem;
  }
  .pb-sm-0 {
    padding-bottom: 0rem;
  }
  .ml-sm-0 {
    margin-left: 0rem;
  }
  .pl-sm-0 {
    padding-left: 0rem;
  }
  .mr-sm-0 {
    margin-right: 0rem;
  }
  .pr-sm-0 {
    padding-right: 0rem;
  }
  .mx-sm-0 {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .px-sm-0 {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .my-sm-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .py-sm-0 {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-1 {
    margin-top: 0.063rem;
  }
  .pt-sm-1 {
    padding-top: 0.063rem;
  }
  .mb-sm-1 {
    margin-bottom: 0.063rem;
  }
  .pb-sm-1 {
    padding-bottom: 0.063rem;
  }
  .ml-sm-1 {
    margin-left: 0.063rem;
  }
  .pl-sm-1 {
    padding-left: 0.063rem;
  }
  .mr-sm-1 {
    margin-right: 0.063rem;
  }
  .pr-sm-1 {
    padding-right: 0.063rem;
  }
  .mx-sm-1 {
    margin-left: 0.063rem;
    margin-right: 0.063rem;
  }
  .px-sm-1 {
    padding-left: 0.063rem;
    padding-right: 0.063rem;
  }
  .my-sm-1 {
    margin-top: 0.063rem;
    margin-bottom: 0.063rem;
  }
  .py-sm-1 {
    padding-top: 0.063rem;
    padding-bottom: 0.063rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-2 {
    margin-top: 0.125rem;
  }
  .pt-sm-2 {
    padding-top: 0.125rem;
  }
  .mb-sm-2 {
    margin-bottom: 0.125rem;
  }
  .pb-sm-2 {
    padding-bottom: 0.125rem;
  }
  .ml-sm-2 {
    margin-left: 0.125rem;
  }
  .pl-sm-2 {
    padding-left: 0.125rem;
  }
  .mr-sm-2 {
    margin-right: 0.125rem;
  }
  .pr-sm-2 {
    padding-right: 0.125rem;
  }
  .mx-sm-2 {
    margin-left: 0.125rem;
    margin-right: 0.125rem;
  }
  .px-sm-2 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }
  .my-sm-2 {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }
  .py-sm-2 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-4 {
    margin-top: 0.25rem;
  }
  .pt-sm-4 {
    padding-top: 0.25rem;
  }
  .mb-sm-4 {
    margin-bottom: 0.25rem;
  }
  .pb-sm-4 {
    padding-bottom: 0.25rem;
  }
  .ml-sm-4 {
    margin-left: 0.25rem;
  }
  .pl-sm-4 {
    padding-left: 0.25rem;
  }
  .mr-sm-4 {
    margin-right: 0.25rem;
  }
  .pr-sm-4 {
    padding-right: 0.25rem;
  }
  .mx-sm-4 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .px-sm-4 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .my-sm-4 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .py-sm-4 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-8 {
    margin-top: 0.5rem;
  }
  .pt-sm-8 {
    padding-top: 0.5rem;
  }
  .mb-sm-8 {
    margin-bottom: 0.5rem;
  }
  .pb-sm-8 {
    padding-bottom: 0.5rem;
  }
  .ml-sm-8 {
    margin-left: 0.5rem;
  }
  .pl-sm-8 {
    padding-left: 0.5rem;
  }
  .mr-sm-8 {
    margin-right: 0.5rem;
  }
  .pr-sm-8 {
    padding-right: 0.5rem;
  }
  .mx-sm-8 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .px-sm-8 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .my-sm-8 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .py-sm-8 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-10 {
    margin-top: 0.625rem;
  }
  .pt-sm-10 {
    padding-top: 0.625rem;
  }
  .mb-sm-10 {
    margin-bottom: 0.625rem;
  }
  .pb-sm-10 {
    padding-bottom: 0.625rem;
  }
  .ml-sm-10 {
    margin-left: 0.625rem;
  }
  .pl-sm-10 {
    padding-left: 0.625rem;
  }
  .mr-sm-10 {
    margin-right: 0.625rem;
  }
  .pr-sm-10 {
    padding-right: 0.625rem;
  }
  .mx-sm-10 {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
  .px-sm-10 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .my-sm-10 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .py-sm-10 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-12 {
    margin-top: 0.75rem;
  }
  .pt-sm-12 {
    padding-top: 0.75rem;
  }
  .mb-sm-12 {
    margin-bottom: 0.75rem;
  }
  .pb-sm-12 {
    padding-bottom: 0.75rem;
  }
  .ml-sm-12 {
    margin-left: 0.75rem;
  }
  .pl-sm-12 {
    padding-left: 0.75rem;
  }
  .mr-sm-12 {
    margin-right: 0.75rem;
  }
  .pr-sm-12 {
    padding-right: 0.75rem;
  }
  .mx-sm-12 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
  .px-sm-12 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .my-sm-12 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .py-sm-12 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-14 {
    margin-top: 0.875rem;
  }
  .pt-sm-14 {
    padding-top: 0.875rem;
  }
  .mb-sm-14 {
    margin-bottom: 0.875rem;
  }
  .pb-sm-14 {
    padding-bottom: 0.875rem;
  }
  .ml-sm-14 {
    margin-left: 0.875rem;
  }
  .pl-sm-14 {
    padding-left: 0.875rem;
  }
  .mr-sm-14 {
    margin-right: 0.875rem;
  }
  .pr-sm-14 {
    padding-right: 0.875rem;
  }
  .mx-sm-14 {
    margin-left: 0.875rem;
    margin-right: 0.875rem;
  }
  .px-sm-14 {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
  }
  .my-sm-14 {
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
  }
  .py-sm-14 {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-16 {
    margin-top: 1rem;
  }
  .pt-sm-16 {
    padding-top: 1rem;
  }
  .mb-sm-16 {
    margin-bottom: 1rem;
  }
  .pb-sm-16 {
    padding-bottom: 1rem;
  }
  .ml-sm-16 {
    margin-left: 1rem;
  }
  .pl-sm-16 {
    padding-left: 1rem;
  }
  .mr-sm-16 {
    margin-right: 1rem;
  }
  .pr-sm-16 {
    padding-right: 1rem;
  }
  .mx-sm-16 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .px-sm-16 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .my-sm-16 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .py-sm-16 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-18 {
    margin-top: 1.125rem;
  }
  .pt-sm-18 {
    padding-top: 1.125rem;
  }
  .mb-sm-18 {
    margin-bottom: 1.125rem;
  }
  .pb-sm-18 {
    padding-bottom: 1.125rem;
  }
  .ml-sm-18 {
    margin-left: 1.125rem;
  }
  .pl-sm-18 {
    padding-left: 1.125rem;
  }
  .mr-sm-18 {
    margin-right: 1.125rem;
  }
  .pr-sm-18 {
    padding-right: 1.125rem;
  }
  .mx-sm-18 {
    margin-left: 1.125rem;
    margin-right: 1.125rem;
  }
  .px-sm-18 {
    padding-left: 1.125rem;
    padding-right: 1.125rem;
  }
  .my-sm-18 {
    margin-top: 1.125rem;
    margin-bottom: 1.125rem;
  }
  .py-sm-18 {
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-20 {
    margin-top: 1.25rem;
  }
  .pt-sm-20 {
    padding-top: 1.25rem;
  }
  .mb-sm-20 {
    margin-bottom: 1.25rem;
  }
  .pb-sm-20 {
    padding-bottom: 1.25rem;
  }
  .ml-sm-20 {
    margin-left: 1.25rem;
  }
  .pl-sm-20 {
    padding-left: 1.25rem;
  }
  .mr-sm-20 {
    margin-right: 1.25rem;
  }
  .pr-sm-20 {
    padding-right: 1.25rem;
  }
  .mx-sm-20 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .px-sm-20 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .my-sm-20 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .py-sm-20 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-24 {
    margin-top: 1.5rem;
  }
  .pt-sm-24 {
    padding-top: 1.5rem;
  }
  .mb-sm-24 {
    margin-bottom: 1.5rem;
  }
  .pb-sm-24 {
    padding-bottom: 1.5rem;
  }
  .ml-sm-24 {
    margin-left: 1.5rem;
  }
  .pl-sm-24 {
    padding-left: 1.5rem;
  }
  .mr-sm-24 {
    margin-right: 1.5rem;
  }
  .pr-sm-24 {
    padding-right: 1.5rem;
  }
  .mx-sm-24 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .px-sm-24 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .my-sm-24 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .py-sm-24 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-28 {
    margin-top: 1.75rem;
  }
  .pt-sm-28 {
    padding-top: 1.75rem;
  }
  .mb-sm-28 {
    margin-bottom: 1.75rem;
  }
  .pb-sm-28 {
    padding-bottom: 1.75rem;
  }
  .ml-sm-28 {
    margin-left: 1.75rem;
  }
  .pl-sm-28 {
    padding-left: 1.75rem;
  }
  .mr-sm-28 {
    margin-right: 1.75rem;
  }
  .pr-sm-28 {
    padding-right: 1.75rem;
  }
  .mx-sm-28 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }
  .px-sm-28 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .my-sm-28 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  .py-sm-28 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-32 {
    margin-top: 2rem;
  }
  .pt-sm-32 {
    padding-top: 2rem;
  }
  .mb-sm-32 {
    margin-bottom: 2rem;
  }
  .pb-sm-32 {
    padding-bottom: 2rem;
  }
  .ml-sm-32 {
    margin-left: 2rem;
  }
  .pl-sm-32 {
    padding-left: 2rem;
  }
  .mr-sm-32 {
    margin-right: 2rem;
  }
  .pr-sm-32 {
    padding-right: 2rem;
  }
  .mx-sm-32 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .px-sm-32 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .my-sm-32 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .py-sm-32 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-36 {
    margin-top: 2.25rem;
  }
  .pt-sm-36 {
    padding-top: 2.25rem;
  }
  .mb-sm-36 {
    margin-bottom: 2.25rem;
  }
  .pb-sm-36 {
    padding-bottom: 2.25rem;
  }
  .ml-sm-36 {
    margin-left: 2.25rem;
  }
  .pl-sm-36 {
    padding-left: 2.25rem;
  }
  .mr-sm-36 {
    margin-right: 2.25rem;
  }
  .pr-sm-36 {
    padding-right: 2.25rem;
  }
  .mx-sm-36 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }
  .px-sm-36 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  .my-sm-36 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }
  .py-sm-36 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-40 {
    margin-top: 2.5rem;
  }
  .pt-sm-40 {
    padding-top: 2.5rem;
  }
  .mb-sm-40 {
    margin-bottom: 2.5rem;
  }
  .pb-sm-40 {
    padding-bottom: 2.5rem;
  }
  .ml-sm-40 {
    margin-left: 2.5rem;
  }
  .pl-sm-40 {
    padding-left: 2.5rem;
  }
  .mr-sm-40 {
    margin-right: 2.5rem;
  }
  .pr-sm-40 {
    padding-right: 2.5rem;
  }
  .mx-sm-40 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .px-sm-40 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .my-sm-40 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .py-sm-40 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-48 {
    margin-top: 3rem;
  }
  .pt-sm-48 {
    padding-top: 3rem;
  }
  .mb-sm-48 {
    margin-bottom: 3rem;
  }
  .pb-sm-48 {
    padding-bottom: 3rem;
  }
  .ml-sm-48 {
    margin-left: 3rem;
  }
  .pl-sm-48 {
    padding-left: 3rem;
  }
  .mr-sm-48 {
    margin-right: 3rem;
  }
  .pr-sm-48 {
    padding-right: 3rem;
  }
  .mx-sm-48 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .px-sm-48 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .my-sm-48 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .py-sm-48 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-56 {
    margin-top: 3.5rem;
  }
  .pt-sm-56 {
    padding-top: 3.5rem;
  }
  .mb-sm-56 {
    margin-bottom: 3.5rem;
  }
  .pb-sm-56 {
    padding-bottom: 3.5rem;
  }
  .ml-sm-56 {
    margin-left: 3.5rem;
  }
  .pl-sm-56 {
    padding-left: 3.5rem;
  }
  .mr-sm-56 {
    margin-right: 3.5rem;
  }
  .pr-sm-56 {
    padding-right: 3.5rem;
  }
  .mx-sm-56 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }
  .px-sm-56 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .my-sm-56 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .py-sm-56 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-64 {
    margin-top: 4rem;
  }
  .pt-sm-64 {
    padding-top: 4rem;
  }
  .mb-sm-64 {
    margin-bottom: 4rem;
  }
  .pb-sm-64 {
    padding-bottom: 4rem;
  }
  .ml-sm-64 {
    margin-left: 4rem;
  }
  .pl-sm-64 {
    padding-left: 4rem;
  }
  .mr-sm-64 {
    margin-right: 4rem;
  }
  .pr-sm-64 {
    padding-right: 4rem;
  }
  .mx-sm-64 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .px-sm-64 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .my-sm-64 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .py-sm-64 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-72 {
    margin-top: 4.5rem;
  }
  .pt-sm-72 {
    padding-top: 4.5rem;
  }
  .mb-sm-72 {
    margin-bottom: 4.5rem;
  }
  .pb-sm-72 {
    padding-bottom: 4.5rem;
  }
  .ml-sm-72 {
    margin-left: 4.5rem;
  }
  .pl-sm-72 {
    padding-left: 4.5rem;
  }
  .mr-sm-72 {
    margin-right: 4.5rem;
  }
  .pr-sm-72 {
    padding-right: 4.5rem;
  }
  .mx-sm-72 {
    margin-left: 4.5rem;
    margin-right: 4.5rem;
  }
  .px-sm-72 {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  .my-sm-72 {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }
  .py-sm-72 {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-100 {
    margin-top: 6.25rem;
  }
  .pt-sm-100 {
    padding-top: 6.25rem;
  }
  .mb-sm-100 {
    margin-bottom: 6.25rem;
  }
  .pb-sm-100 {
    padding-bottom: 6.25rem;
  }
  .ml-sm-100 {
    margin-left: 6.25rem;
  }
  .pl-sm-100 {
    padding-left: 6.25rem;
  }
  .mr-sm-100 {
    margin-right: 6.25rem;
  }
  .pr-sm-100 {
    padding-right: 6.25rem;
  }
  .mx-sm-100 {
    margin-left: 6.25rem;
    margin-right: 6.25rem;
  }
  .px-sm-100 {
    padding-left: 6.25rem;
    padding-right: 6.25rem;
  }
  .my-sm-100 {
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;
  }
  .py-sm-100 {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-112 {
    margin-top: 7rem;
  }
  .pt-sm-112 {
    padding-top: 7rem;
  }
  .mb-sm-112 {
    margin-bottom: 7rem;
  }
  .pb-sm-112 {
    padding-bottom: 7rem;
  }
  .ml-sm-112 {
    margin-left: 7rem;
  }
  .pl-sm-112 {
    padding-left: 7rem;
  }
  .mr-sm-112 {
    margin-right: 7rem;
  }
  .pr-sm-112 {
    padding-right: 7rem;
  }
  .mx-sm-112 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .px-sm-112 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .my-sm-112 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .py-sm-112 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-120 {
    margin-top: 7.5rem;
  }
  .pt-sm-120 {
    padding-top: 7.5rem;
  }
  .mb-sm-120 {
    margin-bottom: 7.5rem;
  }
  .pb-sm-120 {
    padding-bottom: 7.5rem;
  }
  .ml-sm-120 {
    margin-left: 7.5rem;
  }
  .pl-sm-120 {
    padding-left: 7.5rem;
  }
  .mr-sm-120 {
    margin-right: 7.5rem;
  }
  .pr-sm-120 {
    padding-right: 7.5rem;
  }
  .mx-sm-120 {
    margin-left: 7.5rem;
    margin-right: 7.5rem;
  }
  .px-sm-120 {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }
  .my-sm-120 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }
  .py-sm-120 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-124 {
    margin-top: 8rem;
  }
  .pt-sm-124 {
    padding-top: 8rem;
  }
  .mb-sm-124 {
    margin-bottom: 8rem;
  }
  .pb-sm-124 {
    padding-bottom: 8rem;
  }
  .ml-sm-124 {
    margin-left: 8rem;
  }
  .pl-sm-124 {
    padding-left: 8rem;
  }
  .mr-sm-124 {
    margin-right: 8rem;
  }
  .pr-sm-124 {
    padding-right: 8rem;
  }
  .mx-sm-124 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .px-sm-124 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .my-sm-124 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .py-sm-124 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-136 {
    margin-top: 8.5rem;
  }
  .pt-sm-136 {
    padding-top: 8.5rem;
  }
  .mb-sm-136 {
    margin-bottom: 8.5rem;
  }
  .pb-sm-136 {
    padding-bottom: 8.5rem;
  }
  .ml-sm-136 {
    margin-left: 8.5rem;
  }
  .pl-sm-136 {
    padding-left: 8.5rem;
  }
  .mr-sm-136 {
    margin-right: 8.5rem;
  }
  .pr-sm-136 {
    padding-right: 8.5rem;
  }
  .mx-sm-136 {
    margin-left: 8.5rem;
    margin-right: 8.5rem;
  }
  .px-sm-136 {
    padding-left: 8.5rem;
    padding-right: 8.5rem;
  }
  .my-sm-136 {
    margin-top: 8.5rem;
    margin-bottom: 8.5rem;
  }
  .py-sm-136 {
    padding-top: 8.5rem;
    padding-bottom: 8.5rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-144 {
    margin-top: 9rem;
  }
  .pt-sm-144 {
    padding-top: 9rem;
  }
  .mb-sm-144 {
    margin-bottom: 9rem;
  }
  .pb-sm-144 {
    padding-bottom: 9rem;
  }
  .ml-sm-144 {
    margin-left: 9rem;
  }
  .pl-sm-144 {
    padding-left: 9rem;
  }
  .mr-sm-144 {
    margin-right: 9rem;
  }
  .pr-sm-144 {
    padding-right: 9rem;
  }
  .mx-sm-144 {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .px-sm-144 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .my-sm-144 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .py-sm-144 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-152 {
    margin-top: 9.5rem;
  }
  .pt-sm-152 {
    padding-top: 9.5rem;
  }
  .mb-sm-152 {
    margin-bottom: 9.5rem;
  }
  .pb-sm-152 {
    padding-bottom: 9.5rem;
  }
  .ml-sm-152 {
    margin-left: 9.5rem;
  }
  .pl-sm-152 {
    padding-left: 9.5rem;
  }
  .mr-sm-152 {
    margin-right: 9.5rem;
  }
  .pr-sm-152 {
    padding-right: 9.5rem;
  }
  .mx-sm-152 {
    margin-left: 9.5rem;
    margin-right: 9.5rem;
  }
  .px-sm-152 {
    padding-left: 9.5rem;
    padding-right: 9.5rem;
  }
  .my-sm-152 {
    margin-top: 9.5rem;
    margin-bottom: 9.5rem;
  }
  .py-sm-152 {
    padding-top: 9.5rem;
    padding-bottom: 9.5rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-160 {
    margin-top: 10rem;
  }
  .pt-sm-160 {
    padding-top: 10rem;
  }
  .mb-sm-160 {
    margin-bottom: 10rem;
  }
  .pb-sm-160 {
    padding-bottom: 10rem;
  }
  .ml-sm-160 {
    margin-left: 10rem;
  }
  .pl-sm-160 {
    padding-left: 10rem;
  }
  .mr-sm-160 {
    margin-right: 10rem;
  }
  .pr-sm-160 {
    padding-right: 10rem;
  }
  .mx-sm-160 {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .px-sm-160 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .my-sm-160 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .py-sm-160 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-168 {
    margin-top: 10.5rem;
  }
  .pt-sm-168 {
    padding-top: 10.5rem;
  }
  .mb-sm-168 {
    margin-bottom: 10.5rem;
  }
  .pb-sm-168 {
    padding-bottom: 10.5rem;
  }
  .ml-sm-168 {
    margin-left: 10.5rem;
  }
  .pl-sm-168 {
    padding-left: 10.5rem;
  }
  .mr-sm-168 {
    margin-right: 10.5rem;
  }
  .pr-sm-168 {
    padding-right: 10.5rem;
  }
  .mx-sm-168 {
    margin-left: 10.5rem;
    margin-right: 10.5rem;
  }
  .px-sm-168 {
    padding-left: 10.5rem;
    padding-right: 10.5rem;
  }
  .my-sm-168 {
    margin-top: 10.5rem;
    margin-bottom: 10.5rem;
  }
  .py-sm-168 {
    padding-top: 10.5rem;
    padding-bottom: 10.5rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-176 {
    margin-top: 11rem;
  }
  .pt-sm-176 {
    padding-top: 11rem;
  }
  .mb-sm-176 {
    margin-bottom: 11rem;
  }
  .pb-sm-176 {
    padding-bottom: 11rem;
  }
  .ml-sm-176 {
    margin-left: 11rem;
  }
  .pl-sm-176 {
    padding-left: 11rem;
  }
  .mr-sm-176 {
    margin-right: 11rem;
  }
  .pr-sm-176 {
    padding-right: 11rem;
  }
  .mx-sm-176 {
    margin-left: 11rem;
    margin-right: 11rem;
  }
  .px-sm-176 {
    padding-left: 11rem;
    padding-right: 11rem;
  }
  .my-sm-176 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }
  .py-sm-176 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-184 {
    margin-top: 11.5rem;
  }
  .pt-sm-184 {
    padding-top: 11.5rem;
  }
  .mb-sm-184 {
    margin-bottom: 11.5rem;
  }
  .pb-sm-184 {
    padding-bottom: 11.5rem;
  }
  .ml-sm-184 {
    margin-left: 11.5rem;
  }
  .pl-sm-184 {
    padding-left: 11.5rem;
  }
  .mr-sm-184 {
    margin-right: 11.5rem;
  }
  .pr-sm-184 {
    padding-right: 11.5rem;
  }
  .mx-sm-184 {
    margin-left: 11.5rem;
    margin-right: 11.5rem;
  }
  .px-sm-184 {
    padding-left: 11.5rem;
    padding-right: 11.5rem;
  }
  .my-sm-184 {
    margin-top: 11.5rem;
    margin-bottom: 11.5rem;
  }
  .py-sm-184 {
    padding-top: 11.5rem;
    padding-bottom: 11.5rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-192 {
    margin-top: 12rem;
  }
  .pt-sm-192 {
    padding-top: 12rem;
  }
  .mb-sm-192 {
    margin-bottom: 12rem;
  }
  .pb-sm-192 {
    padding-bottom: 12rem;
  }
  .ml-sm-192 {
    margin-left: 12rem;
  }
  .pl-sm-192 {
    padding-left: 12rem;
  }
  .mr-sm-192 {
    margin-right: 12rem;
  }
  .pr-sm-192 {
    padding-right: 12rem;
  }
  .mx-sm-192 {
    margin-left: 12rem;
    margin-right: 12rem;
  }
  .px-sm-192 {
    padding-left: 12rem;
    padding-right: 12rem;
  }
  .my-sm-192 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }
  .py-sm-192 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
  .mt-sm-200 {
    margin-top: 12.5rem;
  }
  .pt-sm-200 {
    padding-top: 12.5rem;
  }
  .mb-sm-200 {
    margin-bottom: 12.5rem;
  }
  .pb-sm-200 {
    padding-bottom: 12.5rem;
  }
  .ml-sm-200 {
    margin-left: 12.5rem;
  }
  .pl-sm-200 {
    padding-left: 12.5rem;
  }
  .mr-sm-200 {
    margin-right: 12.5rem;
  }
  .pr-sm-200 {
    padding-right: 12.5rem;
  }
  .mx-sm-200 {
    margin-left: 12.5rem;
    margin-right: 12.5rem;
  }
  .px-sm-200 {
    padding-left: 12.5rem;
    padding-right: 12.5rem;
  }
  .my-sm-200 {
    margin-top: 12.5rem;
    margin-bottom: 12.5rem;
  }
  .py-sm-200 {
    padding-top: 12.5rem;
    padding-bottom: 12.5rem;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
 
  .flex-sm-inherit {
    flex-direction: inherit;
  }
  .flex-sm-unset {
    flex-direction: unset;
  }
}
@media (min-width: 768px) {
  .mt-md-m10 {
    margin-top: -10rem;
  }
  .pt-md-m10 {
    padding-top: -10rem;
  }
  .mb-md-m10 {
    margin-bottom: -10rem;
  }
  .pb-md-m10 {
    padding-bottom: -10rem;
  }
  .ml-md-m10 {
    margin-left: -10rem;
  }
  .pl-md-m10 {
    padding-left: -10rem;
  }
  .mr-md-m10 {
    margin-right: -10rem;
  }
  .pr-md-m10 {
    padding-right: -10rem;
  }
  .mx-md-m10 {
    margin-left: -10rem;
    margin-right: -10rem;
  }
  .px-md-m10 {
    padding-left: -10rem;
    padding-right: -10rem;
  }
  .my-md-m10 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }
  .py-md-m10 {
    padding-top: -10rem;
    padding-bottom: -10rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-m9 {
    margin-top: -9rem;
  }
  .pt-md-m9 {
    padding-top: -9rem;
  }
  .mb-md-m9 {
    margin-bottom: -9rem;
  }
  .pb-md-m9 {
    padding-bottom: -9rem;
  }
  .ml-md-m9 {
    margin-left: -9rem;
  }
  .pl-md-m9 {
    padding-left: -9rem;
  }
  .mr-md-m9 {
    margin-right: -9rem;
  }
  .pr-md-m9 {
    padding-right: -9rem;
  }
  .mx-md-m9 {
    margin-left: -9rem;
    margin-right: -9rem;
  }
  .px-md-m9 {
    padding-left: -9rem;
    padding-right: -9rem;
  }
  .my-md-m9 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }
  .py-md-m9 {
    padding-top: -9rem;
    padding-bottom: -9rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-m8 {
    margin-top: -8rem;
  }
  .pt-md-m8 {
    padding-top: -8rem;
  }
  .mb-md-m8 {
    margin-bottom: -8rem;
  }
  .pb-md-m8 {
    padding-bottom: -8rem;
  }
  .ml-md-m8 {
    margin-left: -8rem;
  }
  .pl-md-m8 {
    padding-left: -8rem;
  }
  .mr-md-m8 {
    margin-right: -8rem;
  }
  .pr-md-m8 {
    padding-right: -8rem;
  }
  .mx-md-m8 {
    margin-left: -8rem;
    margin-right: -8rem;
  }
  .px-md-m8 {
    padding-left: -8rem;
    padding-right: -8rem;
  }
  .my-md-m8 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }
  .py-md-m8 {
    padding-top: -8rem;
    padding-bottom: -8rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-m7 {
    margin-top: -7rem;
  }
  .pt-md-m7 {
    padding-top: -7rem;
  }
  .mb-md-m7 {
    margin-bottom: -7rem;
  }
  .pb-md-m7 {
    padding-bottom: -7rem;
  }
  .ml-md-m7 {
    margin-left: -7rem;
  }
  .pl-md-m7 {
    padding-left: -7rem;
  }
  .mr-md-m7 {
    margin-right: -7rem;
  }
  .pr-md-m7 {
    padding-right: -7rem;
  }
  .mx-md-m7 {
    margin-left: -7rem;
    margin-right: -7rem;
  }
  .px-md-m7 {
    padding-left: -7rem;
    padding-right: -7rem;
  }
  .my-md-m7 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }
  .py-md-m7 {
    padding-top: -7rem;
    padding-bottom: -7rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-m6 {
    margin-top: -6rem;
  }
  .pt-md-m6 {
    padding-top: -6rem;
  }
  .mb-md-m6 {
    margin-bottom: -6rem;
  }
  .pb-md-m6 {
    padding-bottom: -6rem;
  }
  .ml-md-m6 {
    margin-left: -6rem;
  }
  .pl-md-m6 {
    padding-left: -6rem;
  }
  .mr-md-m6 {
    margin-right: -6rem;
  }
  .pr-md-m6 {
    padding-right: -6rem;
  }
  .mx-md-m6 {
    margin-left: -6rem;
    margin-right: -6rem;
  }
  .px-md-m6 {
    padding-left: -6rem;
    padding-right: -6rem;
  }
  .my-md-m6 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }
  .py-md-m6 {
    padding-top: -6rem;
    padding-bottom: -6rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-m5 {
    margin-top: -5rem;
  }
  .pt-md-m5 {
    padding-top: -5rem;
  }
  .mb-md-m5 {
    margin-bottom: -5rem;
  }
  .pb-md-m5 {
    padding-bottom: -5rem;
  }
  .ml-md-m5 {
    margin-left: -5rem;
  }
  .pl-md-m5 {
    padding-left: -5rem;
  }
  .mr-md-m5 {
    margin-right: -5rem;
  }
  .pr-md-m5 {
    padding-right: -5rem;
  }
  .mx-md-m5 {
    margin-left: -5rem;
    margin-right: -5rem;
  }
  .px-md-m5 {
    padding-left: -5rem;
    padding-right: -5rem;
  }
  .my-md-m5 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .py-md-m5 {
    padding-top: -5rem;
    padding-bottom: -5rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-m4 {
    margin-top: -4rem;
  }
  .pt-md-m4 {
    padding-top: -4rem;
  }
  .mb-md-m4 {
    margin-bottom: -4rem;
  }
  .pb-md-m4 {
    padding-bottom: -4rem;
  }
  .ml-md-m4 {
    margin-left: -4rem;
  }
  .pl-md-m4 {
    padding-left: -4rem;
  }
  .mr-md-m4 {
    margin-right: -4rem;
  }
  .pr-md-m4 {
    padding-right: -4rem;
  }
  .mx-md-m4 {
    margin-left: -4rem;
    margin-right: -4rem;
  }
  .px-md-m4 {
    padding-left: -4rem;
    padding-right: -4rem;
  }
  .my-md-m4 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  .py-md-m4 {
    padding-top: -4rem;
    padding-bottom: -4rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-m3 {
    margin-top: -3rem;
  }
  .pt-md-m3 {
    padding-top: -3rem;
  }
  .mb-md-m3 {
    margin-bottom: -3rem;
  }
  .pb-md-m3 {
    padding-bottom: -3rem;
  }
  .ml-md-m3 {
    margin-left: -3rem;
  }
  .pl-md-m3 {
    padding-left: -3rem;
  }
  .mr-md-m3 {
    margin-right: -3rem;
  }
  .pr-md-m3 {
    padding-right: -3rem;
  }
  .mx-md-m3 {
    margin-left: -3rem;
    margin-right: -3rem;
  }
  .px-md-m3 {
    padding-left: -3rem;
    padding-right: -3rem;
  }
  .my-md-m3 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .py-md-m3 {
    padding-top: -3rem;
    padding-bottom: -3rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-m2 {
    margin-top: -2rem;
  }
  .pt-md-m2 {
    padding-top: -2rem;
  }
  .mb-md-m2 {
    margin-bottom: -2rem;
  }
  .pb-md-m2 {
    padding-bottom: -2rem;
  }
  .ml-md-m2 {
    margin-left: -2rem;
  }
  .pl-md-m2 {
    padding-left: -2rem;
  }
  .mr-md-m2 {
    margin-right: -2rem;
  }
  .pr-md-m2 {
    padding-right: -2rem;
  }
  .mx-md-m2 {
    margin-left: -2rem;
    margin-right: -2rem;
  }
  .px-md-m2 {
    padding-left: -2rem;
    padding-right: -2rem;
  }
  .my-md-m2 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .py-md-m2 {
    padding-top: -2rem;
    padding-bottom: -2rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-m1 {
    margin-top: -1rem;
  }
  .pt-md-m1 {
    padding-top: -1rem;
  }
  .mb-md-m1 {
    margin-bottom: -1rem;
  }
  .pb-md-m1 {
    padding-bottom: -1rem;
  }
  .ml-md-m1 {
    margin-left: -1rem;
  }
  .pl-md-m1 {
    padding-left: -1rem;
  }
  .mr-md-m1 {
    margin-right: -1rem;
  }
  .pr-md-m1 {
    padding-right: -1rem;
  }
  .mx-md-m1 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .px-md-m1 {
    padding-left: -1rem;
    padding-right: -1rem;
  }
  .my-md-m1 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .py-md-m1 {
    padding-top: -1rem;
    padding-bottom: -1rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-0 {
    margin-top: 0rem;
  }
  .pt-md-0 {
    padding-top: 0rem;
  }
  .mb-md-0 {
    margin-bottom: 0rem;
  }
  .pb-md-0 {
    padding-bottom: 0rem;
  }
  .ml-md-0 {
    margin-left: 0rem;
  }
  .pl-md-0 {
    padding-left: 0rem;
  }
  .mr-md-0 {
    margin-right: 0rem;
  }
  .pr-md-0 {
    padding-right: 0rem;
  }
  .mx-md-0 {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .px-md-0 {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .my-md-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .py-md-0 {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-1 {
    margin-top: 0.063rem;
  }
  .pt-md-1 {
    padding-top: 0.063rem;
  }
  .mb-md-1 {
    margin-bottom: 0.063rem;
  }
  .pb-md-1 {
    padding-bottom: 0.063rem;
  }
  .ml-md-1 {
    margin-left: 0.063rem;
  }
  .pl-md-1 {
    padding-left: 0.063rem;
  }
  .mr-md-1 {
    margin-right: 0.063rem;
  }
  .pr-md-1 {
    padding-right: 0.063rem;
  }
  .mx-md-1 {
    margin-left: 0.063rem;
    margin-right: 0.063rem;
  }
  .px-md-1 {
    padding-left: 0.063rem;
    padding-right: 0.063rem;
  }
  .my-md-1 {
    margin-top: 0.063rem;
    margin-bottom: 0.063rem;
  }
  .py-md-1 {
    padding-top: 0.063rem;
    padding-bottom: 0.063rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-2 {
    margin-top: 0.125rem;
  }
  .pt-md-2 {
    padding-top: 0.125rem;
  }
  .mb-md-2 {
    margin-bottom: 0.125rem;
  }
  .pb-md-2 {
    padding-bottom: 0.125rem;
  }
  .ml-md-2 {
    margin-left: 0.125rem;
  }
  .pl-md-2 {
    padding-left: 0.125rem;
  }
  .mr-md-2 {
    margin-right: 0.125rem;
  }
  .pr-md-2 {
    padding-right: 0.125rem;
  }
  .mx-md-2 {
    margin-left: 0.125rem;
    margin-right: 0.125rem;
  }
  .px-md-2 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }
  .my-md-2 {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }
  .py-md-2 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-4 {
    margin-top: 0.25rem;
  }
  .pt-md-4 {
    padding-top: 0.25rem;
  }
  .mb-md-4 {
    margin-bottom: 0.25rem;
  }
  .pb-md-4 {
    padding-bottom: 0.25rem;
  }
  .ml-md-4 {
    margin-left: 0.25rem;
  }
  .pl-md-4 {
    padding-left: 0.25rem;
  }
  .mr-md-4 {
    margin-right: 0.25rem;
  }
  .pr-md-4 {
    padding-right: 0.25rem;
  }
  .mx-md-4 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .px-md-4 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .my-md-4 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .py-md-4 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-8 {
    margin-top: 0.5rem;
  }
  .pt-md-8 {
    padding-top: 0.5rem;
  }
  .mb-md-8 {
    margin-bottom: 0.5rem;
  }
  .pb-md-8 {
    padding-bottom: 0.5rem;
  }
  .ml-md-8 {
    margin-left: 0.5rem;
  }
  .pl-md-8 {
    padding-left: 0.5rem;
  }
  .mr-md-8 {
    margin-right: 0.5rem;
  }
  .pr-md-8 {
    padding-right: 0.5rem;
  }
  .mx-md-8 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .px-md-8 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .my-md-8 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .py-md-8 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-10 {
    margin-top: 0.625rem;
  }
  .pt-md-10 {
    padding-top: 0.625rem;
  }
  .mb-md-10 {
    margin-bottom: 0.625rem;
  }
  .pb-md-10 {
    padding-bottom: 0.625rem;
  }
  .ml-md-10 {
    margin-left: 0.625rem;
  }
  .pl-md-10 {
    padding-left: 0.625rem;
  }
  .mr-md-10 {
    margin-right: 0.625rem;
  }
  .pr-md-10 {
    padding-right: 0.625rem;
  }
  .mx-md-10 {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
  .px-md-10 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .my-md-10 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .py-md-10 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-12 {
    margin-top: 0.75rem;
  }
  .pt-md-12 {
    padding-top: 0.75rem;
  }
  .mb-md-12 {
    margin-bottom: 0.75rem;
  }
  .pb-md-12 {
    padding-bottom: 0.75rem;
  }
  .ml-md-12 {
    margin-left: 0.75rem;
  }
  .pl-md-12 {
    padding-left: 0.75rem;
  }
  .mr-md-12 {
    margin-right: 0.75rem;
  }
  .pr-md-12 {
    padding-right: 0.75rem;
  }
  .mx-md-12 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
  .px-md-12 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .my-md-12 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .py-md-12 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-14 {
    margin-top: 0.875rem;
  }
  .pt-md-14 {
    padding-top: 0.875rem;
  }
  .mb-md-14 {
    margin-bottom: 0.875rem;
  }
  .pb-md-14 {
    padding-bottom: 0.875rem;
  }
  .ml-md-14 {
    margin-left: 0.875rem;
  }
  .pl-md-14 {
    padding-left: 0.875rem;
  }
  .mr-md-14 {
    margin-right: 0.875rem;
  }
  .pr-md-14 {
    padding-right: 0.875rem;
  }
  .mx-md-14 {
    margin-left: 0.875rem;
    margin-right: 0.875rem;
  }
  .px-md-14 {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
  }
  .my-md-14 {
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
  }
  .py-md-14 {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-16 {
    margin-top: 1rem;
  }
  .pt-md-16 {
    padding-top: 1rem;
  }
  .mb-md-16 {
    margin-bottom: 1rem;
  }
  .pb-md-16 {
    padding-bottom: 1rem;
  }
  .ml-md-16 {
    margin-left: 1rem;
  }
  .pl-md-16 {
    padding-left: 1rem;
  }
  .mr-md-16 {
    margin-right: 1rem;
  }
  .pr-md-16 {
    padding-right: 1rem;
  }
  .mx-md-16 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .px-md-16 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .my-md-16 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .py-md-16 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-18 {
    margin-top: 1.125rem;
  }
  .pt-md-18 {
    padding-top: 1.125rem;
  }
  .mb-md-18 {
    margin-bottom: 1.125rem;
  }
  .pb-md-18 {
    padding-bottom: 1.125rem;
  }
  .ml-md-18 {
    margin-left: 1.125rem;
  }
  .pl-md-18 {
    padding-left: 1.125rem;
  }
  .mr-md-18 {
    margin-right: 1.125rem;
  }
  .pr-md-18 {
    padding-right: 1.125rem;
  }
  .mx-md-18 {
    margin-left: 1.125rem;
    margin-right: 1.125rem;
  }
  .px-md-18 {
    padding-left: 1.125rem;
    padding-right: 1.125rem;
  }
  .my-md-18 {
    margin-top: 1.125rem;
    margin-bottom: 1.125rem;
  }
  .py-md-18 {
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-20 {
    margin-top: 1.25rem;
  }
  .pt-md-20 {
    padding-top: 1.25rem;
  }
  .mb-md-20 {
    margin-bottom: 1.25rem;
  }
  .pb-md-20 {
    padding-bottom: 1.25rem;
  }
  .ml-md-20 {
    margin-left: 1.25rem;
  }
  .pl-md-20 {
    padding-left: 1.25rem;
  }
  .mr-md-20 {
    margin-right: 1.25rem;
  }
  .pr-md-20 {
    padding-right: 1.25rem;
  }
  .mx-md-20 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .px-md-20 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .my-md-20 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .py-md-20 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-24 {
    margin-top: 1.5rem;
  }
  .pt-md-24 {
    padding-top: 1.5rem;
  }
  .mb-md-24 {
    margin-bottom: 1.5rem;
  }
  .pb-md-24 {
    padding-bottom: 1.5rem;
  }
  .ml-md-24 {
    margin-left: 1.5rem;
  }
  .pl-md-24 {
    padding-left: 1.5rem;
  }
  .mr-md-24 {
    margin-right: 1.5rem;
  }
  .pr-md-24 {
    padding-right: 1.5rem;
  }
  .mx-md-24 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .px-md-24 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .my-md-24 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .py-md-24 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-28 {
    margin-top: 1.75rem;
  }
  .pt-md-28 {
    padding-top: 1.75rem;
  }
  .mb-md-28 {
    margin-bottom: 1.75rem;
  }
  .pb-md-28 {
    padding-bottom: 1.75rem;
  }
  .ml-md-28 {
    margin-left: 1.75rem;
  }
  .pl-md-28 {
    padding-left: 1.75rem;
  }
  .mr-md-28 {
    margin-right: 1.75rem;
  }
  .pr-md-28 {
    padding-right: 1.75rem;
  }
  .mx-md-28 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }
  .px-md-28 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .my-md-28 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  .py-md-28 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-32 {
    margin-top: 2rem;
  }
  .pt-md-32 {
    padding-top: 2rem;
  }
  .mb-md-32 {
    margin-bottom: 2rem;
  }
  .pb-md-32 {
    padding-bottom: 2rem;
  }
  .ml-md-32 {
    margin-left: 2rem;
  }
  .pl-md-32 {
    padding-left: 2rem;
  }
  .mr-md-32 {
    margin-right: 2rem;
  }
  .pr-md-32 {
    padding-right: 2rem;
  }
  .mx-md-32 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .px-md-32 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .my-md-32 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .py-md-32 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-36 {
    margin-top: 2.25rem;
  }
  .pt-md-36 {
    padding-top: 2.25rem;
  }
  .mb-md-36 {
    margin-bottom: 2.25rem;
  }
  .pb-md-36 {
    padding-bottom: 2.25rem;
  }
  .ml-md-36 {
    margin-left: 2.25rem;
  }
  .pl-md-36 {
    padding-left: 2.25rem;
  }
  .mr-md-36 {
    margin-right: 2.25rem;
  }
  .pr-md-36 {
    padding-right: 2.25rem;
  }
  .mx-md-36 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }
  .px-md-36 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  .my-md-36 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }
  .py-md-36 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-40 {
    margin-top: 2.5rem;
  }
  .pt-md-40 {
    padding-top: 2.5rem;
  }
  .mb-md-40 {
    margin-bottom: 2.5rem;
  }
  .pb-md-40 {
    padding-bottom: 2.5rem;
  }
  .ml-md-40 {
    margin-left: 2.5rem;
  }
  .pl-md-40 {
    padding-left: 2.5rem;
  }
  .mr-md-40 {
    margin-right: 2.5rem;
  }
  .pr-md-40 {
    padding-right: 2.5rem;
  }
  .mx-md-40 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .px-md-40 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .my-md-40 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .py-md-40 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-48 {
    margin-top: 3rem;
  }
  .pt-md-48 {
    padding-top: 3rem;
  }
  .mb-md-48 {
    margin-bottom: 3rem;
  }
  .pb-md-48 {
    padding-bottom: 3rem;
  }
  .ml-md-48 {
    margin-left: 3rem;
  }
  .pl-md-48 {
    padding-left: 3rem;
  }
  .mr-md-48 {
    margin-right: 3rem;
  }
  .pr-md-48 {
    padding-right: 3rem;
  }
  .mx-md-48 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .px-md-48 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .my-md-48 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .py-md-48 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-56 {
    margin-top: 3.5rem;
  }
  .pt-md-56 {
    padding-top: 3.5rem;
  }
  .mb-md-56 {
    margin-bottom: 3.5rem;
  }
  .pb-md-56 {
    padding-bottom: 3.5rem;
  }
  .ml-md-56 {
    margin-left: 3.5rem;
  }
  .pl-md-56 {
    padding-left: 3.5rem;
  }
  .mr-md-56 {
    margin-right: 3.5rem;
  }
  .pr-md-56 {
    padding-right: 3.5rem;
  }
  .mx-md-56 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }
  .px-md-56 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .my-md-56 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .py-md-56 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-64 {
    margin-top: 4rem;
  }
  .pt-md-64 {
    padding-top: 4rem;
  }
  .mb-md-64 {
    margin-bottom: 4rem;
  }
  .pb-md-64 {
    padding-bottom: 4rem;
  }
  .ml-md-64 {
    margin-left: 4rem;
  }
  .pl-md-64 {
    padding-left: 4rem;
  }
  .mr-md-64 {
    margin-right: 4rem;
  }
  .pr-md-64 {
    padding-right: 4rem;
  }
  .mx-md-64 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .px-md-64 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .my-md-64 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .py-md-64 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-72 {
    margin-top: 4.5rem;
  }
  .pt-md-72 {
    padding-top: 4.5rem;
  }
  .mb-md-72 {
    margin-bottom: 4.5rem;
  }
  .pb-md-72 {
    padding-bottom: 4.5rem;
  }
  .ml-md-72 {
    margin-left: 4.5rem;
  }
  .pl-md-72 {
    padding-left: 4.5rem;
  }
  .mr-md-72 {
    margin-right: 4.5rem;
  }
  .pr-md-72 {
    padding-right: 4.5rem;
  }
  .mx-md-72 {
    margin-left: 4.5rem;
    margin-right: 4.5rem;
  }
  .px-md-72 {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  .my-md-72 {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }
  .py-md-72 {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-100 {
    margin-top: 6.25rem;
  }
  .pt-md-100 {
    padding-top: 6.25rem;
  }
  .mb-md-100 {
    margin-bottom: 6.25rem;
  }
  .pb-md-100 {
    padding-bottom: 6.25rem;
  }
  .ml-md-100 {
    margin-left: 6.25rem;
  }
  .pl-md-100 {
    padding-left: 6.25rem;
  }
  .mr-md-100 {
    margin-right: 6.25rem;
  }
  .pr-md-100 {
    padding-right: 6.25rem;
  }
  .mx-md-100 {
    margin-left: 6.25rem;
    margin-right: 6.25rem;
  }
  .px-md-100 {
    padding-left: 6.25rem;
    padding-right: 6.25rem;
  }
  .my-md-100 {
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;
  }
  .py-md-100 {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-112 {
    margin-top: 7rem;
  }
  .pt-md-112 {
    padding-top: 7rem;
  }
  .mb-md-112 {
    margin-bottom: 7rem;
  }
  .pb-md-112 {
    padding-bottom: 7rem;
  }
  .ml-md-112 {
    margin-left: 7rem;
  }
  .pl-md-112 {
    padding-left: 7rem;
  }
  .mr-md-112 {
    margin-right: 7rem;
  }
  .pr-md-112 {
    padding-right: 7rem;
  }
  .mx-md-112 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .px-md-112 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .my-md-112 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .py-md-112 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-120 {
    margin-top: 7.5rem;
  }
  .pt-md-120 {
    padding-top: 7.5rem;
  }
  .mb-md-120 {
    margin-bottom: 7.5rem;
  }
  .pb-md-120 {
    padding-bottom: 7.5rem;
  }
  .ml-md-120 {
    margin-left: 7.5rem;
  }
  .pl-md-120 {
    padding-left: 7.5rem;
  }
  .mr-md-120 {
    margin-right: 7.5rem;
  }
  .pr-md-120 {
    padding-right: 7.5rem;
  }
  .mx-md-120 {
    margin-left: 7.5rem;
    margin-right: 7.5rem;
  }
  .px-md-120 {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }
  .my-md-120 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }
  .py-md-120 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-124 {
    margin-top: 8rem;
  }
  .pt-md-124 {
    padding-top: 8rem;
  }
  .mb-md-124 {
    margin-bottom: 8rem;
  }
  .pb-md-124 {
    padding-bottom: 8rem;
  }
  .ml-md-124 {
    margin-left: 8rem;
  }
  .pl-md-124 {
    padding-left: 8rem;
  }
  .mr-md-124 {
    margin-right: 8rem;
  }
  .pr-md-124 {
    padding-right: 8rem;
  }
  .mx-md-124 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .px-md-124 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .my-md-124 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .py-md-124 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-136 {
    margin-top: 8.5rem;
  }
  .pt-md-136 {
    padding-top: 8.5rem;
  }
  .mb-md-136 {
    margin-bottom: 8.5rem;
  }
  .pb-md-136 {
    padding-bottom: 8.5rem;
  }
  .ml-md-136 {
    margin-left: 8.5rem;
  }
  .pl-md-136 {
    padding-left: 8.5rem;
  }
  .mr-md-136 {
    margin-right: 8.5rem;
  }
  .pr-md-136 {
    padding-right: 8.5rem;
  }
  .mx-md-136 {
    margin-left: 8.5rem;
    margin-right: 8.5rem;
  }
  .px-md-136 {
    padding-left: 8.5rem;
    padding-right: 8.5rem;
  }
  .my-md-136 {
    margin-top: 8.5rem;
    margin-bottom: 8.5rem;
  }
  .py-md-136 {
    padding-top: 8.5rem;
    padding-bottom: 8.5rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-144 {
    margin-top: 9rem;
  }
  .pt-md-144 {
    padding-top: 9rem;
  }
  .mb-md-144 {
    margin-bottom: 9rem;
  }
  .pb-md-144 {
    padding-bottom: 9rem;
  }
  .ml-md-144 {
    margin-left: 9rem;
  }
  .pl-md-144 {
    padding-left: 9rem;
  }
  .mr-md-144 {
    margin-right: 9rem;
  }
  .pr-md-144 {
    padding-right: 9rem;
  }
  .mx-md-144 {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .px-md-144 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .my-md-144 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .py-md-144 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-152 {
    margin-top: 9.5rem;
  }
  .pt-md-152 {
    padding-top: 9.5rem;
  }
  .mb-md-152 {
    margin-bottom: 9.5rem;
  }
  .pb-md-152 {
    padding-bottom: 9.5rem;
  }
  .ml-md-152 {
    margin-left: 9.5rem;
  }
  .pl-md-152 {
    padding-left: 9.5rem;
  }
  .mr-md-152 {
    margin-right: 9.5rem;
  }
  .pr-md-152 {
    padding-right: 9.5rem;
  }
  .mx-md-152 {
    margin-left: 9.5rem;
    margin-right: 9.5rem;
  }
  .px-md-152 {
    padding-left: 9.5rem;
    padding-right: 9.5rem;
  }
  .my-md-152 {
    margin-top: 9.5rem;
    margin-bottom: 9.5rem;
  }
  .py-md-152 {
    padding-top: 9.5rem;
    padding-bottom: 9.5rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-160 {
    margin-top: 10rem;
  }
  .pt-md-160 {
    padding-top: 10rem;
  }
  .mb-md-160 {
    margin-bottom: 10rem;
  }
  .pb-md-160 {
    padding-bottom: 10rem;
  }
  .ml-md-160 {
    margin-left: 10rem;
  }
  .pl-md-160 {
    padding-left: 10rem;
  }
  .mr-md-160 {
    margin-right: 10rem;
  }
  .pr-md-160 {
    padding-right: 10rem;
  }
  .mx-md-160 {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .px-md-160 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .my-md-160 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .py-md-160 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-168 {
    margin-top: 10.5rem;
  }
  .pt-md-168 {
    padding-top: 10.5rem;
  }
  .mb-md-168 {
    margin-bottom: 10.5rem;
  }
  .pb-md-168 {
    padding-bottom: 10.5rem;
  }
  .ml-md-168 {
    margin-left: 10.5rem;
  }
  .pl-md-168 {
    padding-left: 10.5rem;
  }
  .mr-md-168 {
    margin-right: 10.5rem;
  }
  .pr-md-168 {
    padding-right: 10.5rem;
  }
  .mx-md-168 {
    margin-left: 10.5rem;
    margin-right: 10.5rem;
  }
  .px-md-168 {
    padding-left: 10.5rem;
    padding-right: 10.5rem;
  }
  .my-md-168 {
    margin-top: 10.5rem;
    margin-bottom: 10.5rem;
  }
  .py-md-168 {
    padding-top: 10.5rem;
    padding-bottom: 10.5rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-176 {
    margin-top: 11rem;
  }
  .pt-md-176 {
    padding-top: 11rem;
  }
  .mb-md-176 {
    margin-bottom: 11rem;
  }
  .pb-md-176 {
    padding-bottom: 11rem;
  }
  .ml-md-176 {
    margin-left: 11rem;
  }
  .pl-md-176 {
    padding-left: 11rem;
  }
  .mr-md-176 {
    margin-right: 11rem;
  }
  .pr-md-176 {
    padding-right: 11rem;
  }
  .mx-md-176 {
    margin-left: 11rem;
    margin-right: 11rem;
  }
  .px-md-176 {
    padding-left: 11rem;
    padding-right: 11rem;
  }
  .my-md-176 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }
  .py-md-176 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-184 {
    margin-top: 11.5rem;
  }
  .pt-md-184 {
    padding-top: 11.5rem;
  }
  .mb-md-184 {
    margin-bottom: 11.5rem;
  }
  .pb-md-184 {
    padding-bottom: 11.5rem;
  }
  .ml-md-184 {
    margin-left: 11.5rem;
  }
  .pl-md-184 {
    padding-left: 11.5rem;
  }
  .mr-md-184 {
    margin-right: 11.5rem;
  }
  .pr-md-184 {
    padding-right: 11.5rem;
  }
  .mx-md-184 {
    margin-left: 11.5rem;
    margin-right: 11.5rem;
  }
  .px-md-184 {
    padding-left: 11.5rem;
    padding-right: 11.5rem;
  }
  .my-md-184 {
    margin-top: 11.5rem;
    margin-bottom: 11.5rem;
  }
  .py-md-184 {
    padding-top: 11.5rem;
    padding-bottom: 11.5rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-192 {
    margin-top: 12rem;
  }
  .pt-md-192 {
    padding-top: 12rem;
  }
  .mb-md-192 {
    margin-bottom: 12rem;
  }
  .pb-md-192 {
    padding-bottom: 12rem;
  }
  .ml-md-192 {
    margin-left: 12rem;
  }
  .pl-md-192 {
    padding-left: 12rem;
  }
  .mr-md-192 {
    margin-right: 12rem;
  }
  .pr-md-192 {
    padding-right: 12rem;
  }
  .mx-md-192 {
    margin-left: 12rem;
    margin-right: 12rem;
  }
  .px-md-192 {
    padding-left: 12rem;
    padding-right: 12rem;
  }
  .my-md-192 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }
  .py-md-192 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
  .mt-md-200 {
    margin-top: 12.5rem;
  }
  .pt-md-200 {
    padding-top: 12.5rem;
  }
  .mb-md-200 {
    margin-bottom: 12.5rem;
  }
  .pb-md-200 {
    padding-bottom: 12.5rem;
  }
  .ml-md-200 {
    margin-left: 12.5rem;
  }
  .pl-md-200 {
    padding-left: 12.5rem;
  }
  .mr-md-200 {
    margin-right: 12.5rem;
  }
  .pr-md-200 {
    padding-right: 12.5rem;
  }
  .mx-md-200 {
    margin-left: 12.5rem;
    margin-right: 12.5rem;
  }
  .px-md-200 {
    padding-left: 12.5rem;
    padding-right: 12.5rem;
  }
  .my-md-200 {
    margin-top: 12.5rem;
    margin-bottom: 12.5rem;
  }
  .py-md-200 {
    padding-top: 12.5rem;
    padding-bottom: 12.5rem;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-inherit {
    flex-direction: inherit;
  }
  .flex-md-unset {
    flex-direction: unset;
  }
}
@media (min-width: 992px) {
  .mt-lg-m10 {
    margin-top: -10rem;
  }
  .pt-lg-m10 {
    padding-top: -10rem;
  }
  .mb-lg-m10 {
    margin-bottom: -10rem;
  }
  .pb-lg-m10 {
    padding-bottom: -10rem;
  }
  .ml-lg-m10 {
    margin-left: -10rem;
  }
  .pl-lg-m10 {
    padding-left: -10rem;
  }
  .mr-lg-m10 {
    margin-right: -10rem;
  }
  .pr-lg-m10 {
    padding-right: -10rem;
  }
  .mx-lg-m10 {
    margin-left: -10rem;
    margin-right: -10rem;
  }
  .px-lg-m10 {
    padding-left: -10rem;
    padding-right: -10rem;
  }
  .my-lg-m10 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }
  .py-lg-m10 {
    padding-top: -10rem;
    padding-bottom: -10rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-m9 {
    margin-top: -9rem;
  }
  .pt-lg-m9 {
    padding-top: -9rem;
  }
  .mb-lg-m9 {
    margin-bottom: -9rem;
  }
  .pb-lg-m9 {
    padding-bottom: -9rem;
  }
  .ml-lg-m9 {
    margin-left: -9rem;
  }
  .pl-lg-m9 {
    padding-left: -9rem;
  }
  .mr-lg-m9 {
    margin-right: -9rem;
  }
  .pr-lg-m9 {
    padding-right: -9rem;
  }
  .mx-lg-m9 {
    margin-left: -9rem;
    margin-right: -9rem;
  }
  .px-lg-m9 {
    padding-left: -9rem;
    padding-right: -9rem;
  }
  .my-lg-m9 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }
  .py-lg-m9 {
    padding-top: -9rem;
    padding-bottom: -9rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-m8 {
    margin-top: -8rem;
  }
  .pt-lg-m8 {
    padding-top: -8rem;
  }
  .mb-lg-m8 {
    margin-bottom: -8rem;
  }
  .pb-lg-m8 {
    padding-bottom: -8rem;
  }
  .ml-lg-m8 {
    margin-left: -8rem;
  }
  .pl-lg-m8 {
    padding-left: -8rem;
  }
  .mr-lg-m8 {
    margin-right: -8rem;
  }
  .pr-lg-m8 {
    padding-right: -8rem;
  }
  .mx-lg-m8 {
    margin-left: -8rem;
    margin-right: -8rem;
  }
  .px-lg-m8 {
    padding-left: -8rem;
    padding-right: -8rem;
  }
  .my-lg-m8 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }
  .py-lg-m8 {
    padding-top: -8rem;
    padding-bottom: -8rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-m7 {
    margin-top: -7rem;
  }
  .pt-lg-m7 {
    padding-top: -7rem;
  }
  .mb-lg-m7 {
    margin-bottom: -7rem;
  }
  .pb-lg-m7 {
    padding-bottom: -7rem;
  }
  .ml-lg-m7 {
    margin-left: -7rem;
  }
  .pl-lg-m7 {
    padding-left: -7rem;
  }
  .mr-lg-m7 {
    margin-right: -7rem;
  }
  .pr-lg-m7 {
    padding-right: -7rem;
  }
  .mx-lg-m7 {
    margin-left: -7rem;
    margin-right: -7rem;
  }
  .px-lg-m7 {
    padding-left: -7rem;
    padding-right: -7rem;
  }
  .my-lg-m7 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }
  .py-lg-m7 {
    padding-top: -7rem;
    padding-bottom: -7rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-m6 {
    margin-top: -6rem;
  }
  .pt-lg-m6 {
    padding-top: -6rem;
  }
  .mb-lg-m6 {
    margin-bottom: -6rem;
  }
  .pb-lg-m6 {
    padding-bottom: -6rem;
  }
  .ml-lg-m6 {
    margin-left: -6rem;
  }
  .pl-lg-m6 {
    padding-left: -6rem;
  }
  .mr-lg-m6 {
    margin-right: -6rem;
  }
  .pr-lg-m6 {
    padding-right: -6rem;
  }
  .mx-lg-m6 {
    margin-left: -6rem;
    margin-right: -6rem;
  }
  .px-lg-m6 {
    padding-left: -6rem;
    padding-right: -6rem;
  }
  .my-lg-m6 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }
  .py-lg-m6 {
    padding-top: -6rem;
    padding-bottom: -6rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-m5 {
    margin-top: -5rem;
  }
  .pt-lg-m5 {
    padding-top: -5rem;
  }
  .mb-lg-m5 {
    margin-bottom: -5rem;
  }
  .pb-lg-m5 {
    padding-bottom: -5rem;
  }
  .ml-lg-m5 {
    margin-left: -5rem;
  }
  .pl-lg-m5 {
    padding-left: -5rem;
  }
  .mr-lg-m5 {
    margin-right: -5rem;
  }
  .pr-lg-m5 {
    padding-right: -5rem;
  }
  .mx-lg-m5 {
    margin-left: -5rem;
    margin-right: -5rem;
  }
  .px-lg-m5 {
    padding-left: -5rem;
    padding-right: -5rem;
  }
  .my-lg-m5 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .py-lg-m5 {
    padding-top: -5rem;
    padding-bottom: -5rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-m4 {
    margin-top: -4rem;
  }
  .pt-lg-m4 {
    padding-top: -4rem;
  }
  .mb-lg-m4 {
    margin-bottom: -4rem;
  }
  .pb-lg-m4 {
    padding-bottom: -4rem;
  }
  .ml-lg-m4 {
    margin-left: -4rem;
  }
  .pl-lg-m4 {
    padding-left: -4rem;
  }
  .mr-lg-m4 {
    margin-right: -4rem;
  }
  .pr-lg-m4 {
    padding-right: -4rem;
  }
  .mx-lg-m4 {
    margin-left: -4rem;
    margin-right: -4rem;
  }
  .px-lg-m4 {
    padding-left: -4rem;
    padding-right: -4rem;
  }
  .my-lg-m4 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  .py-lg-m4 {
    padding-top: -4rem;
    padding-bottom: -4rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-m3 {
    margin-top: -3rem;
  }
  .pt-lg-m3 {
    padding-top: -3rem;
  }
  .mb-lg-m3 {
    margin-bottom: -3rem;
  }
  .pb-lg-m3 {
    padding-bottom: -3rem;
  }
  .ml-lg-m3 {
    margin-left: -3rem;
  }
  .pl-lg-m3 {
    padding-left: -3rem;
  }
  .mr-lg-m3 {
    margin-right: -3rem;
  }
  .pr-lg-m3 {
    padding-right: -3rem;
  }
  .mx-lg-m3 {
    margin-left: -3rem;
    margin-right: -3rem;
  }
  .px-lg-m3 {
    padding-left: -3rem;
    padding-right: -3rem;
  }
  .my-lg-m3 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .py-lg-m3 {
    padding-top: -3rem;
    padding-bottom: -3rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-m2 {
    margin-top: -2rem;
  }
  .pt-lg-m2 {
    padding-top: -2rem;
  }
  .mb-lg-m2 {
    margin-bottom: -2rem;
  }
  .pb-lg-m2 {
    padding-bottom: -2rem;
  }
  .ml-lg-m2 {
    margin-left: -2rem;
  }
  .pl-lg-m2 {
    padding-left: -2rem;
  }
  .mr-lg-m2 {
    margin-right: -2rem;
  }
  .pr-lg-m2 {
    padding-right: -2rem;
  }
  .mx-lg-m2 {
    margin-left: -2rem;
    margin-right: -2rem;
  }
  .px-lg-m2 {
    padding-left: -2rem;
    padding-right: -2rem;
  }
  .my-lg-m2 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .py-lg-m2 {
    padding-top: -2rem;
    padding-bottom: -2rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-m1 {
    margin-top: -1rem;
  }
  .pt-lg-m1 {
    padding-top: -1rem;
  }
  .mb-lg-m1 {
    margin-bottom: -1rem;
  }
  .pb-lg-m1 {
    padding-bottom: -1rem;
  }
  .ml-lg-m1 {
    margin-left: -1rem;
  }
  .pl-lg-m1 {
    padding-left: -1rem;
  }
  .mr-lg-m1 {
    margin-right: -1rem;
  }
  .pr-lg-m1 {
    padding-right: -1rem;
  }
  .mx-lg-m1 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .px-lg-m1 {
    padding-left: -1rem;
    padding-right: -1rem;
  }
  .my-lg-m1 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .py-lg-m1 {
    padding-top: -1rem;
    padding-bottom: -1rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-0 {
    margin-top: 0rem;
  }
  .pt-lg-0 {
    padding-top: 0rem;
  }
  .mb-lg-0 {
    margin-bottom: 0rem;
  }
  .pb-lg-0 {
    padding-bottom: 0rem;
  }
  .ml-lg-0 {
    margin-left: 0rem;
  }
  .pl-lg-0 {
    padding-left: 0rem;
  }
  .mr-lg-0 {
    margin-right: 0rem;
  }
  .pr-lg-0 {
    padding-right: 0rem;
  }
  .mx-lg-0 {
    margin-left: 0rem;
    margin-right: 0rem;
  }
  .px-lg-0 {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .my-lg-0 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .py-lg-0 {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-1 {
    margin-top: 0.063rem;
  }
  .pt-lg-1 {
    padding-top: 0.063rem;
  }
  .mb-lg-1 {
    margin-bottom: 0.063rem;
  }
  .pb-lg-1 {
    padding-bottom: 0.063rem;
  }
  .ml-lg-1 {
    margin-left: 0.063rem;
  }
  .pl-lg-1 {
    padding-left: 0.063rem;
  }
  .mr-lg-1 {
    margin-right: 0.063rem;
  }
  .pr-lg-1 {
    padding-right: 0.063rem;
  }
  .mx-lg-1 {
    margin-left: 0.063rem;
    margin-right: 0.063rem;
  }
  .px-lg-1 {
    padding-left: 0.063rem;
    padding-right: 0.063rem;
  }
  .my-lg-1 {
    margin-top: 0.063rem;
    margin-bottom: 0.063rem;
  }
  .py-lg-1 {
    padding-top: 0.063rem;
    padding-bottom: 0.063rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-2 {
    margin-top: 0.125rem;
  }
  .pt-lg-2 {
    padding-top: 0.125rem;
  }
  .mb-lg-2 {
    margin-bottom: 0.125rem;
  }
  .pb-lg-2 {
    padding-bottom: 0.125rem;
  }
  .ml-lg-2 {
    margin-left: 0.125rem;
  }
  .pl-lg-2 {
    padding-left: 0.125rem;
  }
  .mr-lg-2 {
    margin-right: 0.125rem;
  }
  .pr-lg-2 {
    padding-right: 0.125rem;
  }
  .mx-lg-2 {
    margin-left: 0.125rem;
    margin-right: 0.125rem;
  }
  .px-lg-2 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }
  .my-lg-2 {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }
  .py-lg-2 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-4 {
    margin-top: 0.25rem;
  }
  .pt-lg-4 {
    padding-top: 0.25rem;
  }
  .mb-lg-4 {
    margin-bottom: 0.25rem;
  }
  .pb-lg-4 {
    padding-bottom: 0.25rem;
  }
  .ml-lg-4 {
    margin-left: 0.25rem;
  }
  .pl-lg-4 {
    padding-left: 0.25rem;
  }
  .mr-lg-4 {
    margin-right: 0.25rem;
  }
  .pr-lg-4 {
    padding-right: 0.25rem;
  }
  .mx-lg-4 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .px-lg-4 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .my-lg-4 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .py-lg-4 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-8 {
    margin-top: 0.5rem;
  }
  .pt-lg-8 {
    padding-top: 0.5rem;
  }
  .mb-lg-8 {
    margin-bottom: 0.5rem;
  }
  .pb-lg-8 {
    padding-bottom: 0.5rem;
  }
  .ml-lg-8 {
    margin-left: 0.5rem;
  }
  .pl-lg-8 {
    padding-left: 0.5rem;
  }
  .mr-lg-8 {
    margin-right: 0.5rem;
  }
  .pr-lg-8 {
    padding-right: 0.5rem;
  }
  .mx-lg-8 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .px-lg-8 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .my-lg-8 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .py-lg-8 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-10 {
    margin-top: 0.625rem;
  }
  .pt-lg-10 {
    padding-top: 0.625rem;
  }
  .mb-lg-10 {
    margin-bottom: 0.625rem;
  }
  .pb-lg-10 {
    padding-bottom: 0.625rem;
  }
  .ml-lg-10 {
    margin-left: 0.625rem;
  }
  .pl-lg-10 {
    padding-left: 0.625rem;
  }
  .mr-lg-10 {
    margin-right: 0.625rem;
  }
  .pr-lg-10 {
    padding-right: 0.625rem;
  }
  .mx-lg-10 {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
  .px-lg-10 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .my-lg-10 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .py-lg-10 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-12 {
    margin-top: 0.75rem;
  }
  .pt-lg-12 {
    padding-top: 0.75rem;
  }
  .mb-lg-12 {
    margin-bottom: 0.75rem;
  }
  .pb-lg-12 {
    padding-bottom: 0.75rem;
  }
  .ml-lg-12 {
    margin-left: 0.75rem;
  }
  .pl-lg-12 {
    padding-left: 0.75rem;
  }
  .mr-lg-12 {
    margin-right: 0.75rem;
  }
  .pr-lg-12 {
    padding-right: 0.75rem;
  }
  .mx-lg-12 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
  .px-lg-12 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .my-lg-12 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .py-lg-12 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-14 {
    margin-top: 0.875rem;
  }
  .pt-lg-14 {
    padding-top: 0.875rem;
  }
  .mb-lg-14 {
    margin-bottom: 0.875rem;
  }
  .pb-lg-14 {
    padding-bottom: 0.875rem;
  }
  .ml-lg-14 {
    margin-left: 0.875rem;
  }
  .pl-lg-14 {
    padding-left: 0.875rem;
  }
  .mr-lg-14 {
    margin-right: 0.875rem;
  }
  .pr-lg-14 {
    padding-right: 0.875rem;
  }
  .mx-lg-14 {
    margin-left: 0.875rem;
    margin-right: 0.875rem;
  }
  .px-lg-14 {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
  }
  .my-lg-14 {
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
  }
  .py-lg-14 {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-16 {
    margin-top: 1rem;
  }
  .pt-lg-16 {
    padding-top: 1rem;
  }
  .mb-lg-16 {
    margin-bottom: 1rem;
  }
  .pb-lg-16 {
    padding-bottom: 1rem;
  }
  .ml-lg-16 {
    margin-left: 1rem;
  }
  .pl-lg-16 {
    padding-left: 1rem;
  }
  .mr-lg-16 {
    margin-right: 1rem;
  }
  .pr-lg-16 {
    padding-right: 1rem;
  }
  .mx-lg-16 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .px-lg-16 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .my-lg-16 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .py-lg-16 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-18 {
    margin-top: 1.125rem;
  }
  .pt-lg-18 {
    padding-top: 1.125rem;
  }
  .mb-lg-18 {
    margin-bottom: 1.125rem;
  }
  .pb-lg-18 {
    padding-bottom: 1.125rem;
  }
  .ml-lg-18 {
    margin-left: 1.125rem;
  }
  .pl-lg-18 {
    padding-left: 1.125rem;
  }
  .mr-lg-18 {
    margin-right: 1.125rem;
  }
  .pr-lg-18 {
    padding-right: 1.125rem;
  }
  .mx-lg-18 {
    margin-left: 1.125rem;
    margin-right: 1.125rem;
  }
  .px-lg-18 {
    padding-left: 1.125rem;
    padding-right: 1.125rem;
  }
  .my-lg-18 {
    margin-top: 1.125rem;
    margin-bottom: 1.125rem;
  }
  .py-lg-18 {
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-20 {
    margin-top: 1.25rem;
  }
  .pt-lg-20 {
    padding-top: 1.25rem;
  }
  .mb-lg-20 {
    margin-bottom: 1.25rem;
  }
  .pb-lg-20 {
    padding-bottom: 1.25rem;
  }
  .ml-lg-20 {
    margin-left: 1.25rem;
  }
  .pl-lg-20 {
    padding-left: 1.25rem;
  }
  .mr-lg-20 {
    margin-right: 1.25rem;
  }
  .pr-lg-20 {
    padding-right: 1.25rem;
  }
  .mx-lg-20 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .px-lg-20 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .my-lg-20 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .py-lg-20 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-24 {
    margin-top: 1.5rem;
  }
  .pt-lg-24 {
    padding-top: 1.5rem;
  }
  .mb-lg-24 {
    margin-bottom: 1.5rem;
  }
  .pb-lg-24 {
    padding-bottom: 1.5rem;
  }
  .ml-lg-24 {
    margin-left: 1.5rem;
  }
  .pl-lg-24 {
    padding-left: 1.5rem;
  }
  .mr-lg-24 {
    margin-right: 1.5rem;
  }
  .pr-lg-24 {
    padding-right: 1.5rem;
  }
  .mx-lg-24 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .px-lg-24 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .my-lg-24 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .py-lg-24 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-28 {
    margin-top: 1.75rem;
  }
  .pt-lg-28 {
    padding-top: 1.75rem;
  }
  .mb-lg-28 {
    margin-bottom: 1.75rem;
  }
  .pb-lg-28 {
    padding-bottom: 1.75rem;
  }
  .ml-lg-28 {
    margin-left: 1.75rem;
  }
  .pl-lg-28 {
    padding-left: 1.75rem;
  }
  .mr-lg-28 {
    margin-right: 1.75rem;
  }
  .pr-lg-28 {
    padding-right: 1.75rem;
  }
  .mx-lg-28 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }
  .px-lg-28 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .my-lg-28 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  .py-lg-28 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-32 {
    margin-top: 2rem;
  }
  .pt-lg-32 {
    padding-top: 2rem;
  }
  .mb-lg-32 {
    margin-bottom: 2rem;
  }
  .pb-lg-32 {
    padding-bottom: 2rem;
  }
  .ml-lg-32 {
    margin-left: 2rem;
  }
  .pl-lg-32 {
    padding-left: 2rem;
  }
  .mr-lg-32 {
    margin-right: 2rem;
  }
  .pr-lg-32 {
    padding-right: 2rem;
  }
  .mx-lg-32 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .px-lg-32 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .my-lg-32 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .py-lg-32 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-36 {
    margin-top: 2.25rem;
  }
  .pt-lg-36 {
    padding-top: 2.25rem;
  }
  .mb-lg-36 {
    margin-bottom: 2.25rem;
  }
  .pb-lg-36 {
    padding-bottom: 2.25rem;
  }
  .ml-lg-36 {
    margin-left: 2.25rem;
  }
  .pl-lg-36 {
    padding-left: 2.25rem;
  }
  .mr-lg-36 {
    margin-right: 2.25rem;
  }
  .pr-lg-36 {
    padding-right: 2.25rem;
  }
  .mx-lg-36 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }
  .px-lg-36 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  .my-lg-36 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }
  .py-lg-36 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-40 {
    margin-top: 2.5rem;
  }
  .pt-lg-40 {
    padding-top: 2.5rem;
  }
  .mb-lg-40 {
    margin-bottom: 2.5rem;
  }
  .pb-lg-40 {
    padding-bottom: 2.5rem;
  }
  .ml-lg-40 {
    margin-left: 2.5rem;
  }
  .pl-lg-40 {
    padding-left: 2.5rem;
  }
  .mr-lg-40 {
    margin-right: 2.5rem;
  }
  .pr-lg-40 {
    padding-right: 2.5rem;
  }
  .mx-lg-40 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .px-lg-40 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .my-lg-40 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .py-lg-40 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-48 {
    margin-top: 3rem;
  }
  .pt-lg-48 {
    padding-top: 3rem;
  }
  .mb-lg-48 {
    margin-bottom: 3rem;
  }
  .pb-lg-48 {
    padding-bottom: 3rem;
  }
  .ml-lg-48 {
    margin-left: 3rem;
  }
  .pl-lg-48 {
    padding-left: 3rem;
  }
  .mr-lg-48 {
    margin-right: 3rem;
  }
  .pr-lg-48 {
    padding-right: 3rem;
  }
  .mx-lg-48 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .px-lg-48 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .my-lg-48 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .py-lg-48 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-56 {
    margin-top: 3.5rem;
  }
  .pt-lg-56 {
    padding-top: 3.5rem;
  }
  .mb-lg-56 {
    margin-bottom: 3.5rem;
  }
  .pb-lg-56 {
    padding-bottom: 3.5rem;
  }
  .ml-lg-56 {
    margin-left: 3.5rem;
  }
  .pl-lg-56 {
    padding-left: 3.5rem;
  }
  .mr-lg-56 {
    margin-right: 3.5rem;
  }
  .pr-lg-56 {
    padding-right: 3.5rem;
  }
  .mx-lg-56 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }
  .px-lg-56 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .my-lg-56 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .py-lg-56 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-64 {
    margin-top: 4rem;
  }
  .pt-lg-64 {
    padding-top: 4rem;
  }
  .mb-lg-64 {
    margin-bottom: 4rem;
  }
  .pb-lg-64 {
    padding-bottom: 4rem;
  }
  .ml-lg-64 {
    margin-left: 4rem;
  }
  .pl-lg-64 {
    padding-left: 4rem;
  }
  .mr-lg-64 {
    margin-right: 4rem;
  }
  .pr-lg-64 {
    padding-right: 4rem;
  }
  .mx-lg-64 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .px-lg-64 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .my-lg-64 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .py-lg-64 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-72 {
    margin-top: 4.5rem;
  }
  .pt-lg-72 {
    padding-top: 4.5rem;
  }
  .mb-lg-72 {
    margin-bottom: 4.5rem;
  }
  .pb-lg-72 {
    padding-bottom: 4.5rem;
  }
  .ml-lg-72 {
    margin-left: 4.5rem;
  }
  .pl-lg-72 {
    padding-left: 4.5rem;
  }
  .mr-lg-72 {
    margin-right: 4.5rem;
  }
  .pr-lg-72 {
    padding-right: 4.5rem;
  }
  .mx-lg-72 {
    margin-left: 4.5rem;
    margin-right: 4.5rem;
  }
  .px-lg-72 {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
  }
  .my-lg-72 {
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
  }
  .py-lg-72 {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-100 {
    margin-top: 6.25rem;
  }
  .pt-lg-100 {
    padding-top: 6.25rem;
  }
  .mb-lg-100 {
    margin-bottom: 6.25rem;
  }
  .pb-lg-100 {
    padding-bottom: 6.25rem;
  }
  .ml-lg-100 {
    margin-left: 6.25rem;
  }
  .pl-lg-100 {
    padding-left: 6.25rem;
  }
  .mr-lg-100 {
    margin-right: 6.25rem;
  }
  .pr-lg-100 {
    padding-right: 6.25rem;
  }
  .mx-lg-100 {
    margin-left: 6.25rem;
    margin-right: 6.25rem;
  }
  .px-lg-100 {
    padding-left: 6.25rem;
    padding-right: 6.25rem;
  }
  .my-lg-100 {
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;
  }
  .py-lg-100 {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-112 {
    margin-top: 7rem;
  }
  .pt-lg-112 {
    padding-top: 7rem;
  }
  .mb-lg-112 {
    margin-bottom: 7rem;
  }
  .pb-lg-112 {
    padding-bottom: 7rem;
  }
  .ml-lg-112 {
    margin-left: 7rem;
  }
  .pl-lg-112 {
    padding-left: 7rem;
  }
  .mr-lg-112 {
    margin-right: 7rem;
  }
  .pr-lg-112 {
    padding-right: 7rem;
  }
  .mx-lg-112 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .px-lg-112 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .my-lg-112 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .py-lg-112 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-120 {
    margin-top: 7.5rem;
  }
  .pt-lg-120 {
    padding-top: 7.5rem;
  }
  .mb-lg-120 {
    margin-bottom: 7.5rem;
  }
  .pb-lg-120 {
    padding-bottom: 7.5rem;
  }
  .ml-lg-120 {
    margin-left: 7.5rem;
  }
  .pl-lg-120 {
    padding-left: 7.5rem;
  }
  .mr-lg-120 {
    margin-right: 7.5rem;
  }
  .pr-lg-120 {
    padding-right: 7.5rem;
  }
  .mx-lg-120 {
    margin-left: 7.5rem;
    margin-right: 7.5rem;
  }
  .px-lg-120 {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }
  .my-lg-120 {
    margin-top: 7.5rem;
    margin-bottom: 7.5rem;
  }
  .py-lg-120 {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-124 {
    margin-top: 8rem;
  }
  .pt-lg-124 {
    padding-top: 8rem;
  }
  .mb-lg-124 {
    margin-bottom: 8rem;
  }
  .pb-lg-124 {
    padding-bottom: 8rem;
  }
  .ml-lg-124 {
    margin-left: 8rem;
  }
  .pl-lg-124 {
    padding-left: 8rem;
  }
  .mr-lg-124 {
    margin-right: 8rem;
  }
  .pr-lg-124 {
    padding-right: 8rem;
  }
  .mx-lg-124 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .px-lg-124 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .my-lg-124 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .py-lg-124 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-136 {
    margin-top: 8.5rem;
  }
  .pt-lg-136 {
    padding-top: 8.5rem;
  }
  .mb-lg-136 {
    margin-bottom: 8.5rem;
  }
  .pb-lg-136 {
    padding-bottom: 8.5rem;
  }
  .ml-lg-136 {
    margin-left: 8.5rem;
  }
  .pl-lg-136 {
    padding-left: 8.5rem;
  }
  .mr-lg-136 {
    margin-right: 8.5rem;
  }
  .pr-lg-136 {
    padding-right: 8.5rem;
  }
  .mx-lg-136 {
    margin-left: 8.5rem;
    margin-right: 8.5rem;
  }
  .px-lg-136 {
    padding-left: 8.5rem;
    padding-right: 8.5rem;
  }
  .my-lg-136 {
    margin-top: 8.5rem;
    margin-bottom: 8.5rem;
  }
  .py-lg-136 {
    padding-top: 8.5rem;
    padding-bottom: 8.5rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-144 {
    margin-top: 9rem;
  }
  .pt-lg-144 {
    padding-top: 9rem;
  }
  .mb-lg-144 {
    margin-bottom: 9rem;
  }
  .pb-lg-144 {
    padding-bottom: 9rem;
  }
  .ml-lg-144 {
    margin-left: 9rem;
  }
  .pl-lg-144 {
    padding-left: 9rem;
  }
  .mr-lg-144 {
    margin-right: 9rem;
  }
  .pr-lg-144 {
    padding-right: 9rem;
  }
  .mx-lg-144 {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .px-lg-144 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .my-lg-144 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .py-lg-144 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-152 {
    margin-top: 9.5rem;
  }
  .pt-lg-152 {
    padding-top: 9.5rem;
  }
  .mb-lg-152 {
    margin-bottom: 9.5rem;
  }
  .pb-lg-152 {
    padding-bottom: 9.5rem;
  }
  .ml-lg-152 {
    margin-left: 9.5rem;
  }
  .pl-lg-152 {
    padding-left: 9.5rem;
  }
  .mr-lg-152 {
    margin-right: 9.5rem;
  }
  .pr-lg-152 {
    padding-right: 9.5rem;
  }
  .mx-lg-152 {
    margin-left: 9.5rem;
    margin-right: 9.5rem;
  }
  .px-lg-152 {
    padding-left: 9.5rem;
    padding-right: 9.5rem;
  }
  .my-lg-152 {
    margin-top: 9.5rem;
    margin-bottom: 9.5rem;
  }
  .py-lg-152 {
    padding-top: 9.5rem;
    padding-bottom: 9.5rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-160 {
    margin-top: 10rem;
  }
  .pt-lg-160 {
    padding-top: 10rem;
  }
  .mb-lg-160 {
    margin-bottom: 10rem;
  }
  .pb-lg-160 {
    padding-bottom: 10rem;
  }
  .ml-lg-160 {
    margin-left: 10rem;
  }
  .pl-lg-160 {
    padding-left: 10rem;
  }
  .mr-lg-160 {
    margin-right: 10rem;
  }
  .pr-lg-160 {
    padding-right: 10rem;
  }
  .mx-lg-160 {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .px-lg-160 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .my-lg-160 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .py-lg-160 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-168 {
    margin-top: 10.5rem;
  }
  .pt-lg-168 {
    padding-top: 10.5rem;
  }
  .mb-lg-168 {
    margin-bottom: 10.5rem;
  }
  .pb-lg-168 {
    padding-bottom: 10.5rem;
  }
  .ml-lg-168 {
    margin-left: 10.5rem;
  }
  .pl-lg-168 {
    padding-left: 10.5rem;
  }
  .mr-lg-168 {
    margin-right: 10.5rem;
  }
  .pr-lg-168 {
    padding-right: 10.5rem;
  }
  .mx-lg-168 {
    margin-left: 10.5rem;
    margin-right: 10.5rem;
  }
  .px-lg-168 {
    padding-left: 10.5rem;
    padding-right: 10.5rem;
  }
  .my-lg-168 {
    margin-top: 10.5rem;
    margin-bottom: 10.5rem;
  }
  .py-lg-168 {
    padding-top: 10.5rem;
    padding-bottom: 10.5rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-176 {
    margin-top: 11rem;
  }
  .pt-lg-176 {
    padding-top: 11rem;
  }
  .mb-lg-176 {
    margin-bottom: 11rem;
  }
  .pb-lg-176 {
    padding-bottom: 11rem;
  }
  .ml-lg-176 {
    margin-left: 11rem;
  }
  .pl-lg-176 {
    padding-left: 11rem;
  }
  .mr-lg-176 {
    margin-right: 11rem;
  }
  .pr-lg-176 {
    padding-right: 11rem;
  }
  .mx-lg-176 {
    margin-left: 11rem;
    margin-right: 11rem;
  }
  .px-lg-176 {
    padding-left: 11rem;
    padding-right: 11rem;
  }
  .my-lg-176 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }
  .py-lg-176 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-184 {
    margin-top: 11.5rem;
  }
  .pt-lg-184 {
    padding-top: 11.5rem;
  }
  .mb-lg-184 {
    margin-bottom: 11.5rem;
  }
  .pb-lg-184 {
    padding-bottom: 11.5rem;
  }
  .ml-lg-184 {
    margin-left: 11.5rem;
  }
  .pl-lg-184 {
    padding-left: 11.5rem;
  }
  .mr-lg-184 {
    margin-right: 11.5rem;
  }
  .pr-lg-184 {
    padding-right: 11.5rem;
  }
  .mx-lg-184 {
    margin-left: 11.5rem;
    margin-right: 11.5rem;
  }
  .px-lg-184 {
    padding-left: 11.5rem;
    padding-right: 11.5rem;
  }
  .my-lg-184 {
    margin-top: 11.5rem;
    margin-bottom: 11.5rem;
  }
  .py-lg-184 {
    padding-top: 11.5rem;
    padding-bottom: 11.5rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-192 {
    margin-top: 12rem;
  }
  .pt-lg-192 {
    padding-top: 12rem;
  }
  .mb-lg-192 {
    margin-bottom: 12rem;
  }
  .pb-lg-192 {
    padding-bottom: 12rem;
  }
  .ml-lg-192 {
    margin-left: 12rem;
  }
  .pl-lg-192 {
    padding-left: 12rem;
  }
  .mr-lg-192 {
    margin-right: 12rem;
  }
  .pr-lg-192 {
    padding-right: 12rem;
  }
  .mx-lg-192 {
    margin-left: 12rem;
    margin-right: 12rem;
  }
  .px-lg-192 {
    padding-left: 12rem;
    padding-right: 12rem;
  }
  .my-lg-192 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }
  .py-lg-192 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
  .mt-lg-200 {
    margin-top: 12.5rem;
  }
  .pt-lg-200 {
    padding-top: 12.5rem;
  }
  .mb-lg-200 {
    margin-bottom: 12.5rem;
  }
  .pb-lg-200 {
    padding-bottom: 12.5rem;
  }
  .ml-lg-200 {
    margin-left: 12.5rem;
  }
  .pl-lg-200 {
    padding-left: 12.5rem;
  }
  .mr-lg-200 {
    margin-right: 12.5rem;
  }
  .pr-lg-200 {
    padding-right: 12.5rem;
  }
  .mx-lg-200 {
    margin-left: 12.5rem;
    margin-right: 12.5rem;
  }
  .px-lg-200 {
    padding-left: 12.5rem;
    padding-right: 12.5rem;
  }
  .my-lg-200 {
    margin-top: 12.5rem;
    margin-bottom: 12.5rem;
  }
  .py-lg-200 {
    padding-top: 12.5rem;
    padding-bottom: 12.5rem;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-inherit {
    flex-direction: inherit;
  }
  .flex-lg-unset {
    flex-direction: unset;
  }
}
body {
  --white: white;
  --black: black;
  font-weight: 400;
}
::-webkit-input-placeholder {
  color: #b0b0b0;
  font-weight: normal;
}
:-ms-input-placeholder {
  color: #b0b0b0;
  font-weight: normal;
}
::placeholder {
  color: #b0b0b0;
  font-weight: normal;
}
.white-space-no-wrap {
  white-space: nowrap;
}
.border {
  border: 1px solid #dee2e6;
}
.border-top {
  border-top: 1px solid #dee2e6;
}
.border-bottom {
  border-bottom: 1px solid #dee2e6;
}
.border-left {
  border-left: 1px solid #dee2e6;
}
.border-right {
  border-right: 1px solid #dee2e6;
}
.border-color-white {
  border-color: var(--white);
}
.text-color-white {
  color: var(--white);
}
.background-color-white {
  background-color: var(--white);
}
.border-color-black {
  border-color: var(--black);
}
.text-color-black {
  color: var(--black);
}
.background-color-black {
  background-color: var(--black);
}
.border-color-theme_primary {
  border-color: var(--theme_primary);
}
.text-color-theme_primary {
  color: var(--theme_primary);
}
.background-color-theme_primary {
  background-color: var(--theme_primary);
}
.border-color-theme_light {
  border-color: var(--theme_light);
}
.text-color-theme_light {
  color: var(--theme_light);
}
.background-color-theme_light {
  background-color: var(--theme_light);
}
.border-color-text_primary {
  border-color: var(--theme_text_primary);
}
.text-color-text_primary {
  color: var(--theme_text_primary);
}
.background-color-text_primary {
  background-color: var(--theme_text_primary);
}
.border-color-text_secondary {
  border-color: var(--theme_text_secondary);
}
.text-color-text_secondary {
  color: var(--theme_text_secondary);
}
.background-color-text_secondary {
  background-color: var(--theme_text_secondary);
}
.border-color-text_tertiary {
  border-color: var(--theme_text_tertiary);
}
.text-color-text_tertiary {
  color: var(--theme_text_tertiary);
}
.background-color-text_tertiary {
  background-color: var(--theme_text_tertiary);
}
.border-circle {
  border-radius: 50%;
}
.border-rounded {
  border-radius: 1%;
}
.border-none {
  border-radius: 0;
}
.position-absolute absolute {
  position: absolute absolute;
}
.position-relative relative {
  position: relative relative;
}
.position-fixed fixed {
  position: fixed fixed;
}
.position-sticky sticky {
  position: -webkit-sticky -webkit-sticky;
  position: sticky sticky;
}
a:hover {
  text-decoration: none;
}
.text-decoration-none {
  text-decoration: none;
}
.text-break {
  word-wrap: break-word;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.transform-30 {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
}
.transform-45 {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.transform-60 {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
}
.transform-90 {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.transform-120 {
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
}
.transform-135 {
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
}
.transform-150 {
  -webkit-transform: rotate(150deg);
          transform: rotate(150deg);
}
.transform-180 {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.font-weight-100 {
  font-weight: 100;
}
.font-weight-200 {
  font-weight: 200;
}
.font-weight-300 {
  font-weight: 300;
}
.font-weight-400 {
  font-weight: 400;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-600 {
  font-weight: 600;
}
.font-weight-700 {
  font-weight: 700;
}
.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}
.text-align-center {
  text-align: center;
}
.text-align-justify {
  text-align: justify;
}
.text-align-end {
  text-align: end;
}
.display-none {
  display: none;
}
.display-inline {
  display: inline;
}
.display-inline-block {
  display: inline-block;
}
.display-block {
  display: block;
}
.display-table {
  display: table;
}
.display-table-cell {
  display: table-cell;
}
.display-table-row {
  display: table-row;
}
.display-flex {
  display: flex;
}
.display-inline-flex {
  display: inline-flex;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-evenly {
  justify-content: space-evenly;
}
.justify-content-start {
  justify-content: flex-start;
}
.justify-content-end {
  justify-content: flex-end;
}
.align-items-baseline {
  align-items: baseline;
}
.align-items-center {
  align-items: center;
}
.align-items-left {
  align-items: left;
}
.align-items-right {
  align-items: right;
}
.align-items-start {
  align-items: flex-start;
}
.align-items-end {
  align-items: flex-end;
}
.align-items-stretch {
  align-items: stretch;
}
@media (min-width: 375px) {
  .display-xs-none {
    display: none;
  }
}
@media (min-width: 375px) {
  .display-xs-inline {
    display: inline;
  }
}
@media (min-width: 375px) {
  .display-xs-inline-block {
    display: inline-block;
  }
}
@media (min-width: 375px) {
  .display-xs-block {
    display: block;
  }
}
@media (min-width: 375px) {
  .display-xs-table {
    display: table;
  }
}
@media (min-width: 375px) {
  .display-xs-table-cell {
    display: table-cell;
  }
}
@media (min-width: 375px) {
  .display-xs-table-row {
    display: table-row;
  }
}
@media (min-width: 375px) {
  .display-xs-flex {
    display: flex;
  }
}
@media (min-width: 375px) {
  .display-xs-inline-flex {
    display: inline-flex;
  }
}
@media (min-width: 576px) {
  .display-sm-none {
    display: none;
  }
}
@media (min-width: 576px) {
  .display-sm-inline {
    display: inline;
  }
}
@media (min-width: 576px) {
  .display-sm-inline-block {
    display: inline-block;
  }
}
@media (min-width: 576px) {
  .display-sm-block {
    display: block;
  }
}
@media (min-width: 576px) {
  .display-sm-table {
    display: table;
  }
}
@media (min-width: 576px) {
  .display-sm-table-cell {
    display: table-cell;
  }
}
@media (min-width: 576px) {
  .display-sm-table-row {
    display: table-row;
  }
}
@media (min-width: 576px) {
  .display-sm-flex {
    display: flex;
  }
}
@media (min-width: 576px) {
  .display-sm-inline-flex {
    display: inline-flex;
  }
}
@media (min-width: 768px) {
  .display-md-none {
    display: none;
  }
}
@media (min-width: 768px) {
  .display-md-inline {
    display: inline;
  }
}
@media (min-width: 768px) {
  .display-md-inline-block {
    display: inline-block;
  }
}
@media (min-width: 768px) {
  .display-md-block {
    display: block;
  }
}
@media (min-width: 768px) {
  .display-md-table {
    display: table;
  }
}
@media (min-width: 768px) {
  .display-md-table-cell {
    display: table-cell;
  }
}
@media (min-width: 768px) {
  .display-md-table-row {
    display: table-row;
  }
}
@media (min-width: 768px) {
  .display-md-flex {
    display: flex;
  }
}
@media (min-width: 768px) {
  .display-md-inline-flex {
    display: inline-flex;
  }
}
@media (min-width: 992px) {
  .display-lg-none {
    display: none;
  }
}
@media (min-width: 992px) {
  .display-lg-inline {
    display: inline;
  }
}
@media (min-width: 992px) {
  .display-lg-inline-block {
    display: inline-block;
  }
}
@media (min-width: 992px) {
  .display-lg-block {
    display: block;
  }
}
@media (min-width: 992px) {
  .display-lg-table {
    display: table;
  }
}
@media (min-width: 992px) {
  .display-lg-table-cell {
    display: table-cell;
  }
}
@media (min-width: 992px) {
  .display-lg-table-row {
    display: table-row;
  }
}
@media (min-width: 992px) {
  .display-lg-flex {
    display: flex;
  }
}
@media (min-width: 992px) {
  .display-lg-inline-flex {
    display: inline-flex;
  }
}
@media (min-width: 1260px) {
  .display-xl-none {
    display: none;
  }
}
@media (min-width: 1260px) {
  .display-xl-inline {
    display: inline;
  }
}
@media (min-width: 1260px) {
  .display-xl-inline-block {
    display: inline-block;
  }
}
@media (min-width: 1260px) {
  .display-xl-block {
    display: block;
  }
}
@media (min-width: 1260px) {
  .display-xl-table {
    display: table;
  }
}
@media (min-width: 1260px) {
  .display-xl-table-cell {
    display: table-cell;
  }
}
@media (min-width: 1260px) {
  .display-xl-table-row {
    display: table-row;
  }
}
@media (min-width: 1260px) {
  .display-xl-flex {
    display: flex;
  }
}
@media (min-width: 1260px) {
  .display-xl-inline-flex {
    display: inline-flex;
  }
}
@media (min-width: 1400px) {
  .display-xxl-none {
    display: none;
  }
}
@media (min-width: 1400px) {
  .display-xxl-inline {
    display: inline;
  }
}
@media (min-width: 1400px) {
  .display-xxl-inline-block {
    display: inline-block;
  }
}
@media (min-width: 1400px) {
  .display-xxl-block {
    display: block;
  }
}
@media (min-width: 1400px) {
  .display-xxl-table {
    display: table;
  }
}
@media (min-width: 1400px) {
  .display-xxl-table-cell {
    display: table-cell;
  }
}
@media (min-width: 1400px) {
  .display-xxl-table-row {
    display: table-row;
  }
}
@media (min-width: 1400px) {
  .display-xxl-flex {
    display: flex;
  }
}
@media (min-width: 1400px) {
  .display-xxl-inline-flex {
    display: inline-flex;
  }
}
.form-group {
  padding-bottom: 1.5rem;
}
.form-input-control {
  display: inline-block;
  width: 100%;
  vertical-align: middle;
  padding: 0.75rem 0.75rem;
  line-height: 1.5rem;
  color: var(--theme_text_primary);
}
.form-input-control:hover,
.form-input-control:focus,
.form-input-control:focus-visible,
.form-input-control:active {
  border: 1px solid var(--theme_primary);
  outline: unset;
}
.form-input-control::-webkit-input-placeholder {
  color: #b0b0b0;
  font-weight: normal;
}
.form-input-control:-ms-input-placeholder {
  color: #b0b0b0;
  font-weight: normal;
}
.form-input-control::placeholder {
  color: #b0b0b0;
  font-weight: normal;
}
.width-10 {
  width: calc(10 * 1%);
}
.width-20 {
  width: calc(20 * 1%);
}
.width-25 {
  width: calc(25 * 1%);
}
.width-30 {
  width: calc(30 * 1%);
}
.width-35 {
  width: calc(35 * 1%);
}
.width-40 {
  width: calc(40 * 1%);
}
.width-45 {
  width: calc(45 * 1%);
}
.width-50 {
  width: calc(50 * 1%);
}
.width-55 {
  width: calc(55 * 1%);
}
.width-60 {
  width: calc(60 * 1%);
}
.width-65 {
  width: calc(65 * 1%);
}
.width-70 {
  width: calc(70 * 1%);
}
.width-75 {
  width: calc(75 * 1%);
}
.width-80 {
  width: calc(80 * 1%);
}
.width-85 {
  width: calc(85 * 1%);
}
.width-90 {
  width: calc(90 * 1%);
}
.width-95 {
  width: calc(95 * 1%);
}
.width-100 {
  width: calc(100 * 1%);
}
.width-105 {
  width: calc(105 * 1%);
}
.width-110 {
  width: calc(110 * 1%);
}
.width-115 {
  width: calc(115 * 1%);
}
.width-120 {
  width: calc(120 * 1%);
}
.width-125 {
  width: calc(125 * 1%);
}
.width-130 {
  width: calc(130 * 1%);
}
.width-135 {
  width: calc(135 * 1%);
}
.width-140 {
  width: calc(140 * 1%);
}
.width-145 {
  width: calc(145 * 1%);
}
.width-150 {
  width: calc(150 * 1%);
}
@media (min-width: 375px) {
  .width-xs-10 {
    width: calc(10 * 1%);
  }
}
@media (min-width: 375px) {
  .width-xs-20 {
    width: calc(20 * 1%);
  }
}
@media (min-width: 375px) {
  .width-xs-25 {
    width: calc(25 * 1%);
  }
}
@media (min-width: 375px) {
  .width-xs-30 {
    width: calc(30 * 1%);
  }
}
@media (min-width: 375px) {
  .width-xs-35 {
    width: calc(35 * 1%);
  }
}
@media (min-width: 375px) {
  .width-xs-40 {
    width: calc(40 * 1%);
  }
}
@media (min-width: 375px) {
  .width-xs-45 {
    width: calc(45 * 1%);
  }
}
@media (min-width: 375px) {
  .width-xs-50 {
    width: calc(50 * 1%);
  }
}
@media (min-width: 375px) {
  .width-xs-55 {
    width: calc(55 * 1%);
  }
}
@media (min-width: 375px) {
  .width-xs-60 {
    width: calc(60 * 1%);
  }
}
@media (min-width: 375px) {
  .width-xs-65 {
    width: calc(65 * 1%);
  }
}
@media (min-width: 375px) {
  .width-xs-70 {
    width: calc(70 * 1%);
  }
}
@media (min-width: 375px) {
  .width-xs-75 {
    width: calc(75 * 1%);
  }
}
@media (min-width: 375px) {
  .width-xs-80 {
    width: calc(80 * 1%);
  }
}
@media (min-width: 375px) {
  .width-xs-85 {
    width: calc(85 * 1%);
  }
}
@media (min-width: 375px) {
  .width-xs-90 {
    width: calc(90 * 1%);
  }
}
@media (min-width: 375px) {
  .width-xs-95 {
    width: calc(95 * 1%);
  }
}
@media (min-width: 375px) {
  .width-xs-100 {
    width: calc(100 * 1%);
  }
}
@media (min-width: 375px) {
  .width-xs-105 {
    width: calc(105 * 1%);
  }
}
@media (min-width: 375px) {
  .width-xs-110 {
    width: calc(110 * 1%);
  }
}
@media (min-width: 375px) {
  .width-xs-115 {
    width: calc(115 * 1%);
  }
}
@media (min-width: 375px) {
  .width-xs-120 {
    width: calc(120 * 1%);
  }
}
@media (min-width: 375px) {
  .width-xs-125 {
    width: calc(125 * 1%);
  }
}
@media (min-width: 375px) {
  .width-xs-130 {
    width: calc(130 * 1%);
  }
}
@media (min-width: 375px) {
  .width-xs-135 {
    width: calc(135 * 1%);
  }
}
@media (min-width: 375px) {
  .width-xs-140 {
    width: calc(140 * 1%);
  }
}
@media (min-width: 375px) {
  .width-xs-145 {
    width: calc(145 * 1%);
  }
}
@media (min-width: 375px) {
  .width-xs-150 {
    width: calc(150 * 1%);
  }
}
@media (min-width: 576px) {
  .width-sm-10 {
    width: calc(10 * 1%);
  }
}
@media (min-width: 576px) {
  .width-sm-20 {
    width: calc(20 * 1%);
  }
}
@media (min-width: 576px) {
  .width-sm-25 {
    width: calc(25 * 1%);
  }
}
@media (min-width: 576px) {
  .width-sm-30 {
    width: calc(30 * 1%);
  }
}
@media (min-width: 576px) {
  .width-sm-35 {
    width: calc(35 * 1%);
  }
}
@media (min-width: 576px) {
  .width-sm-40 {
    width: calc(40 * 1%);
  }
}
@media (min-width: 576px) {
  .width-sm-45 {
    width: calc(45 * 1%);
  }
}
@media (min-width: 576px) {
  .width-sm-50 {
    width: calc(50 * 1%);
  }
}
@media (min-width: 576px) {
  .width-sm-55 {
    width: calc(55 * 1%);
  }
}
@media (min-width: 576px) {
  .width-sm-60 {
    width: calc(60 * 1%);
  }
}
@media (min-width: 576px) {
  .width-sm-65 {
    width: calc(65 * 1%);
  }
}
@media (min-width: 576px) {
  .width-sm-70 {
    width: calc(70 * 1%);
  }
}
@media (min-width: 576px) {
  .width-sm-75 {
    width: calc(75 * 1%);
  }
}
@media (min-width: 576px) {
  .width-sm-80 {
    width: calc(80 * 1%);
  }
}
@media (min-width: 576px) {
  .width-sm-85 {
    width: calc(85 * 1%);
  }
}
@media (min-width: 576px) {
  .width-sm-90 {
    width: calc(90 * 1%);
  }
}
@media (min-width: 576px) {
  .width-sm-95 {
    width: calc(95 * 1%);
  }
}
@media (min-width: 576px) {
  .width-sm-100 {
    width: calc(100 * 1%);
  }
}
@media (min-width: 576px) {
  .width-sm-105 {
    width: calc(105 * 1%);
  }
}
@media (min-width: 576px) {
  .width-sm-110 {
    width: calc(110 * 1%);
  }
}
@media (min-width: 576px) {
  .width-sm-115 {
    width: calc(115 * 1%);
  }
}
@media (min-width: 576px) {
  .width-sm-120 {
    width: calc(120 * 1%);
  }
}
@media (min-width: 576px) {
  .width-sm-125 {
    width: calc(125 * 1%);
  }
}
@media (min-width: 576px) {
  .width-sm-130 {
    width: calc(130 * 1%);
  }
}
@media (min-width: 576px) {
  .width-sm-135 {
    width: calc(135 * 1%);
  }
}
@media (min-width: 576px) {
  .width-sm-140 {
    width: calc(140 * 1%);
  }
}
@media (min-width: 576px) {
  .width-sm-145 {
    width: calc(145 * 1%);
  }
}
@media (min-width: 576px) {
  .width-sm-150 {
    width: calc(150 * 1%);
  }
}
@media (min-width: 768px) {
  .width-md-10 {
    width: calc(10 * 1%);
  }
}
@media (min-width: 768px) {
  .width-md-20 {
    width: calc(20 * 1%);
  }
}
@media (min-width: 768px) {
  .width-md-25 {
    width: calc(25 * 1%);
  }
}
@media (min-width: 768px) {
  .width-md-30 {
    width: calc(30 * 1%);
  }
}
@media (min-width: 768px) {
  .width-md-35 {
    width: calc(35 * 1%);
  }
}
@media (min-width: 768px) {
  .width-md-40 {
    width: calc(40 * 1%);
  }
}
@media (min-width: 768px) {
  .width-md-45 {
    width: calc(45 * 1%);
  }
}
@media (min-width: 768px) {
  .width-md-50 {
    width: calc(50 * 1%);
  }
}
@media (min-width: 768px) {
  .width-md-55 {
    width: calc(55 * 1%);
  }
}
@media (min-width: 768px) {
  .width-md-60 {
    width: calc(60 * 1%);
  }
}
@media (min-width: 768px) {
  .width-md-65 {
    width: calc(65 * 1%);
  }
}
@media (min-width: 768px) {
  .width-md-70 {
    width: calc(70 * 1%);
  }
}
@media (min-width: 768px) {
  .width-md-75 {
    width: calc(75 * 1%);
  }
}
@media (min-width: 768px) {
  .width-md-80 {
    width: calc(80 * 1%);
  }
}
@media (min-width: 768px) {
  .width-md-85 {
    width: calc(85 * 1%);
  }
}
@media (min-width: 768px) {
  .width-md-90 {
    width: calc(90 * 1%);
  }
}
@media (min-width: 768px) {
  .width-md-95 {
    width: calc(95 * 1%);
  }
}
@media (min-width: 768px) {
  .width-md-100 {
    width: calc(100 * 1%);
  }
}
@media (min-width: 768px) {
  .width-md-105 {
    width: calc(105 * 1%);
  }
}
@media (min-width: 768px) {
  .width-md-110 {
    width: calc(110 * 1%);
  }
}
@media (min-width: 768px) {
  .width-md-115 {
    width: calc(115 * 1%);
  }
}
@media (min-width: 768px) {
  .width-md-120 {
    width: calc(120 * 1%);
  }
}
@media (min-width: 768px) {
  .width-md-125 {
    width: calc(125 * 1%);
  }
}
@media (min-width: 768px) {
  .width-md-130 {
    width: calc(130 * 1%);
  }
}
@media (min-width: 768px) {
  .width-md-135 {
    width: calc(135 * 1%);
  }
}
@media (min-width: 768px) {
  .width-md-140 {
    width: calc(140 * 1%);
  }
}
@media (min-width: 768px) {
  .width-md-145 {
    width: calc(145 * 1%);
  }
}
@media (min-width: 768px) {
  .width-md-150 {
    width: calc(150 * 1%);
  }
}
@media (min-width: 992px) {
  .width-lg-10 {
    width: calc(10 * 1%);
  }
}
@media (min-width: 992px) {
  .width-lg-20 {
    width: calc(20 * 1%);
  }
}
@media (min-width: 992px) {
  .width-lg-25 {
    width: calc(25 * 1%);
  }
}
@media (min-width: 992px) {
  .width-lg-30 {
    width: calc(30 * 1%);
  }
}
@media (min-width: 992px) {
  .width-lg-35 {
    width: calc(35 * 1%);
  }
}
@media (min-width: 992px) {
  .width-lg-40 {
    width: calc(40 * 1%);
  }
}
@media (min-width: 992px) {
  .width-lg-45 {
    width: calc(45 * 1%);
  }
}
@media (min-width: 992px) {
  .width-lg-50 {
    width: calc(50 * 1%);
  }
}
@media (min-width: 992px) {
  .width-lg-55 {
    width: calc(55 * 1%);
  }
}
@media (min-width: 992px) {
  .width-lg-60 {
    width: calc(60 * 1%);
  }
}
@media (min-width: 992px) {
  .width-lg-65 {
    width: calc(65 * 1%);
  }
}
@media (min-width: 992px) {
  .width-lg-70 {
    width: calc(70 * 1%);
  }
}
@media (min-width: 992px) {
  .width-lg-75 {
    width: calc(75 * 1%);
  }
}
@media (min-width: 992px) {
  .width-lg-80 {
    width: calc(80 * 1%);
  }
}
@media (min-width: 992px) {
  .width-lg-85 {
    width: calc(85 * 1%);
  }
}
@media (min-width: 992px) {
  .width-lg-90 {
    width: calc(90 * 1%);
  }
}
@media (min-width: 992px) {
  .width-lg-95 {
    width: calc(95 * 1%);
  }
}
@media (min-width: 992px) {
  .width-lg-100 {
    width: calc(100 * 1%);
  }
}
@media (min-width: 992px) {
  .width-lg-105 {
    width: calc(105 * 1%);
  }
}
@media (min-width: 992px) {
  .width-lg-110 {
    width: calc(110 * 1%);
  }
}
@media (min-width: 992px) {
  .width-lg-115 {
    width: calc(115 * 1%);
  }
}
@media (min-width: 992px) {
  .width-lg-120 {
    width: calc(120 * 1%);
  }
}
@media (min-width: 992px) {
  .width-lg-125 {
    width: calc(125 * 1%);
  }
}
@media (min-width: 992px) {
  .width-lg-130 {
    width: calc(130 * 1%);
  }
}
@media (min-width: 992px) {
  .width-lg-135 {
    width: calc(135 * 1%);
  }
}
@media (min-width: 992px) {
  .width-lg-140 {
    width: calc(140 * 1%);
  }
}
@media (min-width: 992px) {
  .width-lg-145 {
    width: calc(145 * 1%);
  }
}
@media (min-width: 992px) {
  .width-lg-150 {
    width: calc(150 * 1%);
  }
}
@media (min-width: 1260px) {
  .width-xl-10 {
    width: calc(10 * 1%);
  }
}
@media (min-width: 1260px) {
  .width-xl-20 {
    width: calc(20 * 1%);
  }
}
@media (min-width: 1260px) {
  .width-xl-25 {
    width: calc(25 * 1%);
  }
}
@media (min-width: 1260px) {
  .width-xl-30 {
    width: calc(30 * 1%);
  }
}
@media (min-width: 1260px) {
  .width-xl-35 {
    width: calc(35 * 1%);
  }
}
@media (min-width: 1260px) {
  .width-xl-40 {
    width: calc(40 * 1%);
  }
}
@media (min-width: 1260px) {
  .width-xl-45 {
    width: calc(45 * 1%);
  }
}
@media (min-width: 1260px) {
  .width-xl-50 {
    width: calc(50 * 1%);
  }
}
@media (min-width: 1260px) {
  .width-xl-55 {
    width: calc(55 * 1%);
  }
}
@media (min-width: 1260px) {
  .width-xl-60 {
    width: calc(60 * 1%);
  }
}
@media (min-width: 1260px) {
  .width-xl-65 {
    width: calc(65 * 1%);
  }
}
@media (min-width: 1260px) {
  .width-xl-70 {
    width: calc(70 * 1%);
  }
}
@media (min-width: 1260px) {
  .width-xl-75 {
    width: calc(75 * 1%);
  }
}
@media (min-width: 1260px) {
  .width-xl-80 {
    width: calc(80 * 1%);
  }
}
@media (min-width: 1260px) {
  .width-xl-85 {
    width: calc(85 * 1%);
  }
}
@media (min-width: 1260px) {
  .width-xl-90 {
    width: calc(90 * 1%);
  }
}
@media (min-width: 1260px) {
  .width-xl-95 {
    width: calc(95 * 1%);
  }
}
@media (min-width: 1260px) {
  .width-xl-100 {
    width: calc(100 * 1%);
  }
}
@media (min-width: 1260px) {
  .width-xl-105 {
    width: calc(105 * 1%);
  }
}
@media (min-width: 1260px) {
  .width-xl-110 {
    width: calc(110 * 1%);
  }
}
@media (min-width: 1260px) {
  .width-xl-115 {
    width: calc(115 * 1%);
  }
}
@media (min-width: 1260px) {
  .width-xl-120 {
    width: calc(120 * 1%);
  }
}
@media (min-width: 1260px) {
  .width-xl-125 {
    width: calc(125 * 1%);
  }
}
@media (min-width: 1260px) {
  .width-xl-130 {
    width: calc(130 * 1%);
  }
}
@media (min-width: 1260px) {
  .width-xl-135 {
    width: calc(135 * 1%);
  }
}
@media (min-width: 1260px) {
  .width-xl-140 {
    width: calc(140 * 1%);
  }
}
@media (min-width: 1260px) {
  .width-xl-145 {
    width: calc(145 * 1%);
  }
}
@media (min-width: 1260px) {
  .width-xl-150 {
    width: calc(150 * 1%);
  }
}
@media (min-width: 1400px) {
  .width-xxl-10 {
    width: calc(10 * 1%);
  }
}
@media (min-width: 1400px) {
  .width-xxl-20 {
    width: calc(20 * 1%);
  }
}
@media (min-width: 1400px) {
  .width-xxl-25 {
    width: calc(25 * 1%);
  }
}
@media (min-width: 1400px) {
  .width-xxl-30 {
    width: calc(30 * 1%);
  }
}
@media (min-width: 1400px) {
  .width-xxl-35 {
    width: calc(35 * 1%);
  }
}
@media (min-width: 1400px) {
  .width-xxl-40 {
    width: calc(40 * 1%);
  }
}
@media (min-width: 1400px) {
  .width-xxl-45 {
    width: calc(45 * 1%);
  }
}
@media (min-width: 1400px) {
  .width-xxl-50 {
    width: calc(50 * 1%);
  }
}
@media (min-width: 1400px) {
  .width-xxl-55 {
    width: calc(55 * 1%);
  }
}
@media (min-width: 1400px) {
  .width-xxl-60 {
    width: calc(60 * 1%);
  }
}
@media (min-width: 1400px) {
  .width-xxl-65 {
    width: calc(65 * 1%);
  }
}
@media (min-width: 1400px) {
  .width-xxl-70 {
    width: calc(70 * 1%);
  }
}
@media (min-width: 1400px) {
  .width-xxl-75 {
    width: calc(75 * 1%);
  }
}
@media (min-width: 1400px) {
  .width-xxl-80 {
    width: calc(80 * 1%);
  }
}
@media (min-width: 1400px) {
  .width-xxl-85 {
    width: calc(85 * 1%);
  }
}
@media (min-width: 1400px) {
  .width-xxl-90 {
    width: calc(90 * 1%);
  }
}
@media (min-width: 1400px) {
  .width-xxl-95 {
    width: calc(95 * 1%);
  }
}
@media (min-width: 1400px) {
  .width-xxl-100 {
    width: calc(100 * 1%);
  }
}
@media (min-width: 1400px) {
  .width-xxl-105 {
    width: calc(105 * 1%);
  }
}
@media (min-width: 1400px) {
  .width-xxl-110 {
    width: calc(110 * 1%);
  }
}
@media (min-width: 1400px) {
  .width-xxl-115 {
    width: calc(115 * 1%);
  }
}
@media (min-width: 1400px) {
  .width-xxl-120 {
    width: calc(120 * 1%);
  }
}
@media (min-width: 1400px) {
  .width-xxl-125 {
    width: calc(125 * 1%);
  }
}
@media (min-width: 1400px) {
  .width-xxl-130 {
    width: calc(130 * 1%);
  }
}
@media (min-width: 1400px) {
  .width-xxl-135 {
    width: calc(135 * 1%);
  }
}
@media (min-width: 1400px) {
  .width-xxl-140 {
    width: calc(140 * 1%);
  }
}
@media (min-width: 1400px) {
  .width-xxl-145 {
    width: calc(145 * 1%);
  }
}
@media (min-width: 1400px) {
  .width-xxl-150 {
    width: calc(150 * 1%);
  }
}
.wrapper {
  width: 100%;
}
.container-xs {
  width: 90%;
  max-width: 375px;
}
.container-sm {
  width: 90%;
  max-width: 576px;
}
.container-md {
  width: 90%;
  max-width: 768px;
}
.container-lg {
  width: 90%;
  max-width: 992px;
}
.container-xl {
  width: 90%;
  max-width: 1200px;
}
.container-xxl {
  width: 90%;
  max-width: 1400px;
}
.width-fit-content {
  width: -webkit-fit-content;
  width: fit-content;
  width: -moz-fit-content;
}
.fontWeight-100 {
  font-weight: 100;
}
.fontWeight-200 {
  font-weight: 200;
}
.fontWeight-300 {
  font-weight: 300;
}
.fontWeight-400 {
  font-weight: 400;
}
.fontWeight-500 {
  font-weight: 500;
}
.fontWeight-600 {
  font-weight: 600;
}
.fontWeight-700 {
  font-weight: 700;
}
.row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.col-lg-1 {
  width: calc((100% / 12) * 1);
}
.col-sm-1 {
  width: calc((100% / 12) * 1);
}
.col-lg-2 {
  width: calc((100% / 12) * 2);
}
.col-sm-2 {
  width: calc((100% / 12) * 2);
}
.col-lg-3 {
  width: calc((100% / 12) * 3);
}
.col-sm-3 {
  width: calc((100% / 12) * 3);
}
.col-lg-4 {
  width: calc((100% / 12) * 4);
}
.col-sm-4 {
  width: calc((100% / 12) * 4);
}
.col-lg-5 {
  width: calc((100% / 12) * 5);
}
.col-sm-5 {
  width: calc((100% / 12) * 5);
}
.col-lg-6 {
  width: calc((100% / 12) * 6);
}
.col-sm-6 {
  width: calc((100% / 12) * 6);
}
.col-lg-7 {
  width: calc((100% / 12) * 7);
}
.col-sm-7 {
  width: calc((100% / 12) * 7);
}
.col-lg-8 {
  width: calc((100% / 12) * 8);
}
.col-sm-8 {
  width: calc((100% / 12) * 8);
}
.col-lg-9 {
  width: calc((100% / 12) * 9);
}
.col-sm-9 {
  width: calc((100% / 12) * 9);
}
.col-lg-10 {
  width: calc((100% / 12) * 10);
}
.col-sm-10 {
  width: calc((100% / 12) * 10);
}
.col-lg-11 {
  width: calc((100% / 12) * 11);
}
.col-sm-11 {
  width: calc((100% / 12) * 11);
}
.col-lg-12 {
  width: calc((100% / 12) * 12);
}
.col-sm-12 {
  width: calc((100% / 12) * 12);
}
@media (max-width: 992px) {
  .col-md-1 {
    width: calc((100% / 12) * 1);
  }
  .col-md-2 {
    width: calc((100% / 12) * 2);
  }
  .col-md-3 {
    width: calc((100% / 12) * 3);
  }
  .col-md-4 {
    width: calc((100% / 12) * 4);
  }
  .col-md-5 {
    width: calc((100% / 12) * 5);
  }
  .col-md-6 {
    width: calc((100% / 12) * 6);
  }
  .col-md-7 {
    width: calc((100% / 12) * 7);
  }
  .col-md-8 {
    width: calc((100% / 12) * 8);
  }
  .col-md-9 {
    width: calc((100% / 12) * 9);
  }
  .col-md-10 {
    width: calc((100% / 12) * 10);
  }
  .col-md-11 {
    width: calc((100% / 12) * 11);
  }
  .col-md-12 {
    width: calc((100% / 12) * 12);
  }
}
@media (max-width: 576px) {
  .col-xs-1 {
    width: calc((100% / 12) * 1);
  }
  .col-xs-2 {
    width: calc((100% / 12) * 2);
  }
  .col-xs-3 {
    width: calc((100% / 12) * 3);
  }
  .col-xs-4 {
    width: calc((100% / 12) * 4);
  }
  .col-xs-5 {
    width: calc((100% / 12) * 5);
  }
  .col-xs-6 {
    width: calc((100% / 12) * 6);
  }
  .col-xs-7 {
    width: calc((100% / 12) * 7);
  }
  .col-xs-8 {
    width: calc((100% / 12) * 8);
  }
  .col-xs-9 {
    width: calc((100% / 12) * 9);
  }
  .col-xs-10 {
    width: calc((100% / 12) * 10);
  }
  .col-xs-11 {
    width: calc((100% / 12) * 11);
  }
  .col-xs-12 {
    width: calc((100% / 12) * 12);
  }
}



* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
box-sizing: border-box;

}

/* colors */
.white{
  color: white; 

}
/* navbar */

/* Home page */
.bg_home {
  -webkit-animation:slide 3s ease-in-out infinite alternate;
          animation:slide 3s ease-in-out infinite alternate;
  background: linear-gradient(-60deg, #09f 50%, rgb(158 159 54 / 60%) 50%);
  bottom:0;
  left:-50%;
  opacity:.5;
  position:fixed;
  right:-50%;
  top:0;
  z-index:-1;
}

.bg2_home {
  animation-direction:alternate-reverse;
  -webkit-animation-duration:4s;
          animation-duration:4s;
}

.bg3_home {
  -webkit-animation-duration:3.5s;
          animation-duration:3.5s;
}

/* auto change text */

/* section 2 */
.btn_primary {
  
  color: #ffffff;
  text-decoration: none;
  transition: 500ms;
}

.btn_primary:hover {
  
  color: #fffffff5;
  text-decoration: none;
  transition: 500ms;
}
/* Hero Section */



.btn_primary_about {
  text-decoration: none;
  color: white;
  transition: 500ms;
  background-color: transparent;
  border-radius: 50px;

}

.btn_primary_about:hover {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.759);
  background-color: blueviolet;
  border-color: blueviolet;
  transition: 500ms;
  border-radius: 50px;
}

/* Services */

.service_cardss {
  box-shadow: 0px 17px 25px rgba(96, 96, 96, 0.08);
  background-color: white;
  transition: .5s;
  flex: 1 0 20em;
}

.service_cardss:hover {
  box-shadow: none;
  background-color: white;
  transition: .5s;
}

.service_cards {
 
  border-radius: 4px;
  background: transparent;
  box-shadow: 0 6px 10px rgba(0, 0, 0, .08), 0 0 6px rgba(0, 0, 0, .05);
  transition: .5s transform cubic-bezier(.155, 1.105, .295, 1.12), .3s box-shadow, .3s -webkit-transform cubic-bezier(.155, 1.105, .295, 1.12);
  cursor: pointer;
  flex: 1 0 20em;
}

.service_cards:hover {
  -webkit-transform: scale(1.07);
          transform: scale(1.07);
  box-shadow: 0 10px 20px rgba(0, 0, 0, .12), 0 4px 8px rgba(0, 0, 0, .06);
}

.flex {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.icon_size_service {
  width: 60px;
}

.tech_card {

  box-shadow: #5D3F2B;
}

.bg_service {
  -webkit-animation:slide 3s ease-in-out infinite alternate;
          animation:slide 3s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg,  rgb(61, 117, 150) 50%, rgba(162, 163, 117, 0.973) 50%);
  bottom:0;
  left:-50%;
  opacity:.5;
  position:fixed;
  right:-50%;
  top:0;
  z-index:-1;
}

.bg2_service {
  animation-direction:alternate-reverse;
  -webkit-animation-duration:4s;
          animation-duration:4s;
}

.bg3_service {
  -webkit-animation-duration:3s;
          animation-duration:3s;
}
@-webkit-keyframes slide {
  0% {
    -webkit-transform:translateX(-25%);
            transform:translateX(-25%);
  }
  100% {
    -webkit-transform:translateX(25%);
            transform:translateX(25%);
  }
}
@keyframes slide {
  0% {
    -webkit-transform:translateX(-25%);
            transform:translateX(-25%);
  }
  100% {
    -webkit-transform:translateX(25%);
            transform:translateX(25%);
  }
}

.listul {
  list-style: none;
color: white;
font-size: 14px;
}
.listul li{
  padding-top: 16px;
}
.listul li::before {
  content: "";
  height: 18px;
  width: 10px;
  border: 3px solid #eff1f3;
  border-radius: 50px;
  background-size: contain;
  display: inline-block;
  width: 1em;
  height: 1em;
  position: relative;
  top: 0.1rem;
  margin-right: 0.5rem;
}

/* About Us */



#about .about-container .icon-box .icon {
  float: left;
  background: transparent;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border-radius: 50%;
  border: 2px solid #eff1f3;
  transition: all 0.3s ease-in-out;
}

#about .about-container .icon-box .icon i {
  color: #c9c9c9;
  font-size: 24px;
}

#about .about-container .icon-box:hover .icon {
  background: #007bff;
}

#about .about-container .icon-box:hover .icon i {
  color: rgb(243, 240, 240);
}

#about .about-container .icon-box .title {
  margin-left: 80px;
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 18px;
}

#about .about-container .icon-box .title a {
  color: #283d50;
  text-decoration: none;
}
#about .about-container .icon-box .title a:hover {
  color: #283d50;
  text-decoration: underline;
}

#about .about-container .icon-box .description {
  margin-left: 80px;
  
  font-size: 14px;
}

#about .about-extra {
  padding-top: 60px;
}

#about .about-extra h4 {
  font-weight: 600;
  font-size: 24px;
}
.bg_about {
  -webkit-animation:slide 3s ease-in-out infinite alternate;
          animation:slide 3s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg,  rgb(48, 99, 141) 50%, rgb(86, 108, 189) 50%);
  bottom:0;
  left:-50%;
  opacity:.5;
  position:fixed;
  right:-50%;
  top:0;
  z-index:-1;
}

.bg2_about {
  animation-direction:alternate-reverse;
  -webkit-animation-duration:4s;
          animation-duration:4s;
}

.bg3_about {
  -webkit-animation-duration:3s;
          animation-duration:3s;
}


.bg_contactus {
  -webkit-animation:slide 3s ease-in-out infinite alternate;
          animation:slide 3s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg, rgb(82 74 118) 50%, rgb(106 101 121) 50%);
  bottom:0;
  left:-50%;
  opacity:.5;
  position:fixed;
  right:-50%;
  top:0;
  z-index:-1;
}

.bg2_contactus {
  animation-direction:alternate-reverse;
  -webkit-animation-duration:4s;
          animation-duration:4s;
}

.bg3_contactus {
  -webkit-animation-duration:3s;
          animation-duration:3s;
}


@keyframes slide {
  0% {
    -webkit-transform:translateX(-25%);
            transform:translateX(-25%);
  }
  50% {
    -webkit-transform:translateX(10%);
            transform:translateX(10%);
  }
  100% {
    -webkit-transform:translateX(25%);
            transform:translateX(25%);
  }
}
/* Footer */
.footer_bg_color{
  background-color: transparent;
  
}
.footer_icon{
  width: 40px;
}

body{
    margin: 0;
     padding: 0;
     font-family: 'Roboto', sans-serif;
}
 .navigation {
     height: 55px;
     background: linear-gradient(45deg, #4199fe, #74b4fe);
}
 .brand {
     position: absolute;
     padding-left: 20px;
     float: left;
     line-height: 55px;
     text-transform: uppercase;
     font-size: 1.4em;
}
 .brand a, .brand a:visited {
     color: #ffffff;
     text-decoration: none;
}
 .nav-container {
     max-width: 1200px;
     margin: 0 auto;
}
 nav {
     float: right;
}
 nav ul {
     list-style: none;
     margin: 0;
     padding: 0;
}
 nav ul li {
     float: left;
     position: relative;
}
 nav ul li a,nav ul li a:visited {
     display: block;
     padding: 0 20px;
     line-height: 55px;
     color: #fff;
     background: #262626 ;
     text-decoration: none;
}
 nav ul li a{
     background: transparent;
     color: #FFF;
}
 nav ul li a:hover, nav ul li a:visited:hover {
     background: #2581DC;
     color: #ffffff;
}
 .navbar-dropdown li a{
     background: #2581DC;
}
 nav ul li a:not(:only-child):after, nav ul li a:visited:not(:only-child):after {
     padding-left: 4px;
     content: ' \025BE';
}
 nav ul li ul li {
     min-width: 190px;
}
 nav ul li ul li a {
     padding: 15px;
     line-height: 20px;
}
 .navbar-dropdown {
     position: absolute;
     display: none;
     z-index: 1;
     background: #fff;
     box-shadow: 0 0 35px 0 rgba(0,0,0,0.25);
}
/* Mobile navigation */
 .nav-mobile {
     display: none;
     position: absolute;
     top: 0;
     right: 0;
     background: transparent;
     height: 55px;
     width: 70px;
}
 @media only screen and (max-width: 800px) {
     .nav-mobile {
         display: block;
    }
     nav {
         width: 100%;
         padding: 55px 0 15px;
    }
     nav ul {
         display: none;
    }
     nav ul li {
         float: none;
    }
     nav ul li a {
         padding: 15px;
         line-height: 20px;
         background: #262626;
    }
     nav ul li ul li a {
         padding-left: 30px;
    }
     .navbar-dropdown {
         position: static;
}
 @media screen and (min-width:800px) {
     .nav-list {
         display: block !important;
    }
}
 #navbar-toggle {
     position: absolute;
     left: 18px;
     top: 15px;
     cursor: pointer;
     padding: 10px 35px 16px 0px;
}
 #navbar-toggle span, #navbar-toggle span:before, #navbar-toggle span:after {
     cursor: pointer;
     border-radius: 1px;
     height: 3px;
     width: 30px;
     background: #ffffff;
     position: absolute;
     display: block;
     content: '';
     transition: all 300ms ease-in-out;
}
 #navbar-toggle span:before {
     top: -10px;
}
 #navbar-toggle span:after {
     bottom: -10px;
}
 #navbar-toggle.active span {
     background-color: transparent;
}
 #navbar-toggle.active span:before, #navbar-toggle.active span:after {
     top: 0;
}
 #navbar-toggle.active span:before {
     -webkit-transform: rotate(45deg);
             transform: rotate(45deg);
}
 #navbar-toggle.active span:after {
     -webkit-transform: rotate(-45deg);
             transform: rotate(-45deg);
}
}
*{
  margin: 0;
  padding: 0; 
  font-family: 'Poppins', sans-serif;
}
.bg_techteam {
  -webkit-animation:slide 3s ease-in-out infinite alternate;
          animation:slide 3s ease-in-out infinite alternate;
  background-image: linear-gradient(-60deg, rgb(106, 129, 97) 50%, rgb(64, 93, 112) 50%);
  bottom:0;
  left:-70%;
  opacity:.5;
  position:fixed;
  right:-70%;
  top:0;
  z-index:-1;
}

.bg2_techteam {
  animation-direction:alternate-reverse;
  -webkit-animation-duration:4s;
          animation-duration:4s;
}

.bg3_techteam {
  -webkit-animation-duration:5s;
          animation-duration:5s;
}

@-webkit-keyframes slide {
  0% {
    -webkit-transform:translateX(-25%);
            transform:translateX(-25%);
  }

  100% {
    -webkit-transform:translateX(25%);
            transform:translateX(25%);
  }
}

@keyframes slide {
  0% {
    -webkit-transform:translateX(-25%);
            transform:translateX(-25%);
  }

  100% {
    -webkit-transform:translateX(25%);
            transform:translateX(25%);
  }
}

.bg_color3{
  background-color: #7e7676;
}
.space {
  letter-spacing: 3px;
}

.cardteam{
   
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.4);
  transition: 0.5s;
}
.cardteam:hover {

  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.6);
}
.text-color-development_color_1 {
 color: #162149;

}
.text-color-development_color_4 {
  color: #493434;
}

.text-color-development_color_3 {
  color: rgb(224, 221, 214);
}
.teamname{
  font-size: 20px;
}
.content{
 color: #102779;
}
.heading{
  color:  linear-gradient(red, yellow);;
}

.footer{
  box-shadow: rgba(0, 0, 0, 0.50) 0px 10px 20px;
  border-radius: 10px;
  
}
.mobile_footer
{
  box-shadow: rgba(168, 50, 50, 2) 10px 20px 20px;
  display: none;
}
@media (max-width: 400px) {
  .mobile_footer{
    display: block;
  }
 .footer{
  display: none;
 }
}

/* mobile footer */
.mobile_footer ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}


.mobile_footer ul li { 
  width: 50px;
  height: 50px;
  line-height: 60px;
  margin: 0 8px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid #D8E2DC;
  float: left;
  transition: all 0.5s ease;
}

.mobile_footer ul li .fa {
  color: #D8E2DC;
  margin-top: -6px;
  transition: all 0.5s ease;
}


.mobile_footer ul li:hover.facebook {
  border: 2px solid #3b5998;
  box-shadow: 0 0 15px #3b5998;
  transition: all 0.5s ease;
}

.mobile_footer ul li:hover .fa-facebook {
  color: #3b5998;
  text-shadow: 0 0 15px #3b5998;
  transition: all 0.5s ease;
}

/*twitter*/
.mobile_footer ul li:hover.twitter {
  border: 2px solid #00aced;
  box-shadow: 0 0 15px #00aced;
  transition: all 0.5s ease;
}

.mobile_footer ul li:hover .fa-twitter {
  color: #00aced;
  text-shadow: 0 0 15px #00aced;
  transition: all 0.5s ease;
}

/* instagram */
.mobile_footer ul li:hover.instagram {
  border: 2px solid #bc2a8d;
  box-shadow: 0 0 15px #bc2a8d;
  transition: all 0.5s ease;
}

.mobile_footer ul li:hover .fa-instagram {
  color: #bc2a8d;
  text-shadow: 0 0 15px #bc2a8d;
  transition: all 0.5s ease; 
}

/* google */
.mobile_footer ul li:hover.google {
  border: 2px solid #dd4b39;
  box-shadow: 0 0 15px #dd4b39;
  transition: all 0.5s ease;
}

.mobile_footer ul li:hover .fa-google {
  color: #dd4b39;
  text-shadow: 0 0 15px #dd4b39;
  transition: all 0.5s ease;
}

/* whatsapp */
.mobile_footer ul li:hover.whatsapp {
  border: 2px solid #4dc247;
  box-shadow: 0 0 15px #4dc247;
  transition: all 0.5s ease;
}

.mobile_footer ul li:hover .fa-whatsapp {
  color: #4dc247;
  text-shadow: 0 0 15px #4dc247;
  transition: all 0.5s ease;
}






  /* Style the Un-order list by setting its list-style to none */
  .wrapper_footer ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  }
  

  .wrapper_footer ul li {
    
    width: 60px;
    height: 60px;
    line-height: 60px;
    margin: 0 8px;
    text-align: center;
    cursor: pointer;
    border-radius: 50%;
    border: 5px solid #D8E2DC;
    float: left;
    transition: all 0.5s ease;
  }

  .wrapper_footer ul li .fa {
    color: #D8E2DC;
    margin-top: 10px;
    transition: all 0.5s ease;
  }
  
  
  .wrapper_footer ul li:hover.facebook {
    border: 5px solid #3b5998;
    box-shadow: 0 0 15px #3b5998;
    transition: all 0.5s ease;
  }
  
  .wrapper_footer ul li:hover .fa-facebook {
    color: #3b5998;
    text-shadow: 0 0 15px #3b5998;
    transition: all 0.5s ease;
  }
  
  /*twitter*/
  .wrapper_footer ul li:hover.twitter {
    border: 5px solid #00aced;
    box-shadow: 0 0 15px #00aced;
    transition: all 0.5s ease;
  }
  
  .wrapper_footer ul li:hover .fa-twitter {
    color: #00aced;
    text-shadow: 0 0 15px #00aced;
    transition: all 0.5s ease;
  }
  
  /* instagram */
  .wrapper_footer ul li:hover.instagram {
    border: 5px solid #bc2a8d;
    box-shadow: 0 0 15px #bc2a8d;
    transition: all 0.5s ease;
  }
  
  .wrapper_footer ul li:hover .fa-instagram {
    color: #bc2a8d;
    text-shadow: 0 0 15px #bc2a8d;
    transition: all 0.5s ease;
  }
  
  /* google */
  .wrapper_footer ul li:hover.google {
    border: 5px solid #dd4b39;
    box-shadow: 0 0 15px #dd4b39;
    transition: all 0.5s ease;
  }
  
  .wrapper_footer ul li:hover .fa-google {
    color: #dd4b39;
    text-shadow: 0 0 15px #dd4b39;
    transition: all 0.5s ease;
  }
  
  /* whatsapp */
  .wrapper_footer ul li:hover.whatsapp {
    border: 5px solid #4dc247;
    box-shadow: 0 0 15px #4dc247;
    transition: all 0.5s ease;
  }
  
  .wrapper_footer ul li:hover .fa-whatsapp {
    color: #4dc247;
    text-shadow: 0 0 15px #4dc247;
    transition: all 0.5s ease;
  }
  
  
  /* media queries */
  

  
  
  
  
  
