
.footer{
  box-shadow: rgba(0, 0, 0, 0.50) 0px 10px 20px;
  border-radius: 10px;
  
}
.mobile_footer
{
  box-shadow: rgba(168, 50, 50, 2) 10px 20px 20px;
  display: none;
}
@media (max-width: 400px) {
  .mobile_footer{
    display: block;
  }
 .footer{
  display: none;
 }
}

/* mobile footer */
.mobile_footer ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}


.mobile_footer ul li { 
  width: 50px;
  height: 50px;
  line-height: 60px;
  margin: 0 8px;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid #D8E2DC;
  float: left;
  transition: all 0.5s ease;
}

.mobile_footer ul li .fa {
  color: #D8E2DC;
  margin-top: -6px;
  transition: all 0.5s ease;
}


.mobile_footer ul li:hover.facebook {
  border: 2px solid #3b5998;
  box-shadow: 0 0 15px #3b5998;
  transition: all 0.5s ease;
}

.mobile_footer ul li:hover .fa-facebook {
  color: #3b5998;
  text-shadow: 0 0 15px #3b5998;
  transition: all 0.5s ease;
}

/*twitter*/
.mobile_footer ul li:hover.twitter {
  border: 2px solid #00aced;
  box-shadow: 0 0 15px #00aced;
  transition: all 0.5s ease;
}

.mobile_footer ul li:hover .fa-twitter {
  color: #00aced;
  text-shadow: 0 0 15px #00aced;
  transition: all 0.5s ease;
}

/* instagram */
.mobile_footer ul li:hover.instagram {
  border: 2px solid #bc2a8d;
  box-shadow: 0 0 15px #bc2a8d;
  transition: all 0.5s ease;
}

.mobile_footer ul li:hover .fa-instagram {
  color: #bc2a8d;
  text-shadow: 0 0 15px #bc2a8d;
  transition: all 0.5s ease; 
}

/* google */
.mobile_footer ul li:hover.google {
  border: 2px solid #dd4b39;
  box-shadow: 0 0 15px #dd4b39;
  transition: all 0.5s ease;
}

.mobile_footer ul li:hover .fa-google {
  color: #dd4b39;
  text-shadow: 0 0 15px #dd4b39;
  transition: all 0.5s ease;
}

/* whatsapp */
.mobile_footer ul li:hover.whatsapp {
  border: 2px solid #4dc247;
  box-shadow: 0 0 15px #4dc247;
  transition: all 0.5s ease;
}

.mobile_footer ul li:hover .fa-whatsapp {
  color: #4dc247;
  text-shadow: 0 0 15px #4dc247;
  transition: all 0.5s ease;
}






  /* Style the Un-order list by setting its list-style to none */
  .wrapper_footer ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
  }
  

  .wrapper_footer ul li {
    
    width: 60px;
    height: 60px;
    line-height: 60px;
    margin: 0 8px;
    text-align: center;
    cursor: pointer;
    border-radius: 50%;
    border: 5px solid #D8E2DC;
    float: left;
    transition: all 0.5s ease;
  }

  .wrapper_footer ul li .fa {
    color: #D8E2DC;
    margin-top: 10px;
    transition: all 0.5s ease;
  }
  
  
  .wrapper_footer ul li:hover.facebook {
    border: 5px solid #3b5998;
    box-shadow: 0 0 15px #3b5998;
    transition: all 0.5s ease;
  }
  
  .wrapper_footer ul li:hover .fa-facebook {
    color: #3b5998;
    text-shadow: 0 0 15px #3b5998;
    transition: all 0.5s ease;
  }
  
  /*twitter*/
  .wrapper_footer ul li:hover.twitter {
    border: 5px solid #00aced;
    box-shadow: 0 0 15px #00aced;
    transition: all 0.5s ease;
  }
  
  .wrapper_footer ul li:hover .fa-twitter {
    color: #00aced;
    text-shadow: 0 0 15px #00aced;
    transition: all 0.5s ease;
  }
  
  /* instagram */
  .wrapper_footer ul li:hover.instagram {
    border: 5px solid #bc2a8d;
    box-shadow: 0 0 15px #bc2a8d;
    transition: all 0.5s ease;
  }
  
  .wrapper_footer ul li:hover .fa-instagram {
    color: #bc2a8d;
    text-shadow: 0 0 15px #bc2a8d;
    transition: all 0.5s ease;
  }
  
  /* google */
  .wrapper_footer ul li:hover.google {
    border: 5px solid #dd4b39;
    box-shadow: 0 0 15px #dd4b39;
    transition: all 0.5s ease;
  }
  
  .wrapper_footer ul li:hover .fa-google {
    color: #dd4b39;
    text-shadow: 0 0 15px #dd4b39;
    transition: all 0.5s ease;
  }
  
  /* whatsapp */
  .wrapper_footer ul li:hover.whatsapp {
    border: 5px solid #4dc247;
    box-shadow: 0 0 15px #4dc247;
    transition: all 0.5s ease;
  }
  
  .wrapper_footer ul li:hover .fa-whatsapp {
    color: #4dc247;
    text-shadow: 0 0 15px #4dc247;
    transition: all 0.5s ease;
  }
  
  
  /* media queries */
  

  
  
  
  
  